import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { DrService } from '../../providers/dr.service';
import { MDR } from '../../models/mdr';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ShipmentService } from '../../providers/shipment.service';
var ShipmentOptimizerComponent = /** @class */ (function () {
    function ShipmentOptimizerComponent(dialog, mapsAPILoader, drService, shipmentService, mdrService, router, snackBar, dpipe) {
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.drService = drService;
        this.shipmentService = shipmentService;
        this.mdrService = mdrService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.data = [];
        this.showLightBox = false;
        this.filter = '';
        this.columns = [
            { value: 'shipment', name: 'Shipment' },
            { value: 'city', name: 'City' },
            { value: 'shippingTypeDescription', name: 'Description' },
            { value: 'forwardingAgentName', name: 'FWDAgent' },
            { value: 'plannedShipmentEnd', name: 'Due Date' },
        ];
        this.selected = [];
    }
    ShipmentOptimizerComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    ShipmentOptimizerComponent.prototype.getData = function () {
        var _this = this;
        this.shipmentService.getAllShipment().subscribe(function (data2) {
            _this.data = data2.map(function (row) {
                // extract some fields to root level
                return Object.assign({
                    shipment: row.zzt.shipment,
                    city: row.zzt.city,
                    shippingTypeDescription: row.zzt.shippingTypeDescription,
                    forwardingAgentName: row.zzt.forwardingAgentName,
                    plannedShipmentEnd: new Date(row.zzt.plannedShipmentEnd),
                }, row);
            });
        }, function (err) {
            _this.snackBar.open('Erro ao buscar shipments', 'Ok', { duration: 4000 });
        });
    };
    ShipmentOptimizerComponent.prototype.unmakeShipment = function () {
        var _this = this;
        this.shipmentService.unmakeShipments(this.selected).subscribe(function (data) {
            _this.selected = [];
            _this.snackBar.open('Shipments desfeitos com sucesso', 'Ok', { duration: 4000 });
            _this.getData();
        }, function (err) {
            _this.snackBar.open('Erro ao desfazer shipments', 'Ok', { duration: 4000 });
        });
    };
    ShipmentOptimizerComponent.prototype.createNewMDR = function () {
        var _this = this;
        var mdr = new MDR();
        mdr.shipmentList = [];
        mdr.status = 'MDR em criação';
        this.selected.forEach(function (el) {
            mdr.shipmentList.push(el);
        });
        console.log(mdr.shipmentList);
        if (mdr.shipmentList[0].zzt.shippingTypeDescription === 'Air transport' || mdr.shipmentList[0].zzt.shippingTypeDescription === 'Mail / Courier') {
            mdr.airport = true;
        }
        else if (mdr.shipmentList[0].zzt.shippingTypeDescription === 'Maritime transport') {
            mdr.airport = false;
        }
        this.mdrService.insertPickup(mdr).subscribe(function (data) {
            //// console.log('MDR new Service');
            console.log(data);
            _this.mdrService.createLegs(data.id, false).subscribe(function (legs) {
                //// console.log(legs);
            });
            _this.getData();
        });
    };
    ShipmentOptimizerComponent.prototype.onSelection = function (selected) {
        this.selected = selected;
    };
    return ShipmentOptimizerComponent;
}());
export { ShipmentOptimizerComponent };
