import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {environment} from '../../environments/environment';

const base_url: string = environment.apiUrl;

@Injectable()
export class AirportService {
  public token: string;

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  getAll(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'airports', {headers: header}).map( (response) => {
      return response;
    });
  }

  getById(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'airports/' + id, {headers: header});
  }

  update(airport: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.put(base_url + 'airports/' + airport.id, airport, {headers: header});
  }

  create(airport: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(base_url + 'airports', airport, {headers: header});
  }
}
