<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'NEW-SHIPMENT-REQUEST/title' | translate }}</p>
  <p class="mx-4">{{ 'NEW-SHIPMENT-REQUEST/sub-title' | translate }}</p>
</div>
<div class="colored-background pt-5">
  <mat-card *ngIf="!uploaded" class="mx-5 mb-5 d-flex flex-row flex-wrap align-items-center rounded-corners">
    <div class="col-md-12">
      <div class="row">
        <span class="title col-md-3 bold-text">{{ 'NEW-SHIPMENT-REQUEST/text/import' | translate }}</span>
        <div class="title col-md-3 ">
          <input id="input-file-id"  #fileInput accept='xlsx' (change)='openFile($event)' class="none" multiple type="file"/>
          <label for="input-file-id" class="button-file">{{ 'NEW-SHIPMENT-REQUEST/text/select-file' | translate }}</label>
        </div>
        Ou
        <div class="title col-md-3">
          <a href="../../../assets/extras/multiUpload.xlsx" download="">{{ 'NEW-DELIVERY-REQUEST/dowload_model' | translate }} <i matSuffix font class="fa fa-download"></i></a>
        </div>
      </div>
    </div>
    <mat-form-field class="col-md-12">
      <input matInput placeholder="{{ 'NEW-SHIPMENT-REQUEST/text/search2' | translate }}" [(ngModel)]="inputOVs" (keyup.enter)="searchShipment()">
      <i matSuffix font class="fa fa-search" (click)="searchShipment()"></i>
    </mat-form-field>
      <p class="mat-h2 pt-2">{{'NEW-SHIPMENT-REQUEST/text/selected-ovs' | translate}}</p>
      <div class="col-md-12 p-0">
         <div *ngIf="dataSource.data.length > 0">
            <mat-table #table [dataSource]="dataSource" matSort>
               <ng-container matColumnDef="city">
                   <mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
                   <mat-cell *matCellDef="let element"> {{element.city }} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="shipment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-SHIPMENT-REQUEST/text/shipment' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.shipment}} </mat-cell>
                  </ng-container>
                 <ng-container matColumnDef="client">
                   <mat-header-cell *matHeaderCellDef mat-sort-header> Client </mat-header-cell>
                   <mat-cell *matCellDef="let element"> {{element.shipToPartyName}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="shippingTypeDescription">
                   <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                   <mat-cell *matCellDef="let element"> {{element.shippingTypeDescription}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="incoterms1">
                   <mat-header-cell *matHeaderCellDef mat-sort-header> Incoterms1</mat-header-cell>
                   <mat-cell *matCellDef="let element"> {{element.incoterms1}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="forwardingAgentName">
                   <mat-header-cell *matHeaderCellDef mat-sort-header> FWA Name </mat-header-cell>
                   <mat-cell *matCellDef="let element"> {{element.forwardingAgentName}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="plannedShipmentEnd">
                   <mat-header-cell *matHeaderCellDef mat-sort-header>  </mat-header-cell>
                   <mat-cell *matCellDef="let element"> {{element.plannedShipmentEnd}} </mat-cell>
                </ng-container>
              <mat-header-row class="header" *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            
         </div>
         <div *ngIf="dataSource.data.length === 0">
            <p>{{'NEW-SHIPMENT-REQUEST/text/no-selected-ovs' | translate}}</p>
         </div>
      </div>
     <!-- <mat-form-field class="col-md-6">
        <input matInput #search2 type="text"  autocapitalize="off" spellcheck="off"
               placeholder="{{ 'NEW-SHIPMENT-REQUEST/text/delivery-address' | translate }}"
               id="deliveryAddress"
               [(ngModel)]="dr.deliveryAddress"
               (change)="convertAddressToLatLng()"
               [formControl]="searchControl2">
     </mat-form-field>
     <div class="col-md-6 d-flex flex-row p-0">
        <mat-form-field class="col-md-6">
           <input matInput placeholder="{{ 'NEW-SHIPMENT-REQUEST/text/lat' | translate }}"
                  id="latitudeAddress"
                  [(ngModel)]="dr.lat"
                  (change)="convertLatLngToAddress()">
        </mat-form-field>
        <mat-form-field class="col-md-6">
           <input matInput placeholder="{{ 'NEW-SHIPMENT-REQUEST/text/lng' | translate }}"
                  id="longitudeAddress"
                  [(ngModel)]="dr.lng"
                  (change)="convertLatLngToAddress()">
        </mat-form-field>
     </div> -->
     <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'NEW-SHIPMENT-REQUEST/text/name-receptor' | translate }}" [(ngModel)]="dr.receiverName">
     </mat-form-field>
     <mat-form-field class="col-md-6">
        <input matInput #tel mask="+0000000090000" type="text" placeholder="{{ 'NEW-SHIPMENT-REQUEST/text/cellphone-recept' | translate }}" [(ngModel)]="dr.receiverPhone">
     </mat-form-field>
     <!-- <mat-form-field class="col-md-3">
        <input matInput [min]="minDate" (click)="myDatepicker.open()" [matDatepicker]="myDatepicker" placeholder="{{ 'NEW-DELIVERY-REQUEST/text/date' | translate }}" id="dateCollect" [(ngModel)]="dateCollect" disabled>
        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #myDatepicker disabled="false"></mat-datepicker>
     </mat-form-field>
     <mat-form-field class="col-md-3">
        <input matInput type="time" id="hour" placeholder="{{ 'NEW-SHIPMENT-REQUEST/text/hour' | translate }}" [(ngModel)]="hour">
     </mat-form-field> -->
     <mat-form-field class="col-md-12">
        <textarea matInput placeholder="{{ 'NEW-SHIPMENT-REQUEST/text/obs' | translate }}" [(ngModel)]="dr.obs"></textarea>
     </mat-form-field>
     <!-- <div> -->
       <!-- <p>Weight: {{weight}} KG</p> -->
       <!-- <p>Volume: {{volume}} m3</p> -->
     <!-- </div> -->
    <!--<mat-button-toggle-group #group="matButtonToggleGroup">-->
      <!--<mat-button-toggle value="ov">OV</mat-button-toggle>-->
      <!--<mat-button-toggle value="nfe">NFe</mat-button-toggle>-->
    <!--</mat-button-toggle-group>-->
    <button *ngIf="dataSource.data.length > 0" mat-raised-button class="col-md-12 my-3 green-button" (click)="insertShippment()">{{'NEW-DELIVERY-REQUEST/text/create' | translate}}</button>
  </mat-card>
 <div  *ngIf="uploaded" class="colored-background pt-5">
   <mat-card class="mx-5 mb-5  flex-row flex-wrap align-items-center rounded-corners">
       <div class="row row-height60">
         <p class="mat-h1 center-h1">
           <span>{{ 'NEW-SHIPMENT-REQUEST/text/ov-selected' | translate }}</span>
         </p>
       </div>
       <div class="row icon-back">
         <p class="col-md-4 ">
           <span (click)="uploaded=false"><i class="fas fa-undo-alt"></i></span>
         </p>
       </div>
       <div class="row">
         <div class="col-md-12">
           <div class="example-container mat-elevation-z8 col-md-12" *ngIf="dataSourceDR.data.length > 0" style="margin-bottom: 30px;">
             <mat-table #table [dataSource]="dataSourceDR" matSort>
               <ng-container matColumnDef="city">
                 <mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
                 <mat-cell *matCellDef="let element"> {{element.city }} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="shipment">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-SHIPMENT-REQUEST/text/shipment' | translate }} </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.shipment}} </mat-cell>
                </ng-container>
               <ng-container matColumnDef="client">
                 <mat-header-cell *matHeaderCellDef mat-sort-header> Client </mat-header-cell>
                 <mat-cell *matCellDef="let element"> {{element.client}} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="shippingTypeDescription">
                 <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                 <mat-cell *matCellDef="let element"> {{element.shippingTypeDescription}} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="incoterms1">
                 <mat-header-cell *matHeaderCellDef mat-sort-header> Incoterms1</mat-header-cell>
                 <mat-cell *matCellDef="let element"> {{element.incoterms1}} </mat-cell>
               </ng-container>
               <ng-container matColumnDef="forwardingAgentName">
                 <mat-header-cell *matHeaderCellDef mat-sort-header> FWA Name </mat-header-cell>
                 <mat-cell *matCellDef="let element"> {{element.forwardingAgentName}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="plannedShipmentEnd">
                 <mat-header-cell *matHeaderCellDef mat-sort-header>  </mat-header-cell>
                 <mat-cell *matCellDef="let element"> {{element.plannedShipmentEnd}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="shipToStreet">
               <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-SHIPMENT-REQUEST/text/shipToStreet' | translate }} </mat-header-cell>
               <mat-cell *matCellDef="let element"> {{element.shipToStreet}} </mat-cell>
             </ng-container>
             <ng-container matColumnDef="confirmedDeliveryDate">
               <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-SHIPMENT-REQUEST/text/dueDate' | translate }} </mat-header-cell>
               <mat-cell *matCellDef="let element"> {{element.confirmedDeliveryDate}} </mat-cell>
             </ng-container>
             <ng-container matColumnDef="weight">
               <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-SHIPMENT-REQUEST/text/weight' | translate }} </mat-header-cell>
               <mat-cell *matCellDef="let element"> {{element.weight}} </mat-cell>
             </ng-container>
             <ng-container matColumnDef="volume">
               <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-SHIPMENT-REQUEST/text/volume' | translate }} </mat-header-cell>
               <mat-cell *matCellDef="let element"> {{element.volume}} </mat-cell>
             </ng-container>
               <mat-header-row class="header" *matHeaderRowDef="displayedColumns3"></mat-header-row>
               <mat-row *matRowDef="let row; columns: displayedColumns3;"></mat-row>
             </mat-table>
           </div>
         </div>
       </div>
       <div class="row">
         <div class="col-md-12">
           <button mat-raised-button class="left green-button" *ngIf="dataSourceDR.data.length> 0" (click)="insertAllShippment()">{{'NEW-SHIPMENT-REQUEST/text/create' | translate}}</button>
         </div>
       </div>
   </mat-card>
 </div>
</div>
