import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../providers/auth.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: any = {};
  username: any;
  password: any;
  loading = false;
  captchaToken = '';
  error = '';

  constructor(public translate: TranslateService, public auth: AuthService, public router: Router, public snackBar: MatSnackBar) {
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['control-tower']);
    }
  }

  resolved(captchaResponse: string) {
      // console.log(captchaResponse);
      this.captchaToken = captchaResponse;
  }

  ngOnInit() {
  }

  login() {
    this.loading = true;
    this.auth.login(this.username, this.password, this.captchaToken).subscribe(result => {
      if (result === 'cap') {
        this.snackBar.open('Captcha não verificado', 'Fechar', {
          duration: 2000,
        });
        this.error = 'Username or password is incorrect';
        this.loading = false;
        return;
      } else if (result === 'cred') {
        // login failed
        this.snackBar.open('Usuário ou senha invalidos', 'Fechar', {
          duration: 2000,
        });
        // this.router.navigate(['login']);
        return false;
      } else {
        // login successful
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const role = user.user.role;
        if (role === 1 || role === 3) {
          this.router.navigate(['cockpit']);
        } else if (role === 5) {
          this.router.navigate(['cockpit']);
        } else if (role === 7 ) {
          this.router.navigate(['finance-list']);
        } else if (role === 9 ) {
          this.router.navigate(['cockpit']);
        } else if (role === 2 ) {
          this.router.navigate(['new-delivery-request']);
        }

        return true;
      }
    }, error => {
      // login failed
      this.snackBar.open('Usuário ou senha invalidos', 'Fechar', {
        duration: 2000,
      });
    });
  }

  chageLanguage() {
    this.translate.use('pt').subscribe((data) => {
      // console.log(data);
    });
  }

  forgotPassword() {
    if (!this.username) {
      this.snackBar.open('Email ausente', 'Ok', { duration: 2000 });
      return;
    }
    this.loading = true;
    this.auth.sendEmail(this.username, this.captchaToken)
        .subscribe(result => {
          // console.log(result)
          this.snackBar.open('Email enviado', 'Fechar', {
            duration: 2000,
          });
          if (result === false) {
            // login failed
            this.snackBar.open('Erro no envio de email', 'Fechar', {
              duration: 2000,
            });
            this.error = 'Username or password is incorrect';
            return false;
          }
        });
  }

}
