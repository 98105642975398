/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@angular/flex-layout/extended";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./menu.component";
import * as i10 from "../../providers/auth.service";
import * as i11 from "../../providers/dr.service";
var styles_MenuComponent = [i0.styles];
var RenderType_MenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuComponent, data: {} });
export { RenderType_MenuComponent as RenderType_MenuComponent };
function View_MenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), i1.ɵdid(5, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, 2, 2, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(8, 933888, null, 0, i6.DefaultClassDirective, [i1.ElementRef, i7.StyleUtils, i7.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i5.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), (_l()(), i1.ɵeld(9, 0, null, 2, 2, "div", [["class", "mat-nav-list-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.link, ""); _ck(_v, 5, 0, currVal_2); var currVal_3 = "fa"; var currVal_4 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.icon, ""); _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.icon, ""); var currVal_6 = "fa"; _ck(_v, 8, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_7 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_v.context.$implicit.name)); _ck(_v, 10, 0, currVal_7); }); }
export function View_MenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-nav-list", [["class", "mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i2.View_MatNavList_0, i2.RenderType_MatNavList)), i1.ɵdid(1, 704512, null, 0, i3.MatNavList, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MenuComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuItems; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-component", [], null, null, null, View_MenuComponent_0, RenderType_MenuComponent)), i1.ɵdid(1, 49152, null, 0, i9.MenuComponent, [i10.AuthService, i4.Router, i11.DrService], null, null)], null, null); }
var MenuComponentNgFactory = i1.ɵccf("app-menu-component", i9.MenuComponent, View_MenuComponent_Host_0, {}, {}, []);
export { MenuComponentNgFactory as MenuComponentNgFactory };
