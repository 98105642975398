import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { DR } from '../../../models/dr';
import { MatTableDataSource, MatSort, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatPaginator } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DrService } from '../../../providers/dr.service';
import { DrObservable } from '../../../services/dr.service';
import { TranslateService } from '@ngx-translate/core';
import { AcceptOccurrenceDialogComponent } from '../occurrence/occurrence.component';
import { Occurrence } from '../../../models/occurrence';
import { HUB } from '../../../models/hub';
import { HubService } from '../../../providers/hub.service';
import { HUBDR } from '../../../models/hub__dr';
import { OVService } from '../../../providers/ov.service';
import { OV } from '../../../models/ov';
import { ShipmentService } from '../../../providers/shipment.service';
import { MDR } from '../../../models/mdr';
import { Airport } from '../../../models/airport';
import { Airway } from '../../../models/airway';
import { Shipment } from '../../../models/shipment';


@Component({
  selector: 'app-airport-route',
  templateUrl: './airport.component.html',
  styleUrls: ['./airport.component.scss']
})
export class AirportComponent implements OnInit {
  @Input() mdr: MDR;
  data: DR[] = [];
  showAirport = false;
  dataSource = new MatTableDataSource<DR>(this.data);
  displayedColumns = ['idDRidentifier', 'deliveryAddress', 'receiverName', 'receiverPhone', 'supervisorName', 'supervisorPhone', 'site', 'dateScheduling', 'status', 'obs', 'action'];
  @ViewChild(MatSort) sort: MatSort;
  currentUser: any;
  role: any;
  airportList: any[] = [];

  constructor(public aRoute: ActivatedRoute, private drService: DrService, private shipmentService: ShipmentService,
    public drObservable: DrObservable, public dialog: MatDialog, public ovService: OVService, public snackBar: MatSnackBar) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    if (this.role === 5) {
      this.displayedColumns = ['idDRidentifier', 'deliveryAddress', 'receiverName', 'receiverPhone', 'supervisorName', 'supervisorPhone', 'site', 'dateScheduling', 'status', 'obs'];
    }
  }

  ngOnInit() {
    this.shipmentService.getAirways(this.mdr.id).subscribe(aList => {
      console.log('airWays');
      console.log(aList);
      this.airportList = aList;
      if (this.airportList.length === 0) {
        const newAirway_1 = new Airway();
        newAirway_1.airportId = 28257;
        newAirway_1.mdrID = this.mdr.id;
        this.shipmentService.insertAirway(newAirway_1).subscribe((response_1) => {
          const newAirway_2 = new Airway();
          newAirway_2.airportId = 28256;
          newAirway_2.mdrID = this.mdr.id;
          this.shipmentService.insertAirway(newAirway_2).subscribe((response_2) => {
            const newAirway_3 = new Airway();
            newAirway_3.airportId = 28256;
            newAirway_3.mdrID = this.mdr.id;
            this.shipmentService.insertAirway(newAirway_3).subscribe((response_3) => {
              const newAirway_4 = new Airway();
              newAirway_4.airportId = 28258;
              newAirway_4.mdrID = this.mdr.id;
              this.shipmentService.insertAirway(newAirway_4).subscribe((response_4) => {
                this.shipmentService.getAirways(this.mdr.id).subscribe(naList => {
                  this.airportList = naList;
                  this.airportList.forEach(el => {
                    if (el.mawb === undefined) {
                      el.mawb = '';
                    }
                  });
                  this.showAirport = true;
                });
              });
            });
          });
        });
        console.log(this.airportList);
      } else {
        this.airportList.forEach(el => {
          if (el.mawb === undefined) {
            el.mawb = '';
          }
        });
        this.showAirport = true;
      }
    });
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(EditAirplaneComponent, {
      data: { airportList: this.airportList, mdr: this.mdr },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response !== undefined) {
        this.showAirport = false;
        this.shipmentService.getAirways(this.mdr.id).subscribe(elem => {
          elem.forEach(el => {
            if (el.mawb === undefined) {
              el.mawb = '';
            }
          });
          console.log(elem);
          this.airportList = elem;
          this.showAirport = true;
        });
      }
    });
  }


  openInfoAirport(elem) {
    console.log(elem.airport);
    this.snackBar.open(elem.airport.name, null, { duration: 3000 });
  }
}

@Component({
  selector: 'app-edit-airplane-dialog',
  templateUrl: 'edit-airplane-dialog.html',
  styleUrls: ['./airport.component.scss']
})
export class EditAirplaneComponent implements OnInit {
  select: any;
  dateCollect: Date;
  hour: any;
  previous: any;
  hubList: HUB[];
  hubId: any;
  hubObs: any;
  airports: any;
  airport: any;
  dataReceived: any[] = [];
  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['airport', 'eta', 'etd', 'ata', 'status', 'awb', 'order', 'action'];
  status = [
    { id: '1', status: 'Aguardando Embarque' },
    { id: '2', status: 'Em Viagem' },
    { id: '3', status: 'Embarque Confirmado' },
    { id: '4', status: 'Liberação Aduaneira' },
    { id: '5', status: 'Voo atrasado' },
    { id: '6', status: 'Viagem finalizada' }
  ];

  constructor(
    public dialogRef: MatDialogRef<EditAirplaneComponent>, public hubService: HubService, public shipmentService: ShipmentService,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService, public drService: DrService, public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    for (const el of this.data.airportList) {
      el.edit = false;
      el.remove = false;
      el.airportIATA = el.airport.iata;
      this.dataReceived.push(el);
    }
    this.shipmentService.getAirports().subscribe(elem => {
      this.airports = elem;
    });
    this.dataSource = new MatTableDataSource<any>(this.dataReceived);
  }

  selectDate(x) {
    // TODO
  }

  removeAirport(element) {
    if (element.id !== null) {
      this.shipmentService.removeAirway(element).subscribe(elem => {
        console.log('removed');
      });
    }
    for (let i = 0; i < this.dataReceived.length; i++) {
      if (this.dataReceived[i] === element) {
        this.dataReceived.splice(i, 1);
        this.dataSource = new MatTableDataSource<any>(this.dataReceived);
      }
    }
  }

  addAirport() {
    this.dataReceived.push({ id: null, eta: new Date(), etd: null, airport: new Airport(), color: 'green', rtd: null });
    this.dataSource = new MatTableDataSource<any>(this.dataReceived);
  }

  // function to check if airport is editable and is not part of nonEditable list
  editableAirport(element) {
    if (element.edit) {
      const nonEditableAirportIds = [28256, 28257, 28258];
      return !nonEditableAirportIds.includes(element.airport.id);
    } else {
      return false;
    }
  }

  showInput(element) {
    element.edit = true;
  }

  onYesClick() {
    this.data.airportList = [];
    for (const el of this.dataReceived) {
      const airportCreated = this.airports.filter(airport => airport.iata === el.airportIATA)[0];
      if (el.remove === true) {
        console.log('Removing');
        this.shipmentService.removeAirway(el).subscribe(elem => {
          console.log('removed');
        });
      } else {
        if (airportCreated === null || airportCreated === undefined) {
          this.snackBar.open('Não foi possível encontrar um ou mais aeroportos', 'OK', { duration: 4000 });
        } else {
          if (this.editableAirport(airportCreated)) {
            this.shipmentService.updateAirway({ airport: {iata: el.airportIATA}, id: el.airport.id }).subscribe(elm => {
              console.log('Atualizado corretamente');
            });
          } else {
            el.airport = airportCreated;
            el.airportId = airportCreated.id;
            el.mdrID = this.data.mdr.id;
            console.log(el);
            this.shipmentService.insertAirway(el).subscribe(elm => {
              console.log('Inserido corretamente');
            });
            this.data.airportList.push(el);
          }
        }
      }
    }
    this.dialogRef.close(this.data.airportList);
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
