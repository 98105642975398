<p>
{{ 'SLA' | translate }}:
<span *ngIf="!editMDRSla" [ngClass]="isSla ? 'is-sla' : 'is-not-sla' ">{{(isSLA ? 'YES' : 'NO' ) | translate }}</span>
  <mat-form-field *ngIf="role !== 5 && editMDRSla" class="col-md-3">
    <mat-select [(ngModel)]="newSla" >
      <mat-option *ngFor="let elem of mdrSla" [value]="elem.value">
        {{elem.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <i *ngIf="role !== 5 && !editMDRSla" font class="fa fa-pencil-alt icon-header" (click)="editSla()" aria-hidden="true"> </i>
  <i *ngIf="role !== 5 && editMDRSla" font class="fa fa-save icon-header" (click)="saveSla()" aria-hidden="true"> </i>
</p>
