import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { DR } from '../../models/dr';
import { MDR } from '../../models/mdr';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';

declare var google: any;

@Component({
  selector: 'app-delivery-optimizer',
  templateUrl: './delivery-optimizer.component.html',
  styleUrls: ['./delivery-optimizer.component.scss']
})

export class DeliveryOptimizerComponent implements OnInit {
  data: DR[] = [];
  columns = [
    {value: 'idDRidentifier', name: 'DR_OPTIMIZER/id'},
    {value: 'deliveryAddress', name: 'DR_OPTIMIZER/address'},
    {value: 'dateScheduling', name: 'DR_OPTIMIZER/date'},
    {value: 'site', name: 'DR_OPTIMIZER/site'},
    {value: 'createdBy', name: 'DR_OPTIMIZER/created'},
    {value: 'client', name: 'DR_OPTIMIZER/table/client'},
    {value: 'ovList', name: 'DR_OPTIMIZER/table/ovList'},
    {value: 'uf', name: 'DR_OPTIMIZER/table/uf'}
  ];
  selected: DR[] = [];

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(public dialog: MatDialog,
              private mapsAPILoader: MapsAPILoader,
              private drService: DrService,
              public mdrService: MdrService,
              public router: Router,
              public snackBar: MatSnackBar,
              public dpipe: DatePipe) { }

  ngOnInit() {
    this.drService.getAllOpen().subscribe( (data2) => {
      data2.forEach( (row) => {
        if (row.ovs) {
          row.ovList = row.ovs.map(e => e.salesDocument).join(', ');
        } else {
          row.ovList = '';
        }
      });
      this.data = data2;
    });
  }

  unmakeDR() {
      this.drService.unmakeDR(this.selected).subscribe(
        (data) => {
          this.drService.getAllOpen().subscribe(
            (data2) => {
              this.data = data2;
              this.snackBar.open('Ordens de entrega desfeitas com sucesso', 'Ok', {
                duration: 4000,
              });
            }
          );
        });
  }

  createConsolidationMDR() {
    const mdr = new MDR();
    mdr.status = 'MDR em validação';
    this.selected.forEach((el: DR) => {
      mdr.drList.push(el);
    });

    this.mdrService.insert(mdr, false).subscribe( (data) => {
     //// console.log('MDR new Service');
     console.log(data);
      this.mdrService.createLegs(data.id, false).subscribe((legs) => {
        console.log(legs);
      });
      this.drService.getAllOpen().subscribe(
        (data2) => {
          this.data = data2;
          this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
            duration: 4000,
          });
          this.router.navigate(['delivery-order']);
        }
      );
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TypeMdrDialogComponent, {
      width: '600px',
      height: '500px',
      data: {
        id: 1,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data !== undefined) {
        this.createNewMDR(data);
      }
    });
  }

  createNewMDR(typeMDR: any) {
    // console.log(typeMDR);
    // cria MDR
    const mdr = new MDR();
    mdr.type = typeMDR.type;
    mdr.status = 'MDR em validação';
    // adiciona DRs selecionadas
    this.selected.forEach((el: DR) => {
      mdr.drList.push(el);
    });

    let flag = false;
    if (mdr.type === 'Cross Doc') {
      this.createConsolidationMDR();
      return;
    } else if (mdr.type === 'CMS' && typeMDR.data) {
      mdr.SAD = typeMDR.data;
      if (typeMDR.data === true) {
        flag = true;
      }
    } else if (mdr.type === 'DTR' && typeMDR.data) {
      mdr['dtr'] = typeMDR.data;
    }
    // }else if(mdr.type === 'DTR' && typeMDR.data){
    //   const drList = [];
    //   for(let i = typeMDR.data.length-1; i>=0; i--){
    //     const dr = new DR();
    //     dr.deliveryAddress = typeMDR.data[i].address;
    //     dr.lat = typeMDR.data[i].lat;
    //     dr.lng = typeMDR.data[i].lng;
    //     dr.uf = typeMDR.data[i].uf;
    //     earlydate.setDate(earlydate.getDate() - i - 1);
    //     dr.dateScheduling = earlydate;
    //     drList.push(dr);
    //   }
    //   //console.log(drList);
    // }
    this.mdrService.insert(mdr, flag).subscribe( (data) => {
      this.drService.getAllOpen().subscribe(
        (data2) => {
          this.data = data2;
          this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
            duration: 4000,
          });
          this.router.navigate(['delivery-optimizer']);
        }
      );
    });
  }

  onSelection(selected) {
    this.selected = selected;
  }
}

@Component({
  selector: 'app-type-mdr-dialog',
  templateUrl: 'type-mdr-dialog.html',
  styleUrls: ['./delivery-optimizer.component.scss']
})
export class TypeMdrDialogComponent implements OnInit {
  public types = [{id: 'EDB', name: 'EDB'}, {id: 'ED', name: 'ED'}, {id: 'CMS', name: 'CMS'}, {id: 'DTR', name: 'DTR'}, {id: 'Cross Doc', name: 'Cross Doc'}];
  typeMDR: any;
  returnLeg = false;
  listAddresses = [];
  addressReturn = [];
  address = null;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor( public dialogRef: MatDialogRef<TypeMdrDialogComponent>, private mapsAPILoader: MapsAPILoader, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.typeMDR = null;

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
    });
  }

  addAddress() {
    const address = ((document.getElementById('deliveryAddress') as HTMLInputElement).value);
    this.listAddresses.push({address: address});
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': address}, (results, status) => {
      // console.log(results);
      const index = this.listAddresses.length - 1;
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        const uf = results[0].address_components[2].short_name;
        this.addressReturn.push({address: address, lat: lat, lng: lng, uf: uf});
        // console.log(this.addressReturn);
      } else {
        this.listAddresses.splice(index, 1);
        // this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
        // console.log(status);
      }
    });
  }

  removeAddress(i) {
    this.listAddresses.splice(i, 1);
  }

  onYesClick(): void {
    if (this.typeMDR === 'CMS') {
      this.dialogRef.close({type : this.typeMDR, data: this.returnLeg});
    } else if (this.typeMDR === 'DTR') {
      this.dialogRef.close({type : this.typeMDR, data: this.addressReturn});
    } else {
      this.dialogRef.close({type : this.typeMDR, data: false});
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
