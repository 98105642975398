import { Component, OnInit, ViewChild, Inject, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { DrService } from '../../../../providers/dr.service';

@Component({
  selector: 'app-dr-card-without-login',
  templateUrl: './dr-card.component.html',
  styleUrls: ['./dr-card.component.scss']
})
export class DrCardComponent implements OnInit {
  token: string;
  dr: any;
  color: string;
  tooltip: string[] = [];
  pickupID: any = null;
  shippingCompany = '';

  icons = {
    'red': 'fa-exclamation-circle',
    'yellow': 'fa-exclamation-triangle',
    'green': '',
    'undefined': ''
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private drService: DrService
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.drService.getDrWithToken(this.token).subscribe((data) => {
      if (!data) { return; }
      this.dr = data;
      this.setStatusColor();
      if (this.dr.idMDR !== null) {
        this.shippingCompany = this.dr.mdr.shippingCompany;
        this.pickupID = this.dr.mdr.pickupID;
      }
    });
  }

  getStatusClass() {
    return 'dr-status-' + this.color;
  }

  setStatusColor() {
    this.tooltip = [];
    this.color = 'green';
    const hora = 1000 * 60 * 60;
    const now = Date.now();

    if (this.dr === null) {
      return;
    }

    // verifica as ocorrencias da MDR
    if ( this.dr.occurrences.length > 0 ) {
      const occurrencesNotSolved = [];
      for (let i = 0; i < this.dr.occurrences.length; i++ ) {
        if (this.dr.occurrences[i].status === 'OCCURRENCE_TO_RESOLVE' ) {
          occurrencesNotSolved.push(this.dr.occurrences[i]);
        }
      }
      if (occurrencesNotSolved.length > 0) {
        const s = ((occurrencesNotSolved.length === 1) ? '' : 's');
        if (occurrencesNotSolved.some((el) => {
          const d = new Date(el.createdAt);
          return (now - d.getTime() > 2 * hora);
        })) {
          // se alguma ocorrencia foi criada mais de 2h atrás
          this.color = 'red';
          this.tooltip.push('há ' + this.dr.occurrences.length + ' ocorrência' + s + ' pendente' + s);
        } else {
          // se alguma ocorrencia foi criada, e todas menos de 2h atrás
          this.color = (this.color === 'red') ? this.color : 'yellow';
          this.tooltip.push('há ' + this.dr.occurrences.length + ' ocorrência' + s + ' pendente' + s);
        }
      }
    }

    // se existe um caminhao para verificar
    if ( this.dr.status === 'InTransit' ) {
      if ( this.dr.driverPosition === undefined ) {
        // se o caminhao ainda nao foi visto
        this.color = 'red';
        this.tooltip.push('caminhão não encontrado');
      } else {
        const d = new Date(this.dr.driverPosition.createdAt);
        if ( now - d.getTime() > 1 * hora ) {
          // se o caminhao nao é visto a mais de 1h
          this.color = 'red';
          this.tooltip.push('caminhão não encontrado');
        } else {
          if ( now - d.getTime() > 1 * hora ) {
            // se o caminhao nao é visto a mais de 1h
            this.color = 'red';
            this.tooltip.push('caminhão não é visto a mais de 1 hora');
          } else if ( now - d.getTime() > 0.5 * hora ) {
            // se o caminhao nao é visto a mais de 30min
            this.color = (this.color === 'red') ? this.color : 'yellow';
            this.tooltip.push('caminhão não é visto a mais de 30 minutos');
          }
        }
      }
    }
  }

}
