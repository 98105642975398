import { ElementRef, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
var OccurrenceListComponent = /** @class */ (function () {
    function OccurrenceListComponent(dialog, drService, mdrService, router, snackBar, dpipe) {
        this.dialog = dialog;
        this.drService = drService;
        this.mdrService = mdrService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.displayedColumns = ['idMDR', 'uoc', 'text', 'createdAt'];
        this.dataSource = new MatTableDataSource(this.data);
    }
    OccurrenceListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mdrService.getOccurencesList().subscribe(function (data2) {
            _this.data = data2;
            _this.dataSource = new MatTableDataSource(_this.data);
        });
    };
    OccurrenceListComponent.prototype.navigateToMDR = function (id) {
        this.router.navigate(['logistic-order/' + id]);
    };
    // unmakeDR() {
    //   this.selection.selected.forEach((el) => {
    //     this.drService.unmakeDR(el).subscribe(
    //       (data) => {
    //         //console.log('Removed');
    //       });
    //   });
    //
    //   this.drService.getAllOpen().subscribe(
    //     (data2) => {
    //       this.data = data2;
    //       this.dataSource = new MatTableDataSource<DR>(this.data);
    //       this.snackBar.open('Ordens de entrega desfeitas com sucesso', 'Ok', {
    //         duration: 4000,
    //       });
    //     }
    //   );
    // }
    // createNewMDR() {
    //   const mdr = new MDR();
    //   mdr.status = 'MDR em criação';
    //   this.selection.selected.forEach((el: DR) => {
    //     mdr.drList.push(el);
    //   });
    //   this.mdrService.insert(mdr).subscribe( (data) => {
    //     this.drService.getAllOpen().subscribe(
    //       (data2) => {
    //         this.data = data2;
    //         this.dataSource = new MatTableDataSource<DR>(this.data);
    //         this.snackBar.open('Ordens de entrega liberada com sucesso', 'Ok', {
    //           duration: 4000,
    //         });
    //       }
    //     );
    //   });
    // }
    OccurrenceListComponent.prototype.applyFilter = function (filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    };
    return OccurrenceListComponent;
}());
export { OccurrenceListComponent };
