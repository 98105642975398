var ZZTPXITEM = /** @class */ (function () {
    function ZZTPXITEM() {
        this.id = null;
        this.delivery = null;
        this.handlingUnit = null;
        this.material = null;
        this.plant = null;
        this.sLoc = null;
        this.length = null;
        this.width = null;
        this.height = null;
        this.huTotalWeight = null;
        this.tareWeight = null;
        this.tareWeightUnit = null;
        this.tareVolume = null;
        this.huVolumeUnit = null;
        this.huLoadingWeight = null;
        this.packedQty = null;
        this.insertedBy = null;
        this.updatedBy = null;
        this.insertDate = null;
        this.updateDate = null;
        this.active = null;
    }
    return ZZTPXITEM;
}());
export { ZZTPXITEM };
