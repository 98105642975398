import { Component, OnInit,
         Inject,
         ViewChild, Input, Output,
         OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material';
import * as dateFormat from 'dateformat';
import {XlsxService} from '../../services/xlsx.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {CookieService} from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
  // inputs externos
  @Input('this') parent: any; // tslint:disable-line:no-input-rename
  @Input() columns: any[] = [];
  @Input() data: any[] = [];
  // callbacks
  @Input() styleCallback: any = null;
  @Input() clickCallback: any = null;
  // gerados a partir de inputs
  displayColumns: string[];
  // relativos à tabela em si
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // relativos a checkboxes
  @Input() showCheckbox = false;
  @Output() check = new EventEmitter<any[]>();
  selectColumnName = 'select';
  selection = new SelectionModel<any>(true, []);
  // relativos a busca com chips
  removable = true;
  selectable = true;
  addOnBlur = true;
  readonly separatorKeyCodes: number[] = [ENTER, COMMA];
  chips: any[] = [];
  @ViewChild('searchType') searchType;
  // relativos a busca de data
  @Input() dateSearch: string[] = [];
  dates: any[] = [ null, null ];
  // relativos a download
  @Input() downloadAs: string;
  // relativos a formatação
  decimalPoints = 2;
  // relativos a busca avançada
  showAdvancedSearch = false;
  advancedSearchValues: any[] = [];
  // relativos a salvar busca
  uid: string;
  @Input() disableCookie = false;

  constructor(
    private xlsx: XlsxService,
    private dialog: MatDialog,
    private router: Router,
    private cookie: CookieService
  ) { }
  ngOnInit() {
    this.searchType.value = 'and';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.setUniqueID();
    this.loadSearches();
    this.loadColumns();
  }
  ngOnChanges(changes: SimpleChanges) {
    // inicializa a tabela
    this.setColumns();
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /********************
   * auxiliares a salvar buscas
   */
  setUniqueID() {
    // TODO componente pai para usar como id unico (https://angular.io/guide/dependency-injection-navtree)
    this.uid = [this.router.url].join('-');
  }
  saveSearches() {
    if ( this.disableCookie ) { return; }
    this.cookie.set(this.uid + 'chips', JSON.stringify(this.chips));
    this.cookie.set(this.uid + 'searchType', JSON.stringify(this.searchType.value));
    this.cookie.set(this.uid + 'dates', JSON.stringify(this.dates));
    this.cookie.set(this.uid + 'advancedSearchValues', JSON.stringify(this.advancedSearchValues));
    this.cookie.set(this.uid + 'showAdvancedSearch', JSON.stringify(this.showAdvancedSearch));
  }
  loadSearches() {
    if ( this.disableCookie ) { return; }
    if (this.cookie.check(this.uid + 'chips')) {
      try {
        this.chips = JSON.parse(this.cookie.get(this.uid + 'chips'));
      } catch (ex) {}
    }
    if (this.cookie.check(this.uid + 'searchType')) {
      try {
        this.searchType.value = JSON.parse(this.cookie.get(this.uid + 'searchType'));
      } catch (ex) {}
    }
    if (this.cookie.check(this.uid + 'dates')) {
      try {
        const dates = JSON.parse(this.cookie.get(this.uid + 'dates'));
        if ( dates[0] !== null ) { this.dates[0] = new Date(dates[0]); }
        if ( dates[1] !== null ) { this.dates[1] = new Date(dates[1]); }
      } catch (ex) {}
    }
    if (this.cookie.check(this.uid + 'advancedSearchValues')) {
      try {
        this.advancedSearchValues = JSON.parse(this.cookie.get(this.uid + 'advancedSearchValues'));
      } catch (ex) {}
    }
    if (this.cookie.check(this.uid + 'showAdvancedSearch')) {
      try {
        this.showAdvancedSearch = JSON.parse(this.cookie.get(this.uid + 'showAdvancedSearch'));
      } catch (ex) {}
    }
    this.applyFilter();
  }

  /********************
   * auxiliares a formatação das células
   */
  typeof(row, name) {
    if ('icon' in this.columns.find(e => e.value === name)) {
      return 'icon';
    } else if (Object.prototype.toString.call(row[name]) === '[object Date]') {
      return 'date';
    } else if ('currency' in this.columns.find(e => e.value === name)) {
      return 'currency';
    } else if ( typeof row[name] === 'number' && row[name] % 1 !== 0 ) {
      return 'float';
    } else {
      return typeof row[name];
    }
  }
  rowCurrency(name) {
    return this.columns.find(e => e.value === name).currency;
  }

  /********************
   * estilo da célula
   */
  style(row) {
    if ( this.styleCallback ) {
      return this.styleCallback(row, this.parent);
    }
  }

  /*********************
   * ao clicar na linha
   */
  onClick(row) {
    if ( this.clickCallback ) {
      this.clickCallback(row, this.parent);
    }
  }

  /***********************
   * relativos a checkboxes
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.dataSource.data.forEach(row => this.selection.select(row));
  }
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  onSelection(event, row?) {
    if ( event ) {
      if ( row ) {
        this.selection.toggle(row);
      } else {
        this.masterToggle();
      }
    }
    this.check.emit(this.selection.selected);
  }

  /***********************
   * relativos a busca simples
   */
  addChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      for (const text of value.split(',')) {
        this.chips.push({'name': text.trim()});
      }
    }
    if (input) {
      input.value = '';
    }
    this.applyFilter();
  }
  removeChip(chip): void {
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips.splice(index, 1);
    }
    this.applyFilter();
  }
  getChips() {
    return this.chips.map(e => e.name);
  }
  applySimpleSearch() {
    // busca
    this.dataSource.filterPredicate = (data: any, mergedFilter: string ) => {

      // função de busca por chip
      const checkChip = (chip) => {
        return this.columns.map(e => e.value).some(key => {
          if (key in data && data[key] !== null) {
            switch (typeof(data[key])) {
              case 'string':
                return(data[key].toLowerCase().indexOf(chip.toLowerCase()) !== -1);
                break;
              case 'number':
                return(parseFloat(data[key]) === parseFloat(chip));
                break;
            }
          }
          return false;
        });
      };

      // função de busca por data
      const checkDate = (date) => {
        // nao é Date
        if ( Object.prototype.toString.call(data[date]) !== '[object Date]' ) {
          return false;
        }
        // data inicial
        if (this.dates[0] && data[date].getTime() < this.dates[0].getTime()) {
          return false;
        }
        // data final
        if (this.dates[1] && data[date].getTime() > this.dates[1].getTime()) {
          return false;
        }
        return true;
      };

      /***************
       *  Tabela verdade da busca:
       *
       *  | E/OU | data   | chips  | busca realizada             |
       *  |------+--------+--------+-----------------------------|
       *  | E    | EXISTE | vazio  | some(data)                  |
       *  | E    | vazio  | EXISTE | every(chips)                |
       *  | E    | EXISTE | EXISTE | some(data) E every(chips)   |
       *  | OU   | EXISTE | vazio  | some(data)                  |
       *  | OU   | vazio  | EXISTE | some(chips)                 |
       *  | OU   | EXISTE | EXISTE | some(data) OU some(chips)   |
       *  | E/OU | vazio  | vazio  | true                        |
       *
       */
      // realiza a busca
      const chips = this.getChips();
      // busca sem chips
      if (chips.length === 0) {
        if (this.dates[0] === null && this.dates[1] === null) { // nenhuma busca
          return true;
        } else { // busca somente com data
          return this.dateSearch.some(checkDate);
        }
      }
      // busca sem data
      if (this.dates[0] === null && this.dates[1] === null) {
        if ( chips.length === 0) { // nenhuma busca (redundante)
          return true;
        } else { // busca somente com chips
          if ( this.searchType.value === 'and' ) { // busca com E lógico
            return chips.every(checkChip);
          } else { // busca com OU lógico
            return chips.some(checkChip);
          }
        }
      }
      // busca com chips e data
      if ( this.searchType.value === 'and' ) { // busca com E lógico
        return chips.every(checkChip) && this.dateSearch.some(checkDate);
      } else { // busca com OU lógico
        return chips.some(checkChip) || this.dateSearch.some(checkDate);
      }

    };
    // atribuição necessária pra aplicar a busca
    this.dataSource.filter = 'this.dataSource.data';
    // paginação
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /************************
   * relativos a busca avançada
   */
  addSearchField() {
    this.advancedSearchValues.push({
      field: '',
      chips: <any[]>[],
    });
  }
  removeSearchField(i) {
    this.advancedSearchValues.splice(i, 1);
    this.applyFilter();
  }
  getFields() {
    return this.advancedSearchValues.filter(e => {
      return (e.chips.length > 0 && e.field !== '');
    });
  }
  addChipFromAdvanced(searchChips: any[], event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      for (const text of value.split(',')) {
        searchChips.push({'name': text.trim()});
      }
    }
    if (input) {
      input.value = '';
    }
    this.applyFilter();
  }
  removeChipFromAdvanced(searchChips, chip): void {
    const index = searchChips.indexOf(chip);
    if (index >= 0) {
      searchChips.splice(index, 1);
    }
    this.applyFilter();
  }
  applyAdvancedSearch() {
    // busca
    this.dataSource.filterPredicate = (data: any, mergedFilter: string ) => {

      // função de busca a partir de um campo
      const checkField = (search) => {
        if ( search.field in data && data[search.field] !== null ) {
          switch (typeof(data[search.field])) {
            case 'string':
              // busca com OU lógico dentre os chips
              return search.chips.some( (chip: {name: string}) => {
                return (data[search.field].toLowerCase().indexOf(chip.name.toLowerCase()) !== -1);
              });
              break;
            case 'number':
              // busca com OU lógico dentre os chips
              return search.chips.some( (chip: {name: string}) => {
                return (parseFloat(data[search.field]) === parseFloat(chip.name));
              });
              break;
          }
        }
        return false;
      };

      // função de busca por data
      const checkDate = (date) => {
        // nao é Date
        if ( Object.prototype.toString.call(data[date]) !== '[object Date]' ) {
          return false;
        }
        // data inicial
        if (this.dates[0] && data[date].getTime() < this.dates[0].getTime()) {
          return false;
        }
        // data final
        if (this.dates[1] && data[date].getTime() > this.dates[1].getTime()) {
          return false;
        }
        return true;
      };

      /***************
       *  Tabela verdade da busca:
       *
       *  | E/OU | data   | fields  | busca realizada              |
       *  |------+--------+---------+------------------------------|
       *  | E    | EXISTE | vazio   | some(data)                   |
       *  | E    | vazio  | EXISTE  | every(fields)                |
       *  | E    | EXISTE | EXISTE  | some(data) E every(fields)   |
       *  | OU   | EXISTE | vazio   | some(data)                   |
       *  | OU   | vazio  | EXISTE  | some(fields)                 |
       *  | OU   | EXISTE | EXISTE  | some(data) OU some(fields)   |
       *  | E/OU | vazio  | vazio   | true                         |
       *
       */
      // realiza a busca
      const fields = this.getFields();
      // busca sem fields
      if (fields.length === 0) {
        if (this.dates[0] === null && this.dates[1] === null) { // nenhuma busca
          return true;
        } else { // busca somente com data
          return this.dateSearch.some(checkDate);
        }
      }
      // busca sem data
      if (this.dates[0] === null && this.dates[1] === null) {
        if ( fields.length === 0) { // nenhuma busca (redundante)
          return true;
        } else { // busca somente com fields
          if ( this.searchType.value === 'and' ) { // busca com E lógico
            return fields.every(checkField);
          } else { // busca com OU lógico
            return fields.some(checkField);
          }
        }
      }
      // busca com fields e data
      if ( this.searchType.value === 'and' ) { // busca com E lógico
       // console.log(fields.every(checkField), this.dateSearch.some(checkDate));
        return fields.every(checkField) && this.dateSearch.some(checkDate);
      } else { // busca com OU lógico
        return fields.some(checkField) || this.dateSearch.some(checkDate);
      }

    };
    // atribuição necessária pra aplicar a busca
    this.dataSource.filter = 'this.dataSource.data';
    // paginação
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /************************
   * relativos à troca entre busca simples e avançada
   */
  applyFilter() {
    this.saveSearches();
    if ( this.showAdvancedSearch ) {
      this.applyAdvancedSearch();
    } else {
      this.applySimpleSearch();
    }
  }
  toggleAdvancedSearch() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
    this.applyFilter();
  }

  /************************
   * relativos a download
   */
  flatten(data) {
    // tirado de : https://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-json-objects
    const result = {};
    function recurse (cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        let i, l;
        for (i = 0, l = cur.length; i < l; i++) {
          recurse(cur[i], prop + '[' + i + ']');
        }
        if (l === 0) {
          result[prop] = [];
        }
      } else {
        let isEmpty = true;
        for (const p of Object.keys(cur)) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) {
          result[prop] = {};
        }
      }
    }
    recurse(data, '');
    return result;
  }
  flatmap(obj) {
    const flat = {};
    // pega os campos (menos id)
    const keys = obj ? Object.keys(obj).filter(e => e !== 'id') : [];
    // itera sobre todos os campos
    for ( let k = 0, lk = keys.length ; k < lk ; k++ ) {
      if (Object.prototype.toString.call(obj[keys[k]]) === '[object Date]') {
        // Date
        // formata
        flat[keys[k]] = dateFormat(obj[keys[k]], 'mmm d, yyyy, HH:MM:ss');
      } else if ( typeof obj[keys[k]] !== 'object' ) {
        // não é object
        // retorna o valor como está
        flat[keys[k]] = obj[keys[k]];
      } else {
        // nested object
        // chama recursivamente
        flat[keys[k]] = this.flatmap(obj[keys[k]]);
      }
    }
    return this.flatten(flat);
  }

  getMostRecentAndAllOcc(obj) {
    if (!obj.occ || obj.occ.length === 0) {
      return [];
    }

    const occurrenceColumns = obj.occ.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                                   .map(occ => {
                                     return `${dateFormat(occ.createdAt, 'mmm d, yyyy, HH:MM:ss')}  ${occ.text}`;
                                   });

    return [occurrenceColumns[0], occurrenceColumns.join('\n')];
  }

  async download() {
    const table = [];
    // itera sobre todos os valores que aparecem
    for ( let i = 0, l = this.dataSource.filteredData.length ; i < l ; i++ ) {
      const parsedOccs = await this.getMostRecentAndAllOcc(this.dataSource.filteredData[i]);

      const data = Object.assign({}, this.dataSource.filteredData[i]);

      if (data.occ) {
        delete data.occ;
      }

      const row = this.flatmap(data);

      // pega os campos
      const keys = Object.keys(row);

      const newrow = {};

      // itera sobre todos os campos
      for ( let k = 0, lk = keys.length ; k < lk ; k++ ) {
        // newrow[keys[k]] = this.formatField(row,keys[k]);
        newrow[keys[k]] = row[keys[k]];
        // console.log('%s[%s] : %s',keys[k],typeof row[keys[k]],newrow[keys[k]]);
      }

      if (parsedOccs && parsedOccs.length) {
        newrow['mostRecentOcc'] = parsedOccs[0];
        newrow['occurrenceAll'] = parsedOccs[1];
      }

      table.push(newrow);
    }
    // exporta
    this.xlsx.export(table, this.downloadAs);
  }

  /************************
   * relativos a mostrar/ocultar colunas
   */
  prepareColumns() {
    this.columns.forEach((column) => {
      if ( !('show' in column) ) {
        column['show'] = true;
      }
    });
  }
  setColumns() {
    // monta a lista de displayColumns
    this.prepareColumns();
    if ( this.showCheckbox ) {
      this.displayColumns = [this.selectColumnName].concat(this.columns.filter(e => e.show).map(e => e.value));
    } else {
      this.displayColumns = this.columns.filter(e => e.show).map(e => e.value);
    }
  }
  toggleColumns() {
    const dialogRef = this.dialog.open(TableToggleColumnsDialogComponent, {
      maxHeight: '80vh',
      data: {
        columns: this.columns
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.setColumns();
      this.saveColumns();
    });
  }
  saveColumns() {
    if ( this.disableCookie ) { return; }
    this.cookie.set(this.uid + 'columns', JSON.stringify(this.columns));
  }
  loadColumns() {
    if ( this.disableCookie ) { return; }
    if (this.cookie.check(this.uid + 'columns')) {
      const savedColumns = JSON.parse(this.cookie.get(this.uid + 'columns'));
      // pega valores .show de valores salvos
      this.columns.forEach((col) => {
        const savedCol = savedColumns.find(c => c.value === col.value);
        col.show = (savedCol.show === true);
      });
    }
    this.setColumns();
  }

}

@Component({
  selector: 'app-table-toggle-columns-dialog',
  templateUrl: 'table-toggle-columns-dialog.html',
  styleUrls: ['./table.component.scss']
})
export class TableToggleColumnsDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<TableToggleColumnsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onYesClick(): void {
    this.dialogRef.close();
  }

}
