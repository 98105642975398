import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {OVService} from '../../providers/ov.service';
import {OV} from '../../models/ov';
import {Router} from '@angular/router';
import {DrService} from '../../providers/dr.service';
import {SelectionModel} from '@angular/cdk/collections';
import {DR} from '../../models/dr';
import { parseString } from 'xml2js';
import {NfeService} from '../../providers/nfe.service';
import {NF} from '../../models/nfe';
import {HUB} from '../../models/hub';
import {HubService} from '../../providers/hub.service';
import {ExcelService} from '../../misc/export-xlsx/export-xlsx';
import { ShipmentService } from '../../providers/shipment.service';
import { ZZCustom } from '../../models/zzcustom';
import { Shipment } from '../../models/shipment';
import { ZZTPXHEADER } from '../../models/zztpxheader';

declare var google: any;

@Component({
  selector: 'app-new-shipment-request',
  templateUrl: './new-shipment-request.component.html',
  styleUrls: ['./new-shipment-request.component.scss']
})

export class NewShipmentRequestComponent implements OnInit {
  public searchControl: FormControl;
  public searchControl2: FormControl;
  public dr: DR = new DR();
  uploaded = false;
  displayedColumns = ['city', 'shipment', 'client', 'shippingTypeDescription', 'incoterms1', 'forwardingAgentName', 'plannedShipmentEnd'];
  dataSource = new MatTableDataSource<ZZTPXHEADER>();
  inputOVs: number;
  inputNFs: string;
  ovOrNf = false;
  shipment = new Shipment();
  opened: any;
  checkedColect: any;
  public dateCollect: Date;
  public hour: string;
  minDate = new Date();
  weight = 0;
  volume = null;
  displayedColumns2 = ['salesDocument', 'referenceNoNf', 'customerName', 'materialDescription'];
  dataSourceOV = new MatTableDataSource<any>();
  displayedColumns3 = ['shipment', 'obs', 'receiverName', 'shipToCity', 'client', 'shipToStreet', 'confirmedDeliveryDate', 'weight', 'volume'];
  dataSourceDR = new MatTableDataSource<any>();
  initialSelection: OV[] = [];
  role: number;
  newId: number;
  selection = new SelectionModel<OV>(true, this.initialSelection);
  newDrList: Shipment[] = [];
  hubList: HUB[];

  @ViewChild(MatSort) sort: MatSort;

  // @ViewChild('search2')
  // public searchElement2Ref: ElementRef;

  constructor(public dialog: MatDialog, private mapsAPILoader: MapsAPILoader, public ovService: OVService,
              public router: Router, public drService: DrService, public nfService: NfeService,
              public excelService: ExcelService, public shipmentService: ShipmentService,
              public snackBar: MatSnackBar, public hubService: HubService) {
    this.dataSource.sort = this.sort;
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = currentUser.user.role;
    if (this.role === 1) {
      this.minDate = new Date('1970-01-01');
    }
    this.drService.getLastIdInserted().subscribe( (data) => {
      this.newId = data;
      console.log(this.newId);
    });
    this.hubService.getAll().subscribe( (data) => {
      this.hubList = data;
    });
  }

  ngOnInit() {

    this.searchControl2 = new FormControl();

    /* this.mapsAPILoader.load().then(() => {
     *   const autocomplete = new google.maps.places.Autocomplete(this.searchElement2Ref.nativeElement, {
     *     types: ['address']
     *   });
     * }); */
  }

  convertAddressToLatLng() {
    const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.dr.lat = lat;
        this.dr.lng = lng;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
       //// console.log(status);
      }
    });
  }

  convertDRAddressToLatLng(dr: DR): boolean {
    const error = false;
    const geocoder = new google.maps.Geocoder();
    const addr = dr.deliveryAddress;
    return geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        dr.lat = results[0].geometry.location.lat();
        dr.lng = results[0].geometry.location.lng();
        return true;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
        return false;
      }
    });
  }

  convertAddressToLatLngXml(addr): any {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        // console.log('true');
        return true;
      } else {
        // console.log('false');
        return false;
      }
    });
  }

  convertLatLngToAddress() {
    if (this.dr.lat === null || this.dr.lng === null) {
      return;
    }
    const lat = (document.getElementById('latitudeAddress') as HTMLInputElement).value;
    const lng = (document.getElementById('longitudeAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(lat, lng);
    geocoder.geocode({'latLng': location}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const addr = results[0].formatted_address;
        this.dr.deliveryAddress = addr;
      } else {
        this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', {duration: 2000});
       //// console.log(status);
      }
    });
  }

  addNewOV(): void {
    this.selection.selected.forEach(el => {
      if ( this.dataSourceOV.data.indexOf(el) < 0) {
        this.dataSourceOV.data.push(el);
      }
    });

    this.dataSourceOV = new MatTableDataSource<any>(this.dataSourceOV.data);
  }

  openDialog(): void {
    // generate a new ID based on last MDR
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '600px',
      data: {
        id: this.dataSource.data,
        value: 0,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 1) {
        this.shipment.observation = this.dr.obs;
        this.shipment.receiverPhone = this.dr.receiverPhone.valueOf() + '';
        this.shipment.receiverName = this.dr.receiverName;
        this.shipment.shipmentNumber = Number(this.dataSource.data[0].shipment);
        this.shipmentService.insert(this.shipment).subscribe(
          (data2) => {
            this.router.navigate(['shipment-optimizer']);
          }
        );
      }
    });
  }

  insertShippment() {
    this.shipment.observation = this.dr.obs;
    this.shipment.receiverPhone = this.dr.receiverPhone.valueOf() + '';
    this.shipment.receiverName = this.dr.receiverName;
    this.shipment.shipmentNumber = Number(this.dataSource.data[0].shipment);
    this.shipmentService.insert(this.shipment).subscribe(
      (data2) => {
        this.router.navigate(['shipment-optimizer']);
      }
    );
  }

  openFile(fileupload: any) {
    const input = fileupload.target;
    const error = false;
    // console.log(fileupload);
    this.newDrList = [];
    const ovList = [];
    const re = /(?:\.([^.]+))?$/;
    for (let index = 0; index < input.files.length; index++) {
      const ext = re.exec(input.files[index].name)[1];
      if ( ext === 'xlsx' ) {
        this.excelService.importFromExcel(fileupload).subscribe( (response) => {
          response.forEach( (el, i, arr) => {
            const drAux = new Shipment();
            try {
              // drAux.pickupAddress = el.pickup_address;
              // drAux.deliveryAddress = el.delivery_address;
              // drAux.site = el.site;
              drAux.receiverName = el.receiver_name;
              drAux.receiverPhone = el.receiver_phone;
              // drAux.supervisorName = el.supervisor_name;
              // drAux.supervisorPhone = el.supervisor_phone;
              // drAux.infraType = el.infra;
              drAux.observation = el.obs;
              // drAux.status = el.shipment;
             // console.log(el.ov);
             // console.log(drAux.status);

              // if ( this.convertDRAddressToLatLng(drAux) === false ) {
              //   this.snackBar.open('Endereço inválido', 'Ok', {duration: 2000});
              //   error = true;
              // }
             // console.log(drAux);
              drAux.shipmentNumber = el.shipment;
              this.shipmentService.getAll(drAux.shipmentNumber).subscribe(
                (data) => {
                  if (data === null) {
                    this.snackBar.open('Not Found Shippment in Database', 'Ok', {duration: 4000});
                  } else {
                    console.log(data);
                    drAux.shipToCity = data.s.shipToCity;
                    drAux.nameOfTheEndUserForFTrade = data.s.nameOfTheEndUserForFTrade;
                    drAux.shipToStreet = data.s.shipToStreet;
                    drAux.confirmedDeliveryDate = data.s.confirmedDeliveryDate;
                    drAux.weight = data.w;
                    drAux.volume =  Number(data.v).toFixed(2);
                    this.newDrList.push(drAux);
                    this.uploaded = true;
                    this.dataSourceDR = new MatTableDataSource<Shipment>(this.newDrList);
                  }
                });

            } catch (ex) {
             // console.log('erro ao ler arquivo xlsx ',ex);
              this.snackBar.open('Erro ao ler arquivo', 'Ok', {duration: 4000});
            }

            // this.uploaded = true;
            // this.dataSourceDR = new MatTableDataSource<Shipment>(this.newDrList);
          });
        });
      }
    }
    // console.log(this.newDrList);
    // console.log(this.dataSourceDR);
  }

  insertAllShippment(): void {
    this.dataSourceDR.data.forEach (el => {
      this.shipmentService.insert(el).subscribe(
        (data2) => {
          this.uploaded = false;
        }
      );
    });
  }


  searchShipment(): void {
    // console.log(this.inputOVs);
    if (this.inputOVs === undefined) {
      this.snackBar.open('Preencha um valor de OV', 'Ok', {duration: 4000});
    } else {
      this.shipmentService.getAll(this.inputOVs).subscribe(
        (data) => {
          console.log(data);
          if (data === null) {
            this.snackBar.open('Not Found Shippment in Database', 'Ok', {duration: 4000});
          } else {
            this.weight = data.w;
            this.volume = Number(data.v).toFixed(2);
            this.dataSource = new MatTableDataSource<ZZTPXHEADER>([data.s]);
          }
        });
    }
  }
}

@Component({
  selector: 'app-dialog-overview-dialog',
  templateUrl: '../new-shipment-request/dialog-overview-dialog.html',
  styleUrls: ['./new-shipment-request.component.scss']
})
export class DialogOverviewComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onYesClick(): void {
    this.data.value = 1;
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.data.value = 0;
    this.dialogRef.close();
  }

}
