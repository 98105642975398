import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { OV } from '../models/ov';
import {environment} from '../../environments/environment';
import {Zhstatus} from '../models/zhstatus';
import {Zrsdpac} from '../models/zrsdpac';
import { ZZTPXHEADER } from '../models/zztpxheader';
import { ZZTPXITEM } from '../models/zztpxitem';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class OVService {
  public token: string;

  constructor(private http: HttpClient) {
      // set token if saved in local storage
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
  }

  getAll(id: number): Observable<any> {
    if (id === null) { return null; }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'OV/' + id, {headers: header});
  }

  saveMultipleTPXH(listTPXH: ZZTPXHEADER[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'OV/TPXH', {
      listTPXH: listTPXH
    }, {headers: header})
      .map((response) => {
        return response;
      });
  }
  saveMultipleTPXI(listTPXI: ZZTPXITEM[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'OV/TPXI', {
      listTPXI: listTPXI
    }, {headers: header})
      .map((response) => {
        return response;
      });
  }
  saveMultipleOV(listOV: OV[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    return this.http.post(base_url + 'OV', {
      listOV: listOV
    }, {headers: header})
      .map((response) => {
        return response;
      });
  }

  saveMultipleZH(listZH: Zhstatus[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    return this.http.post(base_url + 'OV/zh', {
      listZH: listZH
    }, {headers: header})
      .map((response) => {
        return response;
      });
  }

  saveMultipleZRS(listZRS: Zrsdpac[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    return this.http.post(base_url + 'OV/zrs', {
      listZRS: listZRS
    }, {headers: header})
      .map((response) => {
        return response;
      });
  }


  getNfByMdr(id: number): Observable<any> {
    if (id === null) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'OV/nf/' + id, {headers: header})
      .map((response) => {
        const OVdata = response;
        return OVdata;
      });
  }

  validateOVs(idList: string[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    return this.http.post(base_url + 'OV/validate', {
      idList: idList
    }, {headers: header})
               .map((response) => {
                 return response;
               });
  }

  validateHub(hubName: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'OV/validateHUB', {
      hub: hubName
    }, {headers: header})
               .map((response) => {
                 return response;
               });
  }

  getVehicle(ovs: number[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const params = new HttpParams().set('ovs', '[' + ovs.toString() + ']');
    return this.http.get(base_url +  'OV/vehicle', {headers: header, params: params});
  }

  searchByOV(ov: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'OV/search/' + ov, {headers: header});
  }
}
