import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var AirportService = /** @class */ (function () {
    function AirportService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    AirportService.prototype.getAll = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'airports', { headers: header }).map(function (response) {
            return response;
        });
    };
    AirportService.prototype.getById = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'airports/' + id, { headers: header });
    };
    AirportService.prototype.update = function (airport) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.put(base_url + 'airports/' + airport.id, airport, { headers: header });
    };
    AirportService.prototype.create = function (airport) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(base_url + 'airports', airport, { headers: header });
    };
    return AirportService;
}());
export { AirportService };
