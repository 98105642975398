import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { DR } from '../models/dr';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var DrService = /** @class */ (function () {
    function DrService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    DrService.prototype.insert = function (dr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'DR/', {
            dr: dr,
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.insertFake = function (dr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(dr);
        return this.http.post(base_url + 'DR/fake', {
            dr: dr,
        }, { headers: header }).map(function (response) {
            // console.log(response);
            return response;
        });
    };
    DrService.prototype.getCostsByMdrList = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(dr);
        return this.http.post(base_url + 'DR/getCostsByMdrList', {
            mdr: mdr,
        }, { headers: header }).map(function (response) {
            // console.log(response);
            return response;
        });
    };
    DrService.prototype.getById = function (drId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'DR/' + drId, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getAllInfo = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.get(base_url + 'DR/allInfo/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getAll = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.get(base_url + 'DR/All', { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getForCockpit = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'DR/forCockpit', { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getAllFromLSP = function (lsp) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.post(base_url + 'DR/LSPAll', { lsp: lsp }, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getAllOpen = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'DR/All/open', { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getStatus = function (drId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'DR/status', { drId: drId }, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getLastInserted = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.get(base_url + 'DR/getLastInserted', { headers: header }).map(function (response) {
            // console.log(response);
            return response.date;
        });
    };
    DrService.prototype.getLastIdInserted = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.get(base_url + 'DR/getLastIdInserted', { headers: header }).map(function (response) {
            // console.log(response);
            return response.id;
        });
    };
    DrService.prototype.getTowerTPX = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'DR/tower/tpx', { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getTower = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.get(base_url + 'DR/tower', { headers: header }).map(function (response) {
            // console.log(response);
            return response;
        });
    };
    DrService.prototype.getLSPTower = function (lsp) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.post(base_url + 'DR/lspTower', { lsp: lsp }, { headers: header }).map(function (response) {
            // console.log(response);
            return response;
        });
    };
    DrService.prototype.getAllMDR = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.get(base_url + 'DR/mdr', { headers: header }).map(function (response) {
            // console.log(response);
            return response;
        });
    };
    DrService.prototype.getByMdr = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.get(base_url + 'DR/ByMDR/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getByMdrId = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(header);
        return this.http.get(base_url + 'DR/MDR/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.unmakeDR = function (dr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(base_url + 'DR/unmakeDR', {
            drList: dr,
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.updateAddress = function (dr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + '/DR/', {
            dr: DR,
        }, { headers: header }).map(function (response) {
            // console.log(response);
            return true;
        });
    };
    DrService.prototype.update = function (dr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // console.log(dr)
        return this.http.put(base_url + 'DR/', {
            dr: dr
        }, { headers: header }).map(function (response) {
            // console.log(response);
            return true;
        });
    };
    DrService.prototype.getDrWithToken = function (token) {
        return this.http.get(base_url + 'no-login/DR/' + token).map(function (response) {
            return response;
        });
    };
    DrService.prototype.createBooking = function (dr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'DR/booking', {
            dr: dr,
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getBookings = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'DR/booking', { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getBooking = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'DR/booking/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getValidation = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'DR/booking/' + id + '/validate', { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.updateBooking = function (id, dr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'DR/booking/' + id, {
            dr: dr,
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.updateBookingToDR = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'DR/booking/' + id + '/create', {}, { headers: header }).map(function (response) {
            return response;
        });
    };
    DrService.prototype.getHistory = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'DR/history/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    return DrService;
}());
export { DrService };
