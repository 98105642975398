<div class="grey-background">
   <div class="blue-background back-container">
   </div>
   <div class="row">
      <div class="white-background container-row center-container container-border col-md-10">
         <div class="row container-title">
            <div class="col-md-12">
              {{ 'ATTACHMENTS/title' | translate }}
            </div>
         </div>
         <hr>
         <div class="row border-button">
            <div class="col-md-9">
               <span class="title">Anexos Gerais </span>
              <button *ngIf="nfFiles.length" class="button green-button text-white" style="float: right; width: 150px; height: 38px;" (click)="dowloadAll()"> {{ 'ATTACHMENTS/download' | translate }} </button>
            </div>
            <div *ngFor="let file of fileList" class="col-md-12">
               <div class="row title-anex">
                  <div class="col-md-4 center-text">
                     {{file.name}}
                  </div>
                  <div class="col-md-1 center-text">
                     <app-upload-files *ngIf="role !== 9" [id]="mdr.id" [entity]="file.name" (messageEvent)="receiveFile($event)"  ></app-upload-files>

                  </div>
                  <div class="col-md-7">
                     <div class="d-flex" style="margin: 5px;" *ngFor="let fileT of file.files">
                        <app-image-lightbox class="col-md-4" [images]="[fileT]"></app-image-lightbox>
                        <span class="col-md-4" >{{fileT.fileName}}</span>
                        <button *ngIf="role !== 9" class="button red-button text-white col-md-4"  mat-raised-button   (click)="removeFile(fileT, file.name)"> Remover </button>
                     </div>
               </div>
            </div>
         </div>
         <div *ngIf="nfList !== undefined">
            <div *ngFor="let nf of nfFiles" class="row">
               <div class="column col-md-10" >
                  <a class="e2e-trusted-url" [href]="getSafeURL(nf)" target="_blank"> {{ nf.fileName }} </a>
               </div>
               <div class="column col-md-1" >
                  <button *ngIf="role !== 9" class="button red-button text-white"  mat-raised-button   (click)="removeNF(nf)"> {{ 'ATTACHMENTS/remove' | translate }} </button>
               </div>
            </div>
         </div>
         <div class="row border-button">
            <div class="col-md-9">
               <span class="title">{{ 'ATTACHMENTS/pod' | translate }}</span>
            </div>
         </div>
         <div *ngFor="let nf of nfList" class="border-button">
            <div  class="row title-anex">
               <div class="col-md-9 center-text">
                  {{nf.nf}}
               </div>
               <div class="col-md-3 center-text">
                  <app-upload-files *ngIf="role !== 9" [id]="nf.nf" [entity]="'nf'" (messageEvent)="receiveFile($event)"  ></app-upload-files>
               </div>
            </div>
            <div class="row">
               <div class="col-md-10 my-3" *ngFor="let file of nf.file">
                  <div class="d-flex">
                     <app-image-lightbox class="col-md-3"  [images]="[file]"></app-image-lightbox>
                     <form class="col-md-8">
                       <i class="fa fa-copy" matTooltip="{{ 'ATTACHMENTS/copy' | translate }}" (click)="copyData(file)"></i>
                       <i class="fa fa-paste" matTooltip="{{ 'ATTACHMENTS/paste' | translate }}" (click)="pasteData(file)"></i>
                        <mat-form-field class="col-md-12"><input matInput placeholder="{{ 'ATTACHMENTS/name' | translate }}" name="info_name" [(ngModel)]="file.info_name"></mat-form-field>
                        <mat-form-field class="col-md-4"><input matInput placeholder="{{ 'ATTACHMENTS/rg' | translate }}" name="info_rg" [(ngModel)]="file.info_rg"></mat-form-field>
                        <mat-form-field class="col-md-4">
                           <input name="info_date" matInput [matDatepicker]="myDatepicker" [(ngModel)]="file.info_deliveredAt" placeholder="Data da entrega">
                           <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                           <mat-datepicker #myDatepicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="col-md-3">
                           <input name="info_time" matInput type="time" placeholder="{{ 'ATTACHMENTS/hour' | translate }}" [(ngModel)]="file.info_timeDeliveredAt">
                        </mat-form-field>
                        <div class="col-md-12">{{ 'ATTACHMENTS/date' | translate }} {{ file.createdAt | date :'dd/MM/yyyy hh:mm:ss'}}</div>
                        <div class="d-flex justify-content-between">
                           <button class="button red-button text-white col-md-3"  mat-raised-button   (click)="removeFile(file,'nf')"> Remover </button>
                           <button class="button green-button text-white col-md-3"  mat-raised-button   (click)="savePOD(file)"> Salvar </button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
         <!-- <div class="row border-button">
            <div class="col-md-9">
               <span class="title">{{ 'ATTACHMENTS/uoc' | translate }}</span>
            </div>
            <div *ngIf="uocList !== undefined" class="col-md-12">
               <div *ngFor="let uoc of uocList" class="row">
                  <div class="column col-md-9" >
                     {{ uoc.uoc }}
                  </div>
                  <div class="col-md-3 center-text"  >
                     <app-upload-files [id]="uoc.id" [entity]="'uoc'" (messageEvent)="receiveFile($event)"  ></app-upload-files>
                  </div>
                  <div class="row">
                     <div class="col-md-10 my-3" *ngFor="let file of uoc.files">
                        <app-image-lightbox class="col-md-3" [images]="[file]"></app-image-lightbox>
                     </div>
                  </div>
                  <hr>
             </div>
           </div>
         </div> --->
      </div>
   </div>
</div>
