import { Airport } from './airport';
var Airway = /** @class */ (function () {
    function Airway() {
        this.id = null;
        this.eta = null;
        this.etd = null;
        this.airport = new Airport();
        this.airportId = null;
        this.status = null;
        this.ata = null;
        this.mawb = null;
        this.mdrID = null;
        this.remove = false;
        this.order = null;
        this.createdBy = null;
        this.createdAt = null;
    }
    return Airway;
}());
export { Airway };
