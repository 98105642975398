<h1 mat-dialog-title>{{ dialogTitle | translate }}</h1>

<div mat-dialog-content>

  <form #form="ngForm">
    <!-- icao -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/icao'|translate}}
      </mat-label>
      <input matInput required
        type="text"
        name="icao"
        [(ngModel)]="airport.icao"
      >
    </mat-form-field>
    <!-- iata -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/iata'|translate}}
      </mat-label>
      <input matInput required
        type="text"
        name="iata"
        [(ngModel)]="airport.iata"
      >
    </mat-form-field>
    <!-- name -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/name'|translate}}
      </mat-label>
      <input matInput required
        type="text"
        name="name"
        [(ngModel)]="airport.name"
      >
    </mat-form-field>
    <!-- city -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/city'|translate}}
      </mat-label>
      <input matInput required
        type="text"
        name="city"
        [(ngModel)]="airport.city"
      >
    </mat-form-field>
    <!-- state -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/state'|translate}}
      </mat-label>
      <input matInput required
        type="text"
        name="state"
        [(ngModel)]="airport.state"
      >
    </mat-form-field>
    <!-- country -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/coutry'|translate}}
      </mat-label>
      <input matInput required
        type="text"
        name="country"
        [(ngModel)]="airport.coutry"
      >
    </mat-form-field>
    <!-- elevation -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/elevation'|translate}}
      </mat-label>
      <input matInput required
        type="number"
        name="elevation"
        [(ngModel)]="airport.elevation"
      >
    </mat-form-field>
    <!-- latitude -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/lat'|translate}}
      </mat-label>
      <input matInput required
        type="text"
        name="latitude"
        [(ngModel)]="airport.lat"
      >
    </mat-form-field>
    <!-- longitude -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/lng'|translate}}
      </mat-label>
      <input matInput required
        type="text"
        name="longitude"
        [(ngModel)]="airport.lng"
      >
    </mat-form-field>
    <!-- timezone -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/tz'|translate}}
      </mat-label>
      <input matInput required
        type="text"
        name="timezone"
        [(ngModel)]="airport.tz"
      >
    </mat-form-field>
    <!-- type -->
    <mat-form-field class="col-md-4">
      <mat-label>
        {{'AIRPORT/table/type'|translate}}
      </mat-label>
      <mat-select
        name="type"
        [(ngModel)]="airport.is_airport"
      >
        <mat-option [value]="true">
          {{ 'AIRPORT/type/air' | translate }}
        </mat-option>
        <mat-option [value]="false">
          {{ 'AIRPORT/type/sea' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

</div>

<div mat-dialog-actions>
  <button mat-button
    cdkFocusInitial
    (click)="back()"
  >
    {{ 'AIRPORT/dialog/btn/back' | translate }}
  </button>
  <span></span>
  <button mat-raised-button
    class="primary-button"
    [disabled]="!form.valid"
    (click)="save()"
  >
    {{ 'AIRPORT/dialog/btn/save' | translate }}
  </button>
</div>
