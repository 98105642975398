import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { OVService } from '../../providers/ov.service';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DR } from '../../models/dr';
import { NfeService } from '../../providers/nfe.service';
import { HubService } from '../../providers/hub.service';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { ShipmentService } from '../../providers/shipment.service';
import { Shipment } from '../../models/shipment';
var NewShipmentRequestComponent = /** @class */ (function () {
    // @ViewChild('search2')
    // public searchElement2Ref: ElementRef;
    function NewShipmentRequestComponent(dialog, mapsAPILoader, ovService, router, drService, nfService, excelService, shipmentService, snackBar, hubService) {
        var _this = this;
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.ovService = ovService;
        this.router = router;
        this.drService = drService;
        this.nfService = nfService;
        this.excelService = excelService;
        this.shipmentService = shipmentService;
        this.snackBar = snackBar;
        this.hubService = hubService;
        this.dr = new DR();
        this.uploaded = false;
        this.displayedColumns = ['city', 'shipment', 'client', 'shippingTypeDescription', 'incoterms1', 'forwardingAgentName', 'plannedShipmentEnd'];
        this.dataSource = new MatTableDataSource();
        this.ovOrNf = false;
        this.shipment = new Shipment();
        this.minDate = new Date();
        this.weight = 0;
        this.volume = null;
        this.displayedColumns2 = ['salesDocument', 'referenceNoNf', 'customerName', 'materialDescription'];
        this.dataSourceOV = new MatTableDataSource();
        this.displayedColumns3 = ['shipment', 'obs', 'receiverName', 'shipToCity', 'client', 'shipToStreet', 'confirmedDeliveryDate', 'weight', 'volume'];
        this.dataSourceDR = new MatTableDataSource();
        this.initialSelection = [];
        this.selection = new SelectionModel(true, this.initialSelection);
        this.newDrList = [];
        this.dataSource.sort = this.sort;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = currentUser.user.role;
        if (this.role === 1) {
            this.minDate = new Date('1970-01-01');
        }
        this.drService.getLastIdInserted().subscribe(function (data) {
            _this.newId = data;
            console.log(_this.newId);
        });
        this.hubService.getAll().subscribe(function (data) {
            _this.hubList = data;
        });
    }
    NewShipmentRequestComponent.prototype.ngOnInit = function () {
        this.searchControl2 = new FormControl();
        /* this.mapsAPILoader.load().then(() => {
         *   const autocomplete = new google.maps.places.Autocomplete(this.searchElement2Ref.nativeElement, {
         *     types: ['address']
         *   });
         * }); */
    };
    NewShipmentRequestComponent.prototype.convertAddressToLatLng = function () {
        var _this = this;
        var addr = document.getElementById('deliveryAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                _this.dr.lat = lat;
                _this.dr.lng = lng;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                //// console.log(status);
            }
        });
    };
    NewShipmentRequestComponent.prototype.convertDRAddressToLatLng = function (dr) {
        var _this = this;
        var error = false;
        var geocoder = new google.maps.Geocoder();
        var addr = dr.deliveryAddress;
        return geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                dr.lat = results[0].geometry.location.lat();
                dr.lng = results[0].geometry.location.lng();
                return true;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                return false;
            }
        });
    };
    NewShipmentRequestComponent.prototype.convertAddressToLatLngXml = function (addr) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                // console.log('true');
                return true;
            }
            else {
                // console.log('false');
                return false;
            }
        });
    };
    NewShipmentRequestComponent.prototype.convertLatLngToAddress = function () {
        var _this = this;
        if (this.dr.lat === null || this.dr.lng === null) {
            return;
        }
        var lat = document.getElementById('latitudeAddress').value;
        var lng = document.getElementById('longitudeAddress').value;
        var geocoder = new google.maps.Geocoder();
        var location = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': location }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var addr = results[0].formatted_address;
                _this.dr.deliveryAddress = addr;
            }
            else {
                _this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', { duration: 2000 });
                //// console.log(status);
            }
        });
    };
    NewShipmentRequestComponent.prototype.addNewOV = function () {
        var _this = this;
        this.selection.selected.forEach(function (el) {
            if (_this.dataSourceOV.data.indexOf(el) < 0) {
                _this.dataSourceOV.data.push(el);
            }
        });
        this.dataSourceOV = new MatTableDataSource(this.dataSourceOV.data);
    };
    NewShipmentRequestComponent.prototype.openDialog = function () {
        var _this = this;
        // generate a new ID based on last MDR
        var dialogRef = this.dialog.open(DialogOverviewComponent, {
            width: '600px',
            data: {
                id: this.dataSource.data,
                value: 0,
            }
        });
        dialogRef.afterClosed().subscribe(function (data) {
            if (data === 1) {
                _this.shipment.observation = _this.dr.obs;
                _this.shipment.receiverPhone = _this.dr.receiverPhone.valueOf() + '';
                _this.shipment.receiverName = _this.dr.receiverName;
                _this.shipment.shipmentNumber = Number(_this.dataSource.data[0].shipment);
                _this.shipmentService.insert(_this.shipment).subscribe(function (data2) {
                    _this.router.navigate(['shipment-optimizer']);
                });
            }
        });
    };
    NewShipmentRequestComponent.prototype.insertShippment = function () {
        var _this = this;
        this.shipment.observation = this.dr.obs;
        this.shipment.receiverPhone = this.dr.receiverPhone.valueOf() + '';
        this.shipment.receiverName = this.dr.receiverName;
        this.shipment.shipmentNumber = Number(this.dataSource.data[0].shipment);
        this.shipmentService.insert(this.shipment).subscribe(function (data2) {
            _this.router.navigate(['shipment-optimizer']);
        });
    };
    NewShipmentRequestComponent.prototype.openFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var error = false;
        // console.log(fileupload);
        this.newDrList = [];
        var ovList = [];
        var re = /(?:\.([^.]+))?$/;
        for (var index = 0; index < input.files.length; index++) {
            var ext = re.exec(input.files[index].name)[1];
            if (ext === 'xlsx') {
                this.excelService.importFromExcel(fileupload).subscribe(function (response) {
                    response.forEach(function (el, i, arr) {
                        var drAux = new Shipment();
                        try {
                            // drAux.pickupAddress = el.pickup_address;
                            // drAux.deliveryAddress = el.delivery_address;
                            // drAux.site = el.site;
                            drAux.receiverName = el.receiver_name;
                            drAux.receiverPhone = el.receiver_phone;
                            // drAux.supervisorName = el.supervisor_name;
                            // drAux.supervisorPhone = el.supervisor_phone;
                            // drAux.infraType = el.infra;
                            drAux.observation = el.obs;
                            // drAux.status = el.shipment;
                            // console.log(el.ov);
                            // console.log(drAux.status);
                            // if ( this.convertDRAddressToLatLng(drAux) === false ) {
                            //   this.snackBar.open('Endereço inválido', 'Ok', {duration: 2000});
                            //   error = true;
                            // }
                            // console.log(drAux);
                            drAux.shipmentNumber = el.shipment;
                            _this.shipmentService.getAll(drAux.shipmentNumber).subscribe(function (data) {
                                if (data === null) {
                                    _this.snackBar.open('Not Found Shippment in Database', 'Ok', { duration: 4000 });
                                }
                                else {
                                    console.log(data);
                                    drAux.shipToCity = data.s.shipToCity;
                                    drAux.nameOfTheEndUserForFTrade = data.s.nameOfTheEndUserForFTrade;
                                    drAux.shipToStreet = data.s.shipToStreet;
                                    drAux.confirmedDeliveryDate = data.s.confirmedDeliveryDate;
                                    drAux.weight = data.w;
                                    drAux.volume = Number(data.v).toFixed(2);
                                    _this.newDrList.push(drAux);
                                    _this.uploaded = true;
                                    _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                                }
                            });
                        }
                        catch (ex) {
                            // console.log('erro ao ler arquivo xlsx ',ex);
                            _this.snackBar.open('Erro ao ler arquivo', 'Ok', { duration: 4000 });
                        }
                        // this.uploaded = true;
                        // this.dataSourceDR = new MatTableDataSource<Shipment>(this.newDrList);
                    });
                });
            }
        }
        // console.log(this.newDrList);
        // console.log(this.dataSourceDR);
    };
    NewShipmentRequestComponent.prototype.insertAllShippment = function () {
        var _this = this;
        this.dataSourceDR.data.forEach(function (el) {
            _this.shipmentService.insert(el).subscribe(function (data2) {
                _this.uploaded = false;
            });
        });
    };
    NewShipmentRequestComponent.prototype.searchShipment = function () {
        var _this = this;
        // console.log(this.inputOVs);
        if (this.inputOVs === undefined) {
            this.snackBar.open('Preencha um valor de OV', 'Ok', { duration: 4000 });
        }
        else {
            this.shipmentService.getAll(this.inputOVs).subscribe(function (data) {
                console.log(data);
                if (data === null) {
                    _this.snackBar.open('Not Found Shippment in Database', 'Ok', { duration: 4000 });
                }
                else {
                    _this.weight = data.w;
                    _this.volume = Number(data.v).toFixed(2);
                    _this.dataSource = new MatTableDataSource([data.s]);
                }
            });
        }
    };
    return NewShipmentRequestComponent;
}());
export { NewShipmentRequestComponent };
var DialogOverviewComponent = /** @class */ (function () {
    function DialogOverviewComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    DialogOverviewComponent.prototype.onYesClick = function () {
        this.data.value = 1;
        this.dialogRef.close();
    };
    DialogOverviewComponent.prototype.onNoClick = function () {
        this.data.value = 0;
        this.dialogRef.close();
    };
    return DialogOverviewComponent;
}());
export { DialogOverviewComponent };
