import { OnInit, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { MDR } from '../../models/mdr';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
var DeliveryOptimizerComponent = /** @class */ (function () {
    function DeliveryOptimizerComponent(dialog, mapsAPILoader, drService, mdrService, router, snackBar, dpipe) {
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.drService = drService;
        this.mdrService = mdrService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.data = [];
        this.columns = [
            { value: 'idDRidentifier', name: 'DR_OPTIMIZER/id' },
            { value: 'deliveryAddress', name: 'DR_OPTIMIZER/address' },
            { value: 'dateScheduling', name: 'DR_OPTIMIZER/date' },
            { value: 'site', name: 'DR_OPTIMIZER/site' },
            { value: 'createdBy', name: 'DR_OPTIMIZER/created' },
            { value: 'client', name: 'DR_OPTIMIZER/table/client' },
            { value: 'ovList', name: 'DR_OPTIMIZER/table/ovList' },
            { value: 'uf', name: 'DR_OPTIMIZER/table/uf' }
        ];
        this.selected = [];
    }
    DeliveryOptimizerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.drService.getAllOpen().subscribe(function (data2) {
            data2.forEach(function (row) {
                if (row.ovs) {
                    row.ovList = row.ovs.map(function (e) { return e.salesDocument; }).join(', ');
                }
                else {
                    row.ovList = '';
                }
            });
            _this.data = data2;
        });
    };
    DeliveryOptimizerComponent.prototype.unmakeDR = function () {
        var _this = this;
        this.drService.unmakeDR(this.selected).subscribe(function (data) {
            _this.drService.getAllOpen().subscribe(function (data2) {
                _this.data = data2;
                _this.snackBar.open('Ordens de entrega desfeitas com sucesso', 'Ok', {
                    duration: 4000,
                });
            });
        });
    };
    DeliveryOptimizerComponent.prototype.createConsolidationMDR = function () {
        var _this = this;
        var mdr = new MDR();
        mdr.status = 'MDR em validação';
        this.selected.forEach(function (el) {
            mdr.drList.push(el);
        });
        this.mdrService.insert(mdr, false).subscribe(function (data) {
            //// console.log('MDR new Service');
            console.log(data);
            _this.mdrService.createLegs(data.id, false).subscribe(function (legs) {
                console.log(legs);
            });
            _this.drService.getAllOpen().subscribe(function (data2) {
                _this.data = data2;
                _this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.router.navigate(['delivery-order']);
            });
        });
    };
    DeliveryOptimizerComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(TypeMdrDialogComponent, {
            width: '600px',
            height: '500px',
            data: {
                id: 1,
            }
        });
        dialogRef.afterClosed().subscribe(function (data) {
            if (data !== undefined) {
                _this.createNewMDR(data);
            }
        });
    };
    DeliveryOptimizerComponent.prototype.createNewMDR = function (typeMDR) {
        var _this = this;
        // console.log(typeMDR);
        // cria MDR
        var mdr = new MDR();
        mdr.type = typeMDR.type;
        mdr.status = 'MDR em validação';
        // adiciona DRs selecionadas
        this.selected.forEach(function (el) {
            mdr.drList.push(el);
        });
        var flag = false;
        if (mdr.type === 'Cross Doc') {
            this.createConsolidationMDR();
            return;
        }
        else if (mdr.type === 'CMS' && typeMDR.data) {
            mdr.SAD = typeMDR.data;
            if (typeMDR.data === true) {
                flag = true;
            }
        }
        else if (mdr.type === 'DTR' && typeMDR.data) {
            mdr['dtr'] = typeMDR.data;
        }
        // }else if(mdr.type === 'DTR' && typeMDR.data){
        //   const drList = [];
        //   for(let i = typeMDR.data.length-1; i>=0; i--){
        //     const dr = new DR();
        //     dr.deliveryAddress = typeMDR.data[i].address;
        //     dr.lat = typeMDR.data[i].lat;
        //     dr.lng = typeMDR.data[i].lng;
        //     dr.uf = typeMDR.data[i].uf;
        //     earlydate.setDate(earlydate.getDate() - i - 1);
        //     dr.dateScheduling = earlydate;
        //     drList.push(dr);
        //   }
        //   //console.log(drList);
        // }
        this.mdrService.insert(mdr, flag).subscribe(function (data) {
            _this.drService.getAllOpen().subscribe(function (data2) {
                _this.data = data2;
                _this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.router.navigate(['delivery-optimizer']);
            });
        });
    };
    DeliveryOptimizerComponent.prototype.onSelection = function (selected) {
        this.selected = selected;
    };
    return DeliveryOptimizerComponent;
}());
export { DeliveryOptimizerComponent };
var TypeMdrDialogComponent = /** @class */ (function () {
    function TypeMdrDialogComponent(dialogRef, mapsAPILoader, data) {
        this.dialogRef = dialogRef;
        this.mapsAPILoader = mapsAPILoader;
        this.data = data;
        this.types = [{ id: 'EDB', name: 'EDB' }, { id: 'ED', name: 'ED' }, { id: 'CMS', name: 'CMS' }, { id: 'DTR', name: 'DTR' }, { id: 'Cross Doc', name: 'Cross Doc' }];
        this.returnLeg = false;
        this.listAddresses = [];
        this.addressReturn = [];
        this.address = null;
    }
    TypeMdrDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.typeMDR = null;
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement, {
                types: ['address']
            });
        });
    };
    TypeMdrDialogComponent.prototype.addAddress = function () {
        var _this = this;
        var address = (document.getElementById('deliveryAddress').value);
        this.listAddresses.push({ address: address });
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function (results, status) {
            // console.log(results);
            var index = _this.listAddresses.length - 1;
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                var uf = results[0].address_components[2].short_name;
                _this.addressReturn.push({ address: address, lat: lat, lng: lng, uf: uf });
                // console.log(this.addressReturn);
            }
            else {
                _this.listAddresses.splice(index, 1);
                // this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
                // console.log(status);
            }
        });
    };
    TypeMdrDialogComponent.prototype.removeAddress = function (i) {
        this.listAddresses.splice(i, 1);
    };
    TypeMdrDialogComponent.prototype.onYesClick = function () {
        if (this.typeMDR === 'CMS') {
            this.dialogRef.close({ type: this.typeMDR, data: this.returnLeg });
        }
        else if (this.typeMDR === 'DTR') {
            this.dialogRef.close({ type: this.typeMDR, data: this.addressReturn });
        }
        else {
            this.dialogRef.close({ type: this.typeMDR, data: false });
        }
    };
    TypeMdrDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return TypeMdrDialogComponent;
}());
export { TypeMdrDialogComponent };
