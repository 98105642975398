import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { AirportService } from '../../providers/airport.service';
import { Airport } from '../../models/airport';

@Component({
  selector: 'app-airports',
  templateUrl: './airports.component.html',
  styleUrls: ['./airports.component.scss']
})
export class AirportsComponent implements OnInit {
  columns = [
    { value: 'icao', name: 'AIRPORT/table/icao' },
    { value: 'iata', name: 'AIRPORT/table/iata' },
    { value: 'name', name: 'AIRPORT/table/name' },
    { value: 'city', name: 'AIRPORT/table/city' },
    { value: 'state', name: 'AIRPORT/table/state' },
    { value: 'coutry', name: 'AIRPORT/table/coutry' },
    { value: 'elevation', name: 'AIRPORT/table/elevation' },
    { value: 'lat', name: 'AIRPORT/table/lat' },
    { value: 'lng', name: 'AIRPORT/table/lng' },
    { value: 'tz', name: 'AIRPORT/table/tz' },
    { value: 'icon', name: 'AIRPORT/table/type', icon: 'icon', tooltip: 'tooltip' },
  ];
  data = <any[]>[];

  icons = {
    air: 'airplanemode_active',
    sea: 'directions_boat',
  };
  tooltips = {
    air: 'Aeroporto',
    sea: 'Porto marítmo',
  };

  constructor(
    public dialog: MatDialog,
    public airportService: AirportService,
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.airportService.getAll().subscribe((response) => {
      this.data = response.map((row) => {
        // convert is_airport into type
        row['type'] = row['is_airport'] ? 'air' : 'sea';
        // set the icon
        row['icon'] = this.icons[row['type']];
        // set the tooltip
        row['tooltip'] = this.tooltips[row['type']];
        return row;
      });
    });
  }

  onRowClick(row, that) {
    // open dialog to edit airport
    const dialogRef = that.dialog.open(EditAirportDialogComponent, {
      data: {
        title: 'AIRPORT/dialog/edit',
        airport: row,
      }
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.refresh === true) {
        // refresh the table
        that.getData();
      }
    });
  }

  addAirport() {
    // open dialog to create airport
    const dialogRef = this.dialog.open(CreateAirportDialogComponent, {
      data: {
        title: 'AIRPORT/dialog/create',
      }
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.refresh === true) {
        // refresh the table
        this.getData();
      }
    });
  }

}

@Component({
  selector: 'app-dialog-edit-airport-dialog',
  templateUrl: 'dialog-edit-airport-dialog.html',
  styleUrls: ['./airports.component.scss']
})
export class CreateAirportDialogComponent {
  airport: Airport;
  dialogTitle: string;

  constructor(
    public dialogRef: MatDialogRef<CreateAirportDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public airportService: AirportService,
  ) {
    this.airport = new Airport();
    this.dialogTitle = data.title;
  }

  back() {
    this.dialogRef.close({
      refresh: false,
    });
  }

  save() {
    this.airportService.create(this.airport).subscribe((response) => {
      this.dialogRef.close({
        refresh: true,
      });
    }, (err) => {
      console.error(err);
      this.snackBar.open('Erro ao tentar criar aeroporto', 'Ok', { duration: 4000});
    });
  }

}

@Component({
  selector: 'app-dialog-edit-airport-dialog',
  templateUrl: 'dialog-edit-airport-dialog.html',
  styleUrls: ['./airports.component.scss']
})
export class EditAirportDialogComponent {
  airport: Airport;
  dialogTitle: string;

  constructor(
    public dialogRef: MatDialogRef<EditAirportDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public airportService: AirportService,
  ) {
    this.airport = data.airport;
    this.dialogTitle = data.title;
  }

  back() {
    this.dialogRef.close({
      refresh: false,
    });
  }

  save() {
    this.airportService.update(this.airport).subscribe((response) => {
      this.dialogRef.close({
        refresh: true,
      });
    }, (err) => {
      console.error(err);
      this.snackBar.open('Erro ao tentar atualizar aeroporto', 'Ok', { duration: 4000});
    });
  }

}
