<h1 mat-dialog-title>Alterar Status Trajeto Aéreo</h1>
<button class="button green-button text-white" (click)="addAirport()"> Adicionar</button>
<mat-table #table2 [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="airport">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Aeroporto </mat-header-cell>
      <mat-cell *matCellDef="let element"> 
        <span *ngIf="!editableAirport(element); else editIATA">{{element.airportIATA}}</span>
        <ng-template #editIATA>
          <input matInput placeholder="IATA" [(ngModel)]="element.airportIATA">
        </ng-template>
        <!-- <mat-select *ngIf="element.edit" placeholder="Airport" [(ngModel)]="element.airport.id"  >
          <mat-option *ngFor="let elem of airports" [value]="elem.id"  >
            {{ elem.iata }}
          </mat-option>
        </mat-select> -->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="eta">
      <mat-header-cell *matHeaderCellDef mat-sort-header> ETA </mat-header-cell>
      <mat-cell *matCellDef="let element">
          <span *ngIf="!element.edit"> {{element.eta  | date: "dd/MM/yy"}} </span>
          <input *ngIf="element.edit" matInput placeholder="ETA" [matDatepicker]="pickerETA"
                [(ngModel)]="element.eta" (click)="pickerETA.open()">
         <mat-datepicker #pickerETA></mat-datepicker>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="etd">
      <mat-header-cell *matHeaderCellDef mat-sort-header> ETD </mat-header-cell>
      <mat-cell *matCellDef="let element"> 
          <span *ngIf="!element.edit">{{element.etd  | date: "dd/MM/yy"}}</span>
          <input *ngIf="element.edit" matInput placeholder="ETD" [matDatepicker]="pickerETD"
                [(ngModel)]="element.etd" (click)="pickerETD.open()">
         <mat-datepicker #pickerETD></mat-datepicker>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ata">
      <mat-header-cell *matHeaderCellDef mat-sort-header> ATA </mat-header-cell>
      <mat-cell *matCellDef="let element">
          <span *ngIf="!element.edit">{{element.ata  | date: "dd/MM/yy"}}</span>
          <input *ngIf="element.edit" matInput placeholder="ATA" [matDatepicker]="pickerRTD"
                [(ngModel)]="element.ata" (click)="pickerRTD.open()">
         <mat-datepicker #pickerRTD></mat-datepicker>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!element.edit">{{element.status}}</span>
        <mat-select *ngIf="element.edit" placeholder="Status" [(ngModel)]="element.status"  >
            <mat-option *ngFor="let elem of status" [value]="elem.id"  >
              {{ elem.status }}
            </mat-option>
          </mat-select>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="awb">
      <mat-header-cell *matHeaderCellDef mat-sort-header> MAWB </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <input matInput #tel mask="000-00000000" type="text" placeholder="mawb" [(ngModel)]="element.mawb">
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!element.edit" (click)="showInput(element)">{{ 'DELIVERY_ROUTE/edit' | translate }}</span>
        <span *ngIf="editableAirport(element)" (click)="removeAirport(element)">Remover</span>
      </mat-cell>
      
    </ng-container>

    <ng-container matColumnDef="order">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Ordem </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="!element.edit">{{element.order}}</span>
        <input *ngIf="element.edit" matInput type="number" placeholder="Order"
                [(ngModel)]="element.order">
      </mat-cell>
    </ng-container>

<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
   <button mat-button [mat-dialog-close]="data" (click)="onYesClick()" cdkFocusInitial>Salvar Alterações</button>
   <button mat-button (click)="onNoClick()">Descartar Alterações</button>
</div>
