export class Shipment {
    public id: number;
    public shipmentNumber: number;
    public receiverName: string;
    public receiverPhone: string;
    public observation: string;
    public truckId: string;
    public shipToCity: string;
    public nameOfTheEndUserForFTrade: string;
    public shipToStreet: string;
    public confirmedDeliveryDate: string;
    public weight: string;
    public volume: string;
    public createdAt: Date;
    public status: number;

      constructor() {
       this.id = null;
       this.shipmentNumber = null;
       this.receiverName = null;
       this.receiverPhone = null;
       this.observation = null;
       this.truckId = null;
       this.shipToCity = null;
       this.nameOfTheEndUserForFTrade = null;
       this.shipToStreet = null;
       this.confirmedDeliveryDate = null;
       this.weight = null;
       this.volume = null;
       this.createdAt = null;
       this.status = null;
      }
  }
