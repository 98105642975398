import { OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';
var DrsChartComponent = /** @class */ (function () {
    function DrsChartComponent(translate) {
        this.translate = translate;
        this.noData = false;
        this.translations = {
            title: '',
            seriesName: []
        };
    }
    DrsChartComponent.prototype.ngOnInit = function () { };
    DrsChartComponent.prototype.createChart = function (data) {
        // console.log(data);
        if (data.length > 0) {
            this.noData = false;
            var series = data.map(function (status) {
                return { name: status.status, type: undefined, data: [status.count] };
            });
            this.chart = new Chart({
                chart: {
                    type: 'column'
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        format: 'Status'
                    }
                },
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.series.name + ':</b> ' + this.point.y;
                    }
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                series: series
            });
        }
        else {
            this.noData = true;
        }
    };
    return DrsChartComponent;
}());
export { DrsChartComponent };
