import { Airport } from './airport';
import { MDR } from './mdr';

export class Airway {
    id: number;
    eta: Date;
    etd: Date;
    airport: any;
    airportId: number;
    status: number;
    ata: Date;
    mawb: String;
    mdrID: any;
    remove: boolean;
    order: number;
    createdBy: string;
    createdAt: Date;

    constructor() {
      this.id = null;
      this.eta = null;
      this.etd = null;
      this.airport = new Airport();
      this.airportId = null;
      this.status = null;
      this.ata = null;
      this.mawb = null;
      this.mdrID = null;
      this.remove = false;
      this.order = null;
      this.createdBy = null;
      this.createdAt = null;
    }
  }

