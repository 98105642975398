import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { PAYMENT_STATUS } from '../../models/payment';
var FinanceListComponent = /** @class */ (function () {
    function FinanceListComponent(dialog, mdrService, excelService, drService, router, snackBar, dpipe) {
        this.dialog = dialog;
        this.mdrService = mdrService;
        this.excelService = excelService;
        this.drService = drService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.payments = [];
        this.data = [];
        this.listPay = [];
        this.columns = [
            { value: 'status', name: 'FINANCE_LIST/table/status' },
            { value: 'client', name: 'FINANCE_LIST/table/client' },
            { value: 'shippingCompany', name: 'FINANCE_LIST/table/lsp' },
            { value: 'mdrPickupId', name: 'FINANCE_LIST/table/mdr' },
            { value: 'prolog', name: 'FINANCE_LIST/table/prolog' },
            { value: 'costProject', name: 'FINANCE_LIST/table/costProject' },
            { value: 'OV', name: 'FINANCE_LIST/table/ov' },
            { value: 'type', name: 'FINANCE_LIST/table/type' },
            { value: 'origem', name: 'FINANCE_LIST/table/origem' },
            { value: 'currency', name: 'FINANCE_LIST/table/value', currency: 'BRL' },
            { value: 'createdAt', name: 'FINANCE_LIST/table/date' }
        ];
        this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
    }
    FinanceListComponent.prototype.ngOnInit = function () { };
    FinanceListComponent.prototype.cleanData = function (data) {
        var _this = this;
        this.payments = [];
        //// console.log(data);
        data.nf.forEach(function (el, i, arr) {
            _this.payments.push({
                paymentId: {
                    type: 'nf',
                    idPay: el.referenceNoNf
                },
                clientGroup: el.customerName,
                lsp: ('mdr' in el) ? el.mdr.shippingCompany : null,
                mdr: ('mdr' in el) ? el.mdr.id : 0,
                ov: el.salesDocument,
                nf: el.referenceNoNf,
                prolog: el.assignmentInformation,
                ccNet: ('pro' in el) ? el.pro.net + ' ' + el.pro.frete : null,
                sap: ('mdr' in el) ? el.mdr.sapCode : null,
                extraCost: 'Frete',
                total: parseFloat(el.price.total),
                status: ('payment' in el) ? el.payment.status : 'pending',
                romaneio: ('mdr' in el && el.mdr.pickupID !== null) ? el.mdr.pickupID : ('EDB-TP-' + ('00000000').slice(-6)),
                statusPT: ('payment' in el) ? (el.payment.status === 'paid' ? 'Pago' : 'Pendente') : 'Pendente'
            });
        });
        data.nfe.forEach(function (el, i, arr) {
            _this.payments.push({
                paymentId: {
                    type: 'nf',
                    idPay: el.invoiceNumber
                },
                clientGroup: ('client' in el) ? el.client.clientGroup : null,
                lsp: ('mdr' in el) ? el.mdr.shippingCompany : null,
                mdr: ('mdr' in el) ? el.mdr.id : 0,
                ov: null,
                nf: el.invoiceNumber,
                extraCost: 0,
                total: el.totalValue / 100,
                status: ('payment' in el) ? el.payment.status : 'pending',
                romaneio: ('mdr' in el && el.mdr.pickupID !== null) ? el.mdr.pickupID : ('EDB-TP-' + ('00000000').slice(-6)),
                statusPT: ('payment' in el) ? (el.payment.status === 'paid' ? 'Pago' : 'Pendente') : 'Pendente'
            });
            data.forEach(function (k) {
                k.currency = k.value / 100;
            });
            _this.data = data;
        });
    };
    FinanceListComponent.prototype.insertPaymentFromFile = function (fileupload) {
        var _this = this;
        // console.log(fileupload);
        // console.log(fileupload);
        var input = fileupload.target;
        // console.log(fileupload);
        // this.paymentList = [];
        for (var index = 0; index < input.files.length; index++) {
            this.excelService.importFromExcel(fileupload).subscribe(function (data) {
                data.forEach(function (el, i, arr) {
                    var payment = {};
                    // console.log(el);
                    try {
                        payment[''] = el.pickup_address;
                        // console.log(drAux.status);
                    }
                    catch (ex) {
                        // console.log('erro ao ler arquivo xlsx ',ex);
                        _this.snackBar.open('Erro ao ler arquivo', 'Ok', { duration: 4000 });
                    }
                });
            });
        }
    };
    FinanceListComponent.prototype.updateFromFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        this.excelService.importFromExcel(fileupload).subscribe(function (data) {
            // console.log(data);
            var avisos = [];
            var checkRow = function (row) {
                // status é válido
                if ('status' in row
                    && !Object.values(PAYMENT_STATUS).includes(row['status'])) {
                    avisos.push("Linha " + row.__rowNum__ + " cont\u00E9m status inv\u00E1lido");
                    return false;
                }
                // campos necessários estão presentes
                if (!('origemId' in row)
                    || !('invoice' in row)
                    || !('OV' in row)
                    || !('origem' in row)) {
                    avisos.push("Linha " + row.__rowNum__ + " n\u00E3o cont\u00E9m campos obrigat\u00F3rios [origemId,invoice,OV,origem]");
                    return false;
                }
                // linha já existe, portanto pode ser atualizada
                if (!_this.data.find(function (el) {
                    return (
                    // tslint:disable-next-line:triple-equals
                    el['origemId'] == row['origemId']
                        // tslint:disable-next-line:triple-equals
                        && el['invoice'] == row['invoice']
                        // tslint:disable-next-line:triple-equals
                        && el['OV'] == row['OV']
                        // tslint:disable-next-line:triple-equals
                        && el['origem'] == row['origem']);
                })) {
                    avisos.push("Linha " + row.__rowNum__ + " n\u00E3o existe no sistema");
                    return false;
                }
                return true;
            };
            // se todas as linhas recebidas são válidas
            if (data.every(checkRow)) {
                // atualiza as linhas
                var total = data.length;
                // console.log(total);
                _this.mdrService.updatePayment(data).subscribe(function (response) {
                    _this.snackBar.open('Pagamentos atualizados com sucesso', 'OK', { duration: 4000 });
                }, function (error) {
                    if ('status' in error) {
                        _this.snackBar.open('Erro ao subir arquivo, verifique que todas as linhas já existem no sistema.', 'OK', { duration: 4000 });
                    }
                    else {
                        _this.snackBar.open('Estamos ainda atualizando, por favor, aguarde mais 5 minutos.', 'OK', { duration: 4000 });
                    }
                });
            }
            else {
                _this.snackBar.open('Arquivo contém linhas inválidas', 'OK', { duration: 4000 });
                var _loop_1 = function (i, len) {
                    setTimeout(function () {
                        _this.snackBar.open(avisos[i], 'OK', { duration: 2000 });
                    }, 2000 * (i + 1));
                };
                // mostra avisos gerados
                for (var i = 0, len = avisos.length; i < len; i++) {
                    _loop_1(i, len);
                }
            }
        });
    };
    FinanceListComponent.prototype.goToMDR = function (row, that) {
        if (row.mdrId !== 0) {
            that.router.navigate(['logistic-order/' + row.mdrId]);
        }
        else {
            that.snackBar.open('Não há MDR associada a este item', 'Ok', { duration: 4000 });
        }
    };
    FinanceListComponent.prototype.addList = function (listPayments) {
        this.listPay = listPayments;
    };
    FinanceListComponent.prototype.payNF = function () {
        var _this = this;
        this.mdrService.payNF(this.listPay).subscribe(function (response) {
            _this.snackBar.open('Pagamento realizado corretamente', 'Ok', { duration: 4000 });
        });
    };
    FinanceListComponent.prototype.remove = function () {
        var _this = this;
        var removed = 0;
        var total = this.listPay.length;
        this.listPay.forEach(function (payment) {
            _this.mdrService.removePayment(payment.id).subscribe(function (response) {
                removed++;
                _this.snackBar.open('PO (' + removed + '/' + total + ') removido', 'OK', { duration: 4000 });
                var paymentI = _this.data.findIndex(function (e) { return e.id === payment.id; });
                if (paymentI >= 0) {
                    _this.data.splice(paymentI, 1);
                }
            }, function (error) {
                removed++;
                _this.snackBar.open('PO (' + removed + '/' + total + ') falhou remoção', 'OK', { duration: 4000 });
            });
        });
    };
    return FinanceListComponent;
}());
export { FinanceListComponent };
