/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shipment-optimizer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../misc/table/table.component.ngfactory";
import * as i3 from "../../misc/table/table.component";
import * as i4 from "../../services/xlsx.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/router";
import * as i7 from "ngx-cookie-service";
import * as i8 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "@ngx-translate/core";
import * as i14 from "@angular/common";
import * as i15 from "./shipment-optimizer.component";
import * as i16 from "@agm/core/services/maps-api-loader/maps-api-loader";
import * as i17 from "../../providers/dr.service";
import * as i18 from "../../providers/shipment.service";
import * as i19 from "../../providers/mdr.service";
import * as i20 from "@angular/material/snack-bar";
var styles_ShipmentOptimizerComponent = [i0.styles];
var RenderType_ShipmentOptimizerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShipmentOptimizerComponent, data: {} });
export { RenderType_ShipmentOptimizerComponent as RenderType_ShipmentOptimizerComponent };
function View_ShipmentOptimizerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "colored-background table-background px-5 py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-table", [["showCheckbox", "true"]], null, [[null, "check"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("check" === en)) {
        var pd_0 = (_co.onSelection($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(2, 638976, null, 0, i3.TableComponent, [i4.XlsxService, i5.MatDialog, i6.Router, i7.CookieService], { columns: [0, "columns"], data: [1, "data"], showCheckbox: [2, "showCheckbox"] }, { check: "check" }), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "d-flex justify-content-between m-3 pb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "primary-button"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createNewMDR() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(6, 0, ["", ""])), i1.ɵpid(131072, i13.TranslatePipe, [i13.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "warn-button"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unmakeShipment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(10, 0, ["", ""])), i1.ɵpid(131072, i13.TranslatePipe, [i13.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns; var currVal_1 = _co.data; var currVal_2 = "true"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 5).disabled || null); var currVal_4 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("DR_OPTIMIZER/create")); _ck(_v, 6, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 9).disabled || null); var currVal_7 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_6, currVal_7); var currVal_8 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("DR_OPTIMIZER/undo")); _ck(_v, 10, 0, currVal_8); }); }
function View_ShipmentOptimizerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "../../assets/images/happy_face.png"], ["style", "width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["style", "color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""])), i1.ɵpid(131072, i13.TranslatePipe, [i13.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("DR_OPTIMIZER/empty")); _ck(_v, 3, 0, currVal_0); }); }
export function View_ShipmentOptimizerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mx-5 my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "mat-h1"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i13.TranslatePipe, [i13.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShipmentOptimizerComponent_1)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShipmentOptimizerComponent_2)), i1.ɵdid(7, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.data.length > 0); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.data.length === 0); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("DR_OPTIMIZER/title")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ShipmentOptimizerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shipment-optimizer", [], null, null, null, View_ShipmentOptimizerComponent_0, RenderType_ShipmentOptimizerComponent)), i1.ɵdid(1, 114688, null, 0, i15.ShipmentOptimizerComponent, [i5.MatDialog, i16.MapsAPILoader, i17.DrService, i18.ShipmentService, i19.MdrService, i6.Router, i20.MatSnackBar, i14.DatePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShipmentOptimizerComponentNgFactory = i1.ɵccf("app-shipment-optimizer", i15.ShipmentOptimizerComponent, View_ShipmentOptimizerComponent_Host_0, {}, {}, []);
export { ShipmentOptimizerComponentNgFactory as ShipmentOptimizerComponentNgFactory };
