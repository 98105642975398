import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ExportAsService } from 'ngx-export-as';
import { of } from 'rxjs';
import { SiteService, ESTRUTURA, ACESSO } from '../../providers/site.service';
import { LSPEmailDialogComponent } from '../logistic-order/logistic-order.component';
import { AddressPipe } from '../../misc/pipes/parse-address/address.pipe';
import * as https from 'https';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
function convertLatLngToAddress(obj) {
    if (isNaN(obj.lat) || isNaN(obj.lng)) {
        return;
    }
    var lat = obj.lat;
    var lng = obj.lng;
    var options = {
        hostname: 'nominatim.openstreetmap.org',
        path: '/reverse?format=jsonv2&lat=' + lat + '&lon=' + lng,
        headers: { 'User-Agent': 'AwareLog/1.0' }
    };
    https.get(options, function (resp) {
        var data = '';
        resp.on('data', function (chunk) {
            data += chunk;
        });
        resp.on('end', function () {
            var location = JSON.parse(data);
            var address = location.display_name;
            obj.address = address;
        });
    }).on('error', function (err) {
        // console.log("Error: " + err.message);
    });
}
var SiteInvestigationComponent = /** @class */ (function () {
    function SiteInvestigationComponent(dialog, snackBar, router, addressPipe, siteService, excelService) {
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.router = router;
        this.addressPipe = addressPipe;
        this.siteService = siteService;
        this.excelService = excelService;
        this.url = ''; // url do link para geração do token, preenchido no constructor
        this.shippingcompanys = [];
        this.shippingcompanyusers = [];
        this.tipos = {};
        this.data = [];
        this.siteList = [];
        this.columns = [
            { name: 'SITE_INVESTIGATION/table/id', value: 'id' },
            { name: 'SITE_INVESTIGATION/table/code', value: 'code' },
            { name: 'SITE_INVESTIGATION/table/address', value: 'address' },
            { name: 'SITE_INVESTIGATION/table/shippingcompany', value: 'shippingcompany' },
            { name: 'SITE_INVESTIGATION/table/operadora', value: 'operadora' },
            { name: 'SITE_INVESTIGATION/table/regional', value: 'regional' },
            { name: 'SITE_INVESTIGATION/table/visitadatahora', value: 'visitadatahora' },
            { name: 'SITE_INVESTIGATION/table/vistoriadatahora', value: 'vistoriadatahora' },
            { name: 'SITE_INVESTIGATION/table/createdat', value: 'createdAt' },
            { name: 'SITE_INVESTIGATION/table/token', value: 'accesstokenshow' },
            { name: 'SITE_INVESTIGATION/table/aprovado', value: 'aprovado' },
        ];
        this.role = -1;
        this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
        this.url = location.href.replace(this.router.url, '');
    }
    SiteInvestigationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.siteService.read().subscribe(function (data) {
            // pega as keys dos objetos retornados
            var keys = data.length ? Object.keys(data[0]) : [];
            _this.data = data.map(function (el) {
                for (var i = 0, l = keys.length; i < l; i++) {
                    // remove os valores null
                    if (el[keys[i]] === null) {
                        el[keys[i]] = '';
                    }
                    // converte em Date
                    if ((keys[i] === 'visitadatahora'
                        || keys[i] === 'createdAt'
                        || keys[i] === 'vistoriadatahora')
                        && el[keys[i]]) {
                        el[keys[i]] = new Date(el[keys[i]]);
                    }
                }
                // gera o link de acesso ao token
                if ('accesstoken' in el && el.accesstoken) {
                    el.accesstokenshow = true;
                    el.accesstokenlink = _this.url + '/site-investigation/' + el.id + '/' + el.accesstoken;
                }
                else {
                    el.accesstokenshow = false;
                    el.accesstokenlink = false;
                }
                // extrai UF do endereço
                el.uf = _this.addressPipe.transform(el.address, '%E');
                // extrai LSP
                if ('shippingcompany' in el) {
                    el.shippingcompany = el.shippingcompany.name;
                }
                else {
                    el.shippingcompany = 'sem LSP';
                }
                // gera o link pro laudo
                el.reportlink = _this.url + '/site-report/' + el.id;
                return el;
            });
        });
        this.siteService.getShippingCompanys().subscribe(function (data) {
            _this.shippingcompanys = data;
            _this.siteService.getShippingCompanyUsers().subscribe(function (users) {
                // inclui nome da LSP no nome do usuario
                users.map(function (u) {
                    var sc = _this.shippingcompanys.find(function (el) {
                        return el.id === u.usc.idShippingCompany;
                    });
                    if (sc) {
                        u.name = u.name + ' [' + sc.name + ']';
                    }
                });
                _this.shippingcompanyusers = users;
            });
        });
        this.tipos = this.siteService.getEnums();
    };
    SiteInvestigationComponent.prototype.approveSiteInvestigation = function (site) {
        var _this = this;
        var dialogRef = this.dialog.open(ApproveDialogComponent, {
            data: {
                site: site
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            } // unfocus clicando fora do modal
            if (result.what === 'approve') {
                _this.siteService.approve(site.id).subscribe(function (data) {
                    site.aprovado = true;
                    _this.snackBar.open('Laudo aprovado com sucesso', 'Ok', { duration: 4000 });
                });
            }
        });
    };
    SiteInvestigationComponent.prototype.convertAddressToLatLng = function (obj) {
        var _this = this;
        var addr = obj.address;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                obj.lat = lat;
                obj.lng = lng;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                // console.log(status);
            }
        });
    };
    SiteInvestigationComponent.prototype.openFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var error = false;
        // para dar match com os varios tipos que sao criados quando eh feito o download
        var regex = new RegExp('tipo\.*\.tipo');
        // console.log(fileupload);
        this.siteList = [];
        var re = /(?:\.([^.]+))?$/;
        for (var index = 0; index < input.files.length; index++) {
            var ext = re.exec(input.files[index].name)[1];
            if (ext === 'xlsx') {
                this.excelService.importFromExcel(fileupload).subscribe(function (data) {
                    // itera nos sites
                    data.forEach(function (el, i, arr) {
                        try {
                            var arrayTipos_1 = [];
                            var arrayKeys = Object.keys(el);
                            // itera nas keys
                            arrayKeys.forEach(function (e) {
                                // coloca em arrayTipos os tipos que batem
                                if (regex.test(e)) {
                                    arrayTipos_1.push(e);
                                }
                            });
                            el.tipo = [];
                            // atualiza os tipos no site
                            arrayTipos_1.forEach(function (tipos) {
                                el.tipo.push({ tipo: el[tipos] });
                                delete el[tipos];
                            });
                            el.acesso = el.acesso.toLowerCase();
                            _this.siteList.push(el);
                        }
                        catch (ex) {
                            // console.log('erro ao ler arquivo xlsx ',ex);
                            _this.snackBar.open('Erro ao ler arquivo', 'Ok', { duration: 4000 });
                        }
                    });
                    // console.log(this.siteList);
                    _this.siteList.forEach(function (el) {
                        _this.siteService.create(el).subscribe(function () {
                            // console.log("Criado com sucesso");
                            _this.snackBar.open('Criado com sucesso, atualize a página', 'Ok', { duration: 4000 });
                            // console.log(data);
                        });
                    });
                });
            }
            else {
                this.snackBar.open('Arquivo precisa estar no formato xlsx', 'Ok', { duration: 4000 });
            }
        }
    };
    SiteInvestigationComponent.prototype.createToken = function (siteID) {
        var _this = this;
        var dialogRef = this.dialog.open(NewTokenDialogComponent, {
            data: {
                anexo: null,
                site: siteID,
                sites: this.data.map(function (el) {
                    return { id: el.id, name: el.code };
                }),
                shippingcompanys: this.shippingcompanys.map(function (el) {
                    return { id: el.id, name: el.name };
                })
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            } // unfocus clicando fora do modal
            if (result.what === 'token') {
                var opts_1 = {
                    'id': parseInt(result.data.site, 10),
                    'lspid': parseInt(result.data.shippingcompany, 10),
                    'visitadatahora': result.data.visitadatahora,
                    'file': result.data.anexo
                };
                _this.openEmailDialog(parseInt(result.data.site, 10), parseInt(result.data.shippingcompany, 10)).subscribe(function (response) {
                    _this.siteService.createToken(opts_1).subscribe(function (data) {
                        var link = _this.url + '/site-investigation/' + data.siteid + '/' + data.accesstoken;
                        _this.dialog.open(TokenDialogComponent, { data: {
                                link: link
                            } });
                        var old = _this.data.find(function (el) { return el.id === result.data.site; });
                        old.accesstokenshow = true;
                        old.accesstokenlink = link;
                        old.visitadatahora = result.data.visitadatahora;
                        _this.snackBar.open('Token gerado com sucesso', 'Ok', { duration: 4000 });
                    });
                });
            }
        });
    };
    SiteInvestigationComponent.prototype.addItem = function () {
        var _this = this;
        var tipo = {};
        this.tipos.tipo.map(function (el) {
            tipo[el.value] = false;
        });
        var dialogRef = this.dialog.open(NewSiteDialogComponent, {
            data: {
                tipos: this.tipos,
                tipo: tipo
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            } // unfocus clicando fora do modal
            if (result.what === 'insert') {
                // unifica tipo em um array
                var tipoArr_1 = [];
                _this.tipos.tipo.map(function (el) {
                    if (result.data.tipo[el.value] === true) {
                        tipoArr_1.push({ tipo: el.value });
                    }
                });
                result.data.tipo = tipoArr_1;
                // envia pro back
                _this.siteService.create(result.data).subscribe(function (data) {
                    _this.snackBar.open('Item inserido com sucesso, recarregue a página para atualizar a tabela', 'Ok', { duration: 4000 });
                });
            }
        });
    };
    SiteInvestigationComponent.prototype.onRowClick = function (row, that) {
        if (that.role === 1) {
            that.openEditDialog(row);
        }
        else {
            that.openViewDialog(row);
        }
    };
    SiteInvestigationComponent.prototype.rowStyle = function (row, that) {
        if (row.visitadatahora < new Date()) {
            return 'red';
        }
        if (!row.aprovado) {
            return 'yellow';
        }
        if (row.acesso !== ACESSO.LIBERADO) {
            return 'yellow';
        }
        if (!row.aprovado && row.acesso !== ACESSO.LIBERADO) {
            return 'yellow';
        }
        if (row.aprovado && row.acesso === ACESSO.LIBERADO) {
            return 'green';
        }
    };
    SiteInvestigationComponent.prototype.openViewDialog = function (row) {
        var _this = this;
        // separa tipo em objeto
        var tipo = {};
        this.tipos.tipo.map(function (el) {
            tipo[el.value] = false;
        });
        row.tipo.map(function (el) {
            tipo[el.tipo] = true;
        });
        row.tipo = tipo;
        row.tipos = this.tipos;
        var dialogRef = this.dialog.open(ViewSiteDialogComponent, {
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            // unifica tipo em um array (a partir de row)
            var tipoArr = [];
            _this.tipos.tipo.map(function (el) {
                if (row.tipo[el.value] === true) {
                    tipoArr.push({ tipo: el.value });
                }
            });
            row.tipo = tipoArr;
            if (!result) {
                return;
            } // unfocus clicando fora do modal
            if (result.what === 'new-token') {
                _this.createToken(row.id);
            }
        });
    };
    SiteInvestigationComponent.prototype.openEditDialog = function (row) {
        var _this = this;
        // separa tipo em objeto
        var tipo = {};
        this.tipos.tipo.map(function (el) {
            tipo[el.value] = false;
        });
        row.tipo.map(function (el) {
            tipo[el.tipo] = true;
        });
        row.tipo = tipo;
        row.tipos = this.tipos;
        var dialogRef = this.dialog.open(EditSiteDialogComponent, {
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            // unifica tipo em um array (a partir de row)
            var tipoArr = [];
            _this.tipos.tipo.map(function (el) {
                if (row.tipo[el.value] === true) {
                    tipoArr.push({ tipo: el.value });
                }
            });
            row.tipo = tipoArr;
            if (!result) {
                return;
            } // unfocus clicando fora do modal
            result.data.tipo = row.tipo;
            if (result.what === 'delete') {
                _this.siteService.delete(result.data.id).subscribe(function (data) {
                    _this.snackBar.open('Item removido com sucesso, recarregue a página para atualizar a tabela', 'Ok', { duration: 4000 });
                });
            }
            else if (result.what === 'update') {
                // separa os anexos
                var toSave_1 = Object.assign({}, result.data);
                var files_1 = [];
                ['form_1_1_anexo', 'form_1_2_anexo', 'form_1_3_anexo', 'form_1_4_anexo', 'form_1_5_anexo', 'form_1_6_anexo', 'form_1_7_anexo', 'form_1_8_anexo', 'form_1_9_anexo', 'form_2_1_anexo', 'form_2_2_anexo', 'form_2_3_anexo', 'form_2_4_anexo', 'form_2_5_anexo', 'form_2_6_anexo', 'form_2_7_anexo', 'form_2_8_anexo', 'form_2_9_anexo', 'form_3_1_anexo', 'form_3_2_anexo', 'form_3_3_anexo', 'form_4_1_anexo', 'form_4_2_anexo', 'form_4_3_anexo', 'form_5_1_anexo', 'form_5_2_anexo', 'form_5_3_anexo', 'form_6_1_anexo', 'form_6_2_anexo', 'form_6_3_anexo', 'form_6_4_anexo', 'form_6_5_anexo', 'form_7_1_anexo', 'form_7_2_anexo', 'form_7_3_anexo'].forEach(function (el) {
                    if (!!toSave_1[el] && !!toSave_1[el].name) {
                        var filename = el + '.' + toSave_1[el].name.split('.').pop();
                        files_1.push({ file: toSave_1[el], field: filename });
                    }
                    delete toSave_1[el];
                });
                // envia pro back
                _this.snackBar.open('Enviando... isto pode demorar um pouco', 'Ok', { duration: 2000 });
                _this.siteService.updateFiles(toSave_1.id, files_1).subscribe(function (dataUpdateFiles) {
                    _this.siteService.update(result.data).subscribe(function (dataUpdate) {
                        _this.snackBar.open('Item atualizado com sucesso', 'Ok', { duration: 4000 });
                        var old = _this.data.find(function (el) { return el.id === result.data.id; });
                        old = result.data;
                    });
                });
            }
            else if (result.what === 'new-token') {
                _this.createToken(row.id);
            }
            else if (result.what === 'approve') {
                _this.approveSiteInvestigation(row);
            }
        });
    };
    SiteInvestigationComponent.prototype.openEmailDialog = function (siteid, lspid) {
        var _this = this;
        if (this.role !== 1) {
            return of(false);
        }
        // mostra dialog
        var dialogRef = this.dialog.open(LSPEmailDialogComponent, {
            // mantem somente usuarios da LSP selecionada
            data: this.shippingcompanyusers.filter(function (u) {
                return u.usc.idShippingCompany === lspid;
            }),
        });
        return dialogRef.afterClosed().map(function (result) {
            if (result[0]) {
                var selectedUsers = result[1].value;
                if (selectedUsers !== null) {
                    return _this.siteService.sendLSPEmail(siteid, selectedUsers).subscribe(function (resultSend) {
                        // move on
                    });
                }
            }
        });
    };
    return SiteInvestigationComponent;
}());
export { SiteInvestigationComponent };
var EditSiteDialogComponent = /** @class */ (function () {
    function EditSiteDialogComponent(dialogRef, snackBar, data, exportAsService) {
        this.dialogRef = dialogRef;
        this.snackBar = snackBar;
        this.data = data;
        this.exportAsService = exportAsService;
        this.SITE_ESTRUTURA = ESTRUTURA;
        this.tipos = [];
        this.exportAsConfig = {
            type: 'pdf',
            elementId: 'printable',
        };
        this.tipos = data.tipos;
        delete data.tipos;
    }
    EditSiteDialogComponent.prototype.onClickPrint = function () {
        // download the file using old school javascript method
        this.exportAsService.save(this.exportAsConfig, 'report');
    };
    EditSiteDialogComponent.prototype.convertAddressToLatLng = function (obj) {
        var _this = this;
        var addr = obj.address;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                obj.lat = lat;
                obj.lng = lng;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                // console.log(status);
            }
        });
    };
    EditSiteDialogComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '', data: this.data });
    };
    EditSiteDialogComponent.prototype.onClickRemove = function () {
        this.dialogRef.close({ what: 'delete', data: this.data });
    };
    EditSiteDialogComponent.prototype.onClickSave = function () {
        this.dialogRef.close({ what: 'update', data: this.data });
    };
    EditSiteDialogComponent.prototype.onClickNewToken = function () {
        this.dialogRef.close({ what: 'new-token', data: this.data });
    };
    EditSiteDialogComponent.prototype.onClickApprove = function () {
        this.dialogRef.close({ what: 'approve', data: this.data });
    };
    EditSiteDialogComponent.prototype.address2latlng = function () {
        this.convertAddressToLatLng(this.data);
    };
    EditSiteDialogComponent.prototype.latlng2address = function () {
        convertLatLngToAddress(this.data);
    };
    return EditSiteDialogComponent;
}());
export { EditSiteDialogComponent };
var NewSiteDialogComponent = /** @class */ (function () {
    function NewSiteDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.SITE_ESTRUTURA = ESTRUTURA;
        this.tipos = [];
        this.tipos = data.tipos;
        delete data.tipos;
    }
    NewSiteDialogComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    NewSiteDialogComponent.prototype.onClickSave = function () {
        this.dialogRef.close({ what: 'insert', data: this.data });
    };
    NewSiteDialogComponent.prototype.address2latlng = function () {
        // convertAddressToLatLng(this.data);
    };
    NewSiteDialogComponent.prototype.latlng2address = function () {
        convertLatLngToAddress(this.data);
    };
    return NewSiteDialogComponent;
}());
export { NewSiteDialogComponent };
var NewTokenDialogComponent = /** @class */ (function () {
    function NewTokenDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.site = null;
        this.sites = [];
        this.shippingcompanys = [];
        this.sites = data.sites;
        this.shippingcompanys = data.shippingcompanys;
    }
    NewTokenDialogComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    NewTokenDialogComponent.prototype.onClickToken = function () {
        this.dialogRef.close({ what: 'token', data: this.data });
    };
    return NewTokenDialogComponent;
}());
export { NewTokenDialogComponent };
var TokenDialogComponent = /** @class */ (function () {
    function TokenDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    TokenDialogComponent.prototype.onClickOk = function () {
        this.dialogRef.close();
    };
    return TokenDialogComponent;
}());
export { TokenDialogComponent };
var ApproveDialogComponent = /** @class */ (function () {
    function ApproveDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ApproveDialogComponent.prototype.onClickOk = function () {
        this.dialogRef.close({ what: 'approve' });
    };
    return ApproveDialogComponent;
}());
export { ApproveDialogComponent };
var ViewSiteDialogComponent = /** @class */ (function () {
    function ViewSiteDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.tipos = [];
        this.tipos = data.tipos;
        delete data.tipos;
    }
    ViewSiteDialogComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '', data: this.data });
    };
    ViewSiteDialogComponent.prototype.onClickNewToken = function () {
        this.dialogRef.close({ what: 'new-token', data: this.data });
    };
    return ViewSiteDialogComponent;
}());
export { ViewSiteDialogComponent };
