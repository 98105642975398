var Airport = /** @class */ (function () {
    function Airport() {
        this.id = null;
        this.icao = null;
        this.iata = null;
        this.name = null;
        this.city = null;
        this.state = null;
        this.coutry = null;
        this.elevation = null;
        this.lat = null;
        this.lng = null;
        this.tz = null;
        this.is_airport = null;
    }
    return Airport;
}());
export { Airport };
