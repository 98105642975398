/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./upload.component";
import * as i5 from "../../providers/upload.service";
var styles_UploadComponent = [i0.styles];
var RenderType_UploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadComponent, data: {} });
export { RenderType_UploadComponent as RenderType_UploadComponent };
function View_UploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "progress"], ["style", "width: 40%; float: left; margin: 12px 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "progress-bar progress-bar-info progress-bar-striped"], ["role", "progressbar"]], [[1, "aria-valuenow", 0]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { width: 0 }), (_l()(), i1.ɵted(4, null, [" ", "%"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, (_co.progress.percentage + "%")); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.progress.percentage, ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.progress.percentage; _ck(_v, 4, 0, currVal_2); }); }
function View_UploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 6).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " arquivos selecionados"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentFileUpload.length; _ck(_v, 1, 0, currVal_0); }); }
function View_UploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 6).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-upload"]], null, null, null, null, null))], null, null); }
export function View_UploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["style", "width: 100%; float: right;padding: 0px 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "label", [["class", "btn btn-default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [["fileInput", 1]], null, 5, "input", [["multiple", ""], ["style", "display:none"], ["type", "file"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.fileInputValue = $event) !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (_co.selectFile($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(9, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(11, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadComponent_3)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentFileUpload.length > 0); _ck(_v, 3, 0, currVal_0); var currVal_8 = _co.fileInputValue; _ck(_v, 9, 0, currVal_8); var currVal_9 = (_co.currentFileUpload.length > 0); _ck(_v, 13, 0, currVal_9); var currVal_10 = (_co.currentFileUpload.length === 0); _ck(_v, 15, 0, currVal_10); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 11).ngClassValid; var currVal_6 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_UploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-files", [], null, null, null, View_UploadComponent_0, RenderType_UploadComponent)), i1.ɵdid(1, 114688, null, 0, i4.UploadComponent, [i5.UploadFileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadComponentNgFactory = i1.ɵccf("app-upload-files", i4.UploadComponent, View_UploadComponent_Host_0, { entity: "entity", id: "id" }, { messageEvent: "messageEvent" }, []);
export { UploadComponentNgFactory as UploadComponentNgFactory };
