import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { OV } from '../models/ov';
import {environment} from '../../environments/environment';
import {Zhstatus} from '../models/zhstatus';
import {Zrsdpac} from '../models/zrsdpac';
import { ZZCustom } from '../models/zzcustom';
import { Shipment } from '../models/shipment';
import { Airway } from '../models/airway';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class ShipmentService {
  public token: string;

  constructor(private http: HttpClient) {
      // set token if saved in local storage
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
  }

  getAllShipment(): Observable< any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      return this.http.get(base_url + 'shipment/getAll', {headers: header})
          .map((response: any) => {
            const ShipmentData = response;
            return ShipmentData;
          });
  }

  unmakeShipments(list: Shipment[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(base_url + 'shipment/unmake', {
      shipmentList: list,
    }, {headers: header});
  }

  insertMultiples(list: any[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'driver/saveMultiplePositions', {list}, {headers: header})
          .map((response: any) => {
            if (response !== undefined) {
              if (response.text === 'Success') {
                return true;
              }
            }
            return false;
          });
  }


  getAll(id: number): Observable<any> {
    if (id === null) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      return this.http.get(base_url + 'shipment/' + id, {headers: header})
          .map((response: any) => {
            const ShipmentData = response;
            return ShipmentData;
          });
    }

    getAirports(): Observable<any> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'shipment/airports/list', {headers: header})
            .map((response: any) => {
              const airports = response;
              return airports;
            });
      }


    getAirways(mdr): Observable<any> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'shipment/airway/' + mdr, {headers: header})
            .map((response: any) => {
              const airports = response;
              return airports;
            });
      }

    insertAirway(ship: Airway): Observable<any> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      return this.http.post(base_url + 'shipment/airway', {airway: ship}, {headers: header})
          .map((response: any) => {
            const ShipmentData = response;
            return ShipmentData;
          });
    }

    updateAirway(airport): Observable<any> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      return this.http.put(base_url + 'airports/' + airport.id, airport.airport, {headers: header})
          .map((response: any) => {
            const ShipmentData = response;
            return ShipmentData;
          });
    }

    removeAirway(ship: Airway): Observable<any> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      return this.http.delete(base_url + 'shipment/' + ship.id, {headers: header})
          .map((response: any) => {
            const ShipmentData = response;
            return ShipmentData;
          });
    }

    getAllByMDR(id: number): Observable<any> {
      if (id === null) {
        return null;
      }
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'shipment/byMDR/' + id, {headers: header})
            .map((response: any) => {
              const ShipmentData = response;
              return ShipmentData;
            });
      }

    getAllInfo(id: number): Observable<any> {
      if (id === null) {
        return null;
      }
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'shipment/info/' + id, {headers: header})
            .map((response: any) => {
              const ShipmentData = response;
              return ShipmentData;
            });
      }

    insert(ship: Shipment): Observable<any> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      return this.http.post(base_url + 'shipment/', {shippment: ship}, {headers: header})
          .map((response: any) => {
            const ShipmentData = response;
            return ShipmentData;
          });
    }
}
