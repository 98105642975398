import { OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DR } from '../../../models/dr';
import { MatSnackBar } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { DrService } from '../../../providers/dr.service';
import { MatDialog, MatDialogRef } from '@angular/material';
var DrValidationComponent = /** @class */ (function () {
    function DrValidationComponent(router, aRoute, snackBar, mapsAPILoader, drService, dialog) {
        this.router = router;
        this.aRoute = aRoute;
        this.snackBar = snackBar;
        this.mapsAPILoader = mapsAPILoader;
        this.drService = drService;
        this.dialog = dialog;
        this.dr = new DR();
        // pro icon de salesOrders
        this.status = null;
        this.icons = {
            'status': '',
            'tooltips': {
                'invalid': 'OVs inválidas',
                'incomplete': 'NFs não existentes',
                'valid': 'DR Booking válido',
                'undefined': ''
            },
            'icons': {
                'invalid': 'fa-exclamation-circle',
                'incomplete': 'fa-exclamation-triangle',
                'valid': 'fa-check',
                'undefined': ''
            },
            'classes': {
                'invalid': 'icon-red',
                'incomplete': 'icon-yellow',
                'valid': 'icon-green',
                'undefined': ''
            }
        };
    }
    DrValidationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.aRoute.params.subscribe(function (params) {
            _this.id = params.id;
            _this.validateDR();
        });
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement, {
                types: ['address']
            });
        });
    };
    DrValidationComponent.prototype.convertAddressToLatLng = function () {
        var _this = this;
        var addr = document.getElementById('deliveryAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                _this.dr.lat = lat;
                _this.dr.lng = lng;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
            }
        });
    };
    DrValidationComponent.prototype.convertLatLngToAddress = function () {
        var _this = this;
        if (this.dr.lat === null || this.dr.lng === null) {
            return;
        }
        var lat = document.getElementById('latitudeAddress').value;
        var lng = document.getElementById('longitudeAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat: parseFloat(lat), lng: parseFloat(lng) } }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var addr = results[0].formatted_address;
                _this.dr.deliveryAddress = addr;
            }
            else {
                _this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', { duration: 2000 });
            }
        });
    };
    DrValidationComponent.prototype.validateDR = function () {
        var _this = this;
        this.drService.getValidation(this.id).subscribe(function (response) {
            _this.dr = response.dr;
            _this.salesOrders = _this.dr.salesOrders.join(',');
            _this.icons.status = response.status;
            _this.status = {
                status: response.status,
                error: response.error,
                warning: response.warning
            };
            if (response.status === 'valid') {
                _this.approveDR();
            }
            else {
                _this.openDialog();
            }
        }, function (err) {
            _this.snackBar.open('Erro ao buscar DR Booking', 'Ok', { duration: 4000 });
            _this.router.navigate(['dr-booking']);
        });
    };
    DrValidationComponent.prototype.saveAndValidate = function () {
        var _this = this;
        this.dr.salesOrders = this.salesOrders
            .replace(/ /g, ',') // troca espaços por vírgulas
            .split(',') // quebra em array nas vírgulas
            .filter(function (e) { return e.length; }); // remove strings vazias
        this.drService.updateBooking(this.id, this.dr).subscribe(function (response) {
            _this.validateDR();
        }, function (err) {
            _this.snackBar.open('Erro ao atualizar DR Booking', 'Ok', { duration: 2000 });
        });
    };
    DrValidationComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogDrValidationComponent, {
            data: {
                status: this.status
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            } // unfocus clicando fora do modal
            if (result.what === 'approve') {
                _this.approveDR();
            }
        });
    };
    DrValidationComponent.prototype.approveDR = function () {
        var _this = this;
        this.drService.updateBookingToDR(this.id).subscribe(function (response) {
            _this.router.navigate(['delivery-optimizer']);
        }, function (err) {
            _this.snackBar.open('Erro ao criar DR a partir de DR Booking', 'Ok', { duration: 2000 });
        });
    };
    return DrValidationComponent;
}());
export { DrValidationComponent };
var DialogDrValidationComponent = /** @class */ (function () {
    function DialogDrValidationComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    DialogDrValidationComponent.prototype.onClickOk = function () {
        this.dialogRef.close({ what: 'approve' });
    };
    DialogDrValidationComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    return DialogDrValidationComponent;
}());
export { DialogDrValidationComponent };
