import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var ClientUserService = /** @class */ (function () {
    function ClientUserService(http) {
        this.http = http;
        this.uid = 'ClientUserService-';
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    ClientUserService.prototype.getAll = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        // let params = new HttpParams().set("token",token);
        return this.http.get(base_url + 'client/getAll', { headers: header })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    ClientUserService.prototype.getClients = function () {
        var _this = this;
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        if ((this.uid + 'getClients') in localStorage) {
            return new Observable(function (observer) {
                observer.next(JSON.parse(localStorage.getItem(_this.uid + 'getClients')));
            });
        }
        else {
            return this.http.get(base_url + 'client/GetClients', { headers: header })
                .map(function (response) {
                localStorage.setItem(_this.uid + 'getClients', JSON.stringify(response));
                return response;
            });
        }
    };
    ClientUserService.prototype.deleteUser = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var params = new HttpParams().set('id', id + '');
        return this.http.delete(base_url + 'client/user/' + id, { headers: header, params: params })
            .map(function (response) {
            return response;
        });
    };
    ClientUserService.prototype.remove = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var params = new HttpParams().set('id', id + '');
        return this.http.delete(base_url + 'client/' + id, { headers: header, params: params })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    ClientUserService.prototype.getTpUsers = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'client/GetTps', { headers: header })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    ClientUserService.prototype.create = function (clientId, userId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'client/', { clientId: clientId, userId: userId }, { headers: header })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    ClientUserService.prototype.createUser = function (userName, userEmail, companyId) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'client/CreateUser', { userName: userName, userEmail: userEmail, companyId: companyId }, { headers: header })
            .map(function (response) {
            var res = response;
            // console.log(res);
            return res;
        });
    };
    ClientUserService.prototype.getUserFromLSP = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'client/getAllUsers', { headers: header })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    ClientUserService.prototype.getLspList = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'client/GetLsp', { headers: header })
            .map(function (response) {
            // console.log(response);
            var OVdata = response;
            return OVdata;
        });
    };
    return ClientUserService;
}());
export { ClientUserService };
