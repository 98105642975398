export class ZZTPXITEM {
  public id: number;
  public delivery: string;
  public handlingUnit: string;
  public material: string;
  public plant: string;
  public sLoc: string;
  public length: string;
  public width: string;
  public height: string;
  public huTotalWeight: string;
  public tareWeight: string;
  public tareWeightUnit: string;
  public tareVolume: string;
  public huVolumeUnit: string;
  public huLoadingWeight: string;
  public packedQty: string;
  public insertedBy: number;
  public updatedBy: number;
  public insertDate: Date;
  public updateDate: Date;
  public active: boolean;

    constructor() {
      this.id = null;
      this.delivery = null;
      this.handlingUnit = null;
      this.material = null;
      this.plant = null;
      this.sLoc = null;
      this.length = null;
      this.width = null;
      this.height = null;
      this.huTotalWeight = null;
      this.tareWeight = null;
      this.tareWeightUnit = null;
      this.tareVolume = null;
      this.huVolumeUnit = null;
      this.huLoadingWeight = null;
      this.packedQty = null;
      this.insertedBy = null;
      this.updatedBy = null;
      this.insertDate = null;
      this.updateDate = null;
      this.active = null;
    }
  }

