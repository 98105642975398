<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <!-- this creates a google map on the page with the given lat/lng from -->
      <!-- the component as the initial center of the map: -->
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'GEOLOCATION/title' | translate }}
        </div>
      </div>
      <hr>
      <span *ngIf="finishedDriveStatus.indexOf(mdr.status) === -1">
         <div class="col-md-4"> 
           <p>{{ 'GEOLOCATION/distanceRemaining' | translate }} {{ distanceRemaining }}</p>
         </div>
         <div class="col-md-4"> 
           <p>{{ 'GEOLOCATION/timeRemaining' | translate }} {{ timeRemaining }}</p>
         </div>
      </span>
      <div>
        <p>ETA: <i *ngIf="role === 1 && !editETA" font class="fa fa-pencil-alt icon-header" (click)="editETAN()" aria-hidden="true"></i>
          <i *ngIf="role === 1 && editETA" font class="fa fa-save icon-header" (click)="saveETAN()" aria-hidden="true"></i></p>
        <input *ngIf="editETA" matInput placeholder="ETA" id="eta" [(ngModel)]="mdr.sapCode">
        <p *ngIf="!editETA"> {{ mdr.sapCode }}</p>
      </div>
      <div>
        <p>ATA: <i *ngIf="role === 1 && !editATA" font class="fa fa-pencil-alt icon-header" (click)="editATAN()" aria-hidden="true"></i>
          <i *ngIf="role === 1 && editATA" font class="fa fa-save icon-header" (click)="saveATAN()" aria-hidden="true"></i></p>
        <input *ngIf="editATA" matInput placeholder="ATA" id="ata" [(ngModel)]="mdr.SAD">
        <p *ngIf="!editATA"> {{ mdr.SAD }}</p>
      </div>

      <div *ngIf="showMap">
        <agm-map #gmap [latitude]="lat" [longitude]="lng" [mapTypeControl]="true"  [usePanning]="true"  >
          <span *ngIf="showMarkers">

            <agm-marker *ngFor="let mark of markers"
              [markerDraggable]="mdr.status === 'MDR em validação'"
              (dragEnd)="updateMarker($event, mark)"
              [label]="{color: '#FFFFFF', fontSize: '16px', text: mark.label}"
              [latitude]="mark.lat"
              [longitude]="mark.lng"
            ></agm-marker>

            <!-- pontos do tracking -->
            <agm-marker *ngFor="let mark of markersDriver"
              [markerDraggable]="false"
              [iconUrl]="mark.icon"
              [latitude]="mark.lat"
              [longitude]="mark.lng"
            ></agm-marker>

            <!-- linha cinza -->
            <span *ngFor="let leg of legsNewLegs">
              <agm-direction *ngIf="leg.type === 'Rodoviário'"
                (onResponse)="onResponse($event)"
                (onChange)="onResponse($event)"
                [visible] = "show"
                [renderOptions]="renderOptions"
                [origin]="leg.pointInit"
                [destination]="leg.pointEnd"
              ></agm-direction>

              <agm-polyline *ngIf="leg.type !== 'Rodoviário'"
                [geodesic]="true"
                [visible] = "show">
                <agm-polyline-point
                  [latitude]="leg.pointInit.lat"
                  [longitude]="leg.pointInit.lng"
                ></agm-polyline-point>
                <agm-polyline-point
                  [latitude]="leg.pointEnd.lat"
                  [longitude]="leg.pointEnd.lng"
                ></agm-polyline-point>
              </agm-polyline>
            </span>

            <span *ngIf="finishedDriveStatus.indexOf(mdr.status) === -1">
              <!-- linha azul -->
              <agm-direction *ngIf="haveDriver && legsNewLegs.length > 0"
                (onResponse)="onResponseDriver($event)"
                (onChange)="onResponseDriver($event)"
                [visible] = "show"
                [renderOptions]="renderOptionsDriver"
                [origin]="driverPosition"
                [destination]="legsNewLegs[0].pointEnd"
              ></agm-direction>

              <!-- marker do caminhão -->
              <agm-marker *ngIf="haveDriver"
                [markerDraggable]="false"
                [latitude]="driverPosition.lat"
                [iconUrl]="icon"
                [longitude]="driverPosition.lng"
              ></agm-marker>
            </span>

          </span>
          <span *ngIf="aereo">
              <!-- <agm-marker *ngFor="let mark of markers" [markerDraggable]="false" (dragEnd)="updateMarker($event, mark)" [label]="{color: '#FFFFFF', fontSize: '16px', text: mark.label}" [latitude]="mark.lat" [longitude]="mark.lng"></agm-marker> -->
              <!-- <agm-marker *ngFor="let mark of markersDriver" [markerDraggable]="false"  [iconUrl]="iconPoint" [latitude]="mark.lat" [longitude]="mark.lng"></agm-marker> -->

              <span *ngFor="let leg of markersDriver; let i = index">
                <!-- <agm-direction (onResponse)="onResponse($event)" (onChange)="onResponse($event)" *ngIf="leg.type === 'Rodoviário'" [visible] = "show" [renderOptions]="renderOptions" [origin]="leg.pointInit" [destination]="leg.pointEnd" ></agm-direction> -->
                <agm-polyline *ngIf="i>3 && (i < markersDriver.length - 1)" [geodesic]="true" [visible] = "show">
                  <agm-polyline-point [latitude]="leg.lat" [longitude]="leg.lng"></agm-polyline-point>
                  <agm-polyline-point [latitude]="markersDriver[i+1].lat"  [longitude]="markersDriver[i+1].lng"></agm-polyline-point>
                </agm-polyline>
              </span>
              <!-- <agm-direction (onResponse)="onResponseDriver($event)" (onChange)="onResponseDriver($event)" *ngIf="haveDriver && legsNewLegs.length > 0" [visible] = "show" [renderOptions]="renderOptionsDriver" [origin]="driverPosition" [destination]="legsNewLegs[0].pointEnd" ></agm-direction> -->
              <!-- <agm-marker *ngIf="haveDriver" [markerDraggable]="false" [latitude]="driverPosition.lat" [iconUrl]="icon" [longitude]="driverPosition.lng"></agm-marker> -->
            </span>

        </agm-map>

        <!-- tabela com posições dos motoristas -->
        <app-table
          *ngIf="markersDriver.length > 0"
          [data]="driverPositionList"
          [this]="this"
          [columns]="driverPositionTableColumns"
          [clickCallback]="removePoint"
        ></app-table>
      </div>

      <!--<div>-->
      <!--<app-delivery-route></app-delivery-route>-->
      <!--</div>-->
      <span *ngIf="role !== 9" class="title col-md-3 bold-text">Import Positions</span>
      <div *ngIf="role !== 9" class="title col-md-6 ">
        <input id="input-file-id"  #fileInput accept='xlsx' (change)='openFile($event)' class="none" multiple type="file"/>
        <label for="input-file-id" class="button-file">{{ 'NEW-DELIVERY-REQUEST/text/select-file' | translate }}</label>
      </div>
      <div *ngIf="mdr.status === 'MDR em validação'" class="row">
        <div class="row">
          <div class="col-md-12">
            <button mat-raised-button class="left red-button"  (click)="removeDirection()">{{ 'GEOLOCATION/remove_route' | translate }} </button>
            <button mat-raised-button class="left green-button"  (click)="showDirection()">{{ 'GEOLOCATION/show_route' | translate }}</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table mat-table *ngIf="legsNewLegs.length > 0" #table [dataSource]="dataSource" matSort>
              <!-- Add pickup Address -->

              <ng-container matColumnDef="pointStart">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GEOLOCATION/point_init' | translate }}  </th>
                <td mat-cell *matCellDef="let element"> {{ element.pointInit.label}} </td>
              </ng-container>

              <ng-container matColumnDef="pointEnd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'GEOLOCATION/point_end' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.pointEnd.label}} </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'GEOLOCATION/type' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.type }} </td>
              </ng-container>

              <ng-container matColumnDef="remove">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'GEOLOCATION/split' | translate }} </th>
                <td mat-cell *matCellDef="let element" > <span  (click)="removeLeg(element)"><i [ngClass]="{'fas-grey':mdr.lock === false}" class="fas fa-times"></i></span></td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
          </div>
          <div class="row all-row">
            <div class="col-md-12">
              <span class="title">{{ 'GEOLOCATION/create_point' | translate }}</span>
            </div>
          </div>
          <div class="row all-row">
            <div class="col-md-12">
              <mat-form-field class="example-full-width">
                <input matInput type="number" placeholder="{{ 'GEOLOCATION/lat' | translate }}" [(ngModel)]="latNew">
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <input type="number"  matInput placeholder="{{ 'GEOLOCATION/lng' | translate }}" [(ngModel)]="lngNew">
              </mat-form-field>
              <button mat-raised-button class="green-button right"  (click)="addPin()">{{ 'GEOLOCATION/add_point' | translate }}</button>
            </div>
          </div>
          <div class="row all-row">
            <div class="col-md-12">
              <span class="title">{{ 'GEOLOCATION/create_leg' | translate }}</span>
            </div>
          </div>
          <div class="row all-row">
            <div class="col-md-7">
              <mat-form-field class="example-full-width">
                <mat-select placeholder="{{ 'GEOLOCATION/point_init' | translate }}" [(ngModel)]="markerInit">
                  <mat-option *ngFor="let elem of markers" [value]="elem.label">
                    {{ elem.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-select placeholder="{{ 'GEOLOCATION/point_end' | translate }}" [(ngModel)]="markerEnd">
                  <mat-option *ngFor="let elem of markers" [value]="elem.label">
                    {{ elem.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="example-full-width">
                <mat-select placeholder="{{ 'GEOLOCATION/type' | translate }}" [(ngModel)]="selectedType">
                  <mat-option *ngFor="let elem of typeTransp" [value]="elem.name">
                    {{ elem.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <!--<button mat-raised-button class="yellow-button right"  (click)="saveRoute()">Salvar Alterações</button>-->
              <button mat-raised-button class="green-button right"  (click)="createLeg()">{{ 'GEOLOCATION/add_leg' | translate }}</button>
            </div>
          </div>
          <div class="row all-row">
            <div class="col-md-12">
              <span class="title">{{ 'GEOLOCATION/remove_point' | translate }}</span>
            </div>
          </div>
          <div class="row all-row">
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-select placeholder="{{ 'GEOLOCATION/selected_point' | translate }}" [(ngModel)]="markerDelete">
                  <mat-option *ngFor="let elem of markers" [value]="elem.label">
                    {{ elem.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <button mat-raised-button class="red-button right"  (click)="deletePin()">{{ 'GEOLOCATION/delete_point' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
