<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row" *ngIf="showAirport">
    <div class="white-background container-row center-container container-border col-md-10">
      <div class="row container-title">
        <div class="col-md-12">
          Rota aérea
          <ng-container *ngIf="role === 1">
            <i font class="fa fa-edit" style="color: gray" aria-hidden="true" (click)="openDialog()"></i>
          </ng-container>
          <!-- {{ 'DELIVERY_ROUTE/title' | translate }} -->
        </div>
      </div>
      <hr>
      <div class="example-container mat-elevation-z8" style="margin-bottom: 30px;">
        <div class="row">
            <!-- <div class="air-local col-md-1">
                <p class="small-text">ETA</p>
                <p class="small-text">ETD</p>
                <p>Local</p>
                <p><i font class="fa fa-dot-circle" style="color: white" aria-hidden="true"></i></p>
                <p class="small-text">RTD</p>
              </div> -->
          <div class="air-local col">
              <p class="small-text" matTooltip="ETA"> {{(airportList[0].eta? (airportList[0].eta| date: "dd/MM/yy") : "-")}} </p>
              <p class="small-text" matTooltip="ETD"> {{ (airportList[0].etd? (airportList[0].etd| date: "dd/MM/yy") : "-")}} </p>
              <p matTooltip="Aeroporto" (click)="openInfoAirport(aiportList[0])">{{airportList[0].airport.icao}}</p>
              <p><i font class="fa fa-dot-circle" [style.color]="airportList[0].status === '1'? 'green': (airportList[0].status === '2'? 'red': 'gray')" aria-hidden="true"></i></p>
              <p class="small-text" matTooltip="ATA">{{(airportList[0].ata? (airportList[0].ata| date: "dd/MM/yy") : "-")}}</p>
          </div>
          <div class="air-local col">
              <p class="small-text" matTooltip="ETA"> {{(airportList[1].eta? (airportList[1].eta| date: "dd/MM/yy") : "-")}} </p>
              <p class="small-text" matTooltip="ETD"> {{ (airportList[1].etd? (airportList[1].etd| date: "dd/MM/yy") : "-")}} </p>
              <p matTooltip="Aeroporto" (click)="openInfoAirport(aiportList[1])">{{airportList[1].airport.icao}}</p>
              <p><i font class="fa fa-dot-circle" [style.color]="airportList[1].status === '1'? 'green': (airportList[1].status === '2'? 'red': 'gray')" aria-hidden="true"></i></p>
              <p class="small-text" matTooltip="ATA">{{(airportList[1].ata? (airportList[1].ata| date: "dd/MM/yy") : "-")}}</p>
            </div>
          <div class="air-local col-md-1">
            <i font [ngClass]="mdr.airport ? 'fa fa-plane-departure' : 'fa fa-ship'" style="bottom: 0px;position: absolute;color: gray" aria-hidden="true"></i>
          </div>
          <div *ngFor="let point of airportList; let i = index" >
            <div *ngIf="i>3" class="air-local col">
              <p class="small-text" matTooltip="ETA"> {{(point.eta? (point.eta| date: "dd/MM/yy") : "-")}} </p>
              <p class="small-text" matTooltip="ETD"> {{ (point.etd? (point.etd| date: "dd/MM/yy") : "-")}} </p>
              <p matTooltip="Aeroporto" (click)="openInfoAirport(point)">{{point.airport.iata}}</p>
              <p><i font class="fa fa-dot-circle" [style.color]=" point.status === '2' || point.status === '3' || point.status === '6' ? 'green': (point.status === '4' || point.status === '5'? 'red': 'gray')" aria-hidden="true"></i></p>
              <p class="small-text" matTooltip="ATA">{{(point.ata? (point.ata| date: "dd/MM/yy") : "-")}}</p>
              <p class="small-text" *ngIf="point.mawb !== undefined && point.mawb !== null" matTooltip="MAWB"><a href="{{ 'https://connect.track-trace.com/for/awarebb/aircargo/' + point.mawb.substring(0,3) + '-' + point.mawb.substring(3,11)}}" target="_blank">{{point.mawb}}</a></p>
            </div>
          </div>
          <div class="air-local col-md-1">
            <i font [ngClass]="mdr.airport ? 'fa fa-plane-arrival' : 'fa fa-ship'" style="bottom: 0px;position: absolute;"  aria-hidden="true"></i>
          </div>
          <div class="air-local col">
              <p class="small-text" matTooltip="ETA"> {{(airportList[2].eta? (airportList[2].eta| date: "dd/MM/yy") : "-")}} </p>
              <p class="small-text" matTooltip="ETD"> {{ (airportList[2].etd? (airportList[2].etd| date: "dd/MM/yy") : "-")}} </p>
              <p matTooltip="Aeroporto" (click)="openInfoAirport(aiportList[2])">{{airportList[2].airport.icao}}</p>
              <p><i font class="fa fa-dot-circle" [style.color]="airportList[2].status === 1? 'green': (airportList[2].status === 2? 'red': 'gray')" aria-hidden="true"></i></p>
              <p class="small-text" matTooltip="ATA">{{(airportList[2].ata? (airportList[2].ata| date: "dd/MM/yy") : "-")}}</p>
          </div>
          <div class="air-local col">
              <p class="small-text" matTooltip="ETA"> {{(airportList[3].eta? (airportList[3].eta| date: "dd/MM/yy") : "-")}} </p>
              <p class="small-text" matTooltip="ETD"> {{ (airportList[3].etd? (airportList[3].etd| date: "dd/MM/yy") : "-")}} </p>
              <p matTooltip="Aeroporto" (click)="openInfoAirport(aiportList[3])">{{airportList[3].airport.icao}}</p>
              <p><i font class="fa fa-dot-circle" [style.color]="airportList[3].status === 1? 'green': (airportList[3].status === 2? 'red': 'gray')" aria-hidden="true"></i></p>
              <p class="small-text" matTooltip="ATA">{{(airportList[3].ata? (airportList[3].ata| date: "dd/MM/yy") : "-")}}</p>
            </div>
        </div>
      </div>
  </div>
</div>
