export class DriverPosition {
    public id: number;
    public mdrId: number;
    public phoneNumber: number;
    public lat: string;
    public lng: string;
    public createdAt: Date;

    constructor() {
        this.id = null;
        this.mdrId = null;
        this.phoneNumber = null;
        this.lat = null;
        this.lng = null;
        this.createdAt = null;
    }

    // @Column("datetime2", {nullable: true})
    // public estimatedDeliveryTime: Date;
}
