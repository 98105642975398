export class Airport {
  public id: number;
  public icao: string;
  public iata: string;
  public name: string;
  public city: string;
  public state: string;
  public coutry: string;
  public elevation: number;
  public lat: string;
  public lng: string;
  public tz: string;
  public is_airport: boolean;

    constructor() {
      this.id = null;
      this.icao = null;
      this.iata = null;
      this.name = null;
      this.city = null;
      this.state = null;
      this.coutry = null;
      this.elevation = null;
      this.lat = null;
      this.lng = null;
      this.tz = null;
      this.is_airport = null;
    }
  }
