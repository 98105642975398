import { Component, OnInit, ViewChild, Input, Inject, ElementRef, OnDestroy } from '@angular/core';
import { MatGridList, TooltipPosition } from '@angular/material';
import { MatTableDataSource, MatSort, MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import {AgmCoreModule, LatLng, MapsAPILoader} from '@agm/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MdrService } from '../../providers/mdr.service';
import { DR } from '../../models/dr';
import { UOC } from '../../models/uoc';
import { MDR, MDR_TYPE, MDR_STATUS } from '../../models/mdr';
import { Occurrence } from '../../models/occurrence';
import { MdrObservable } from '../../services/mdr.service';
import { PriceObservable } from '../../services/price.service';
import { DrObservable } from '../../services/dr.service';
import { UOCObservable } from '../../services/uoc.service';
import { User } from '../../models/user';

import { DrService } from '../../providers/dr.service';

declare var google: any;
import { } from 'googlemaps';

import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import { OverviewExampleDialogComponent } from '../new-delivery-request/new-delivery-request.component';
import { OV } from '../../models/ov';
import { UOCMDR } from '../../models/uoc__mdr';
import { COPQMDR } from '../../models/copq__mdr';
import { CostAvoidanceMDR } from '../../models/costAvoidance__mdr';
import { CostAvoidance } from '../../models/costAvoidance';
import { Copq } from '../../models/copq';
import { Driver } from '../../models/driver';
import { Observable } from 'rxjs/Observable';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import 'rxjs/add/operator/takeWhile';
import { TranslateService } from '@ngx-translate/core';
import { TransportComponent } from './transport/transport.component';
import { TaxComponent } from './tax/tax.component';
import { Message } from '../../models/message';
import { SocketService } from '../../providers/socket.service';
import { ChatService } from '../../providers/chat.service';
import { Event } from '../../models/events';
import { AttachmentsComponent } from './attachments/attachments.component';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { Title } from '@angular/platform-browser';

import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-logistic-order',
  templateUrl: './logistic-order.component.html',
  styleUrls: ['./logistic-order.component.scss']
})


export class LogisticOrderComponent implements OnInit, OnDestroy {
  public chatType = 5; // transportadora <-> motorista

  @ViewChild(TransportComponent) transport;
  @ViewChild(TaxComponent) tax;
  @ViewChild(AttachmentsComponent) attachments;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  selectedIndex = null;

  exportAsConfig: ExportAsConfig = {
    type: 'pdf', // the type you want to download
    elementId: 'printable', // the id of html/table element
    options: {
      pagespilt: true,
      html2canvas: {
        useCORS: true,
      },
      pagebreak: {
        mode: 'avoid-all',
      }
    }
  };

  public searchControl: FormControl;
  private alive: boolean; // used to unsubscribe from the IntervalObservable
  driver: Driver;
  drivers: Driver[] = [];
  opened: any;
  addNewOV: any;
  print = false;
  mdr: MDR = new MDR();
  editable = true;
  driverEditable = false;
  currentUser: any;
  role = 0;
  editMDRStatus = false;
  editMDRType = false;
  selectedVehicle: any;
  selectedShippingCompany: any;
  private idsocket: string;
  private display: boolean; // whether to display info in the component
  // use *ngIf="display" in your html to take
  // advantage of this
  reservation: any = '';
  reprove: any = '';
  shipmentReturned: any = '';
  positionOptions: TooltipPosition = 'after';
  tooltip = 'Esta MDR esta sendo editada por outra pessoa';

  price: Price = { price: 0, total: 0, adValorem: 0, addDelivery: 0, taxedPrice: 0, sc: '', vehi: '' };
  @ViewChild(MatSort) sort: MatSort;


  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild(TransportComponent)
  transportInfo: TransportComponent;


  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;

  hiddenMap = true;

  public channel: number;
  public ioConnection: any;
  private user: string;
  private lock = false;
  public valueNfStatus = false;
  mdrStatus = [
    { value: 'MDR em validação', name: 'MDR em validação' },
    { value: 'MDR em criação', name: 'MDR em criação' },
    { value: 'MDR devolvida por LSP', name: 'MDR devolvida por LSP' },
    { value: 'MDR aguardando LSP', name: 'MDR aguardando LSP' },
    { value: 'MDR aprovada LSP', name: 'MDR aprovada LSP' },
    { value: 'Motorista e veiculo definidos', name: 'Motorista e veiculo definidos' },
    { value: 'Veículo carregado', name: 'Veículo carregado' },
    { value: 'Carga entregue com sucesso', name: 'Carga entregue com sucesso' },
    { value: 'Carga retornada total', name: 'Carga retornada total' },
    { value: 'Entrega de carga certificada pelo LSP', name: 'Entrega de carga certificada pelo LSP' },
    { value: 'MDR paga / concluída', name: 'MDR paga / concluída' },
  ];
  mdrType = [
    { value: MDR_TYPE.EDB, name: 'EDB' },
    { value: MDR_TYPE.ED, name: 'ED' },
    { value: MDR_TYPE.CMS, name: 'CMS' },
    { value: MDR_TYPE.DRT, name: 'DRT' },
    { value: MDR_TYPE.CrossDoc, name: 'Cross Doc' },
  ];



  constructor(public dialog: MatDialog, private titleService: Title,
    public mdrService: MdrService, public router: Router, public aRoute: ActivatedRoute,
    public snackBar: MatSnackBar, public mdrObservable: MdrObservable,
    public priceObservable: PriceObservable, public drObservable: DrObservable,
    public drService: DrService, public uocObservable: UOCObservable,
    private socketService: SocketService, public exportAsService: ExportAsService,
    public chat: ChatService) {


    this.driver = new Driver();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.display = false;
    this.alive = true;
  }

  ngOnDestroy() {
    this.titleService.setTitle('Bluebee');
  }

  ngOnInit() {
    this.aRoute.params.subscribe((params) => {
      this.channel = params.id;

      // busca MDR
      this.mdrService.getMDR(params.id).subscribe((data) => {
        this.mdr = data;
//        console.log(this.mdr);
        // busca NFe
        this.mdrService.getNf(params.id).subscribe((nfs) => {
          const totalValue = nfs.filter(nf => parseFloat(nf.totalValue) >= 500000);
          if (totalValue.length > 0) {
            this.valueNfStatus = true;
          }
        });

        // setta o titulo da página
        if (this.mdr.pickupID !== null) {
          this.titleService.setTitle(this.mdr.pickupID);
        }
        switch (this.mdr.status) {
          case 'MDR em criação':
            this.selectedIndex = 0;
            break;
          case 'MDR aguardando LSP':
            this.selectedIndex = 1;
            break;
          case 'MDR aprovada LSP':
            this.selectedIndex = 2;
            break;
          case 'Motorista e veiculo definidos':
            this.selectedIndex = 3;
            break;
          case 'Veículo carregado':
            this.selectedIndex = 4;
            break;
          case 'Carga entregue com sucesso':
            this.selectedIndex = 5;
            break;
          case 'Entrega de carga certificada pelo LSP':
            this.selectedIndex = 6;
            break;
          default:
            this.selectedIndex = 0;
            break;
        }
        if (this.mdr.status === 'MDR aprovada com ressalvas LSP') {
          this.mdrService.getMDRReservation(params.id).subscribe((elem) => {
            // console.log(elem);
            this.reservation = elem;
          });
        }
        if (this.mdr.status === 'MDR em criação') {
          this.mdrService.getMDRReprove(params.id).subscribe((elem) => {
            this.reprove = elem;
          });
        }
        if (this.mdr.status === 'MDR devolvida por LSP') {
          this.mdrService.getMDRReprove(params.id).subscribe((elem) => {
            this.reprove = elem;
          });
        }
        if (this.mdr.status === 'Carga retornada total') {
          this.mdrService.getMDRReprove(params.id).subscribe((elem) => {
            // console.log(elem);
            this.shipmentReturned = elem;
          });
        }

        // pega DRs associadas
        this.drService.getByMdr(params.id).subscribe((newDr) => {
          this.drObservable.changeDR(newDr);
        });

        // pega UOCs da MDR
        this.mdrService.getUOC(this.mdr).subscribe((uoc) => {
          this.uocObservable.changeUOC(uoc);
        });

        // atualiza Observable da MDR
        this.mdrObservable.changeMDR(this.mdr);

        // inicializa socket
        this.initIoConnection(this.channel);

        // de acordo com mdr.status
        switch (this.mdr.status) {
          case 'MDR em validação':
            this.mdrService.createLegs(this.mdr.id, false).subscribe((legs) => {
              //
            });
            break;
          case 'MDR em criação':
            this.mdrService.getAllPriceR(this.mdr).subscribe((elem) => {
              this.price = elem;
              this.mdr.totalNF = elem.val;
              this.mdr.sapCode = elem.sapCd;
              this.mdr.totalKM = elem.km;
              this.mdr.shippingCompany = elem.sc;
              this.mdr.vehicle = elem.vehi;
              this.priceObservable.changePrice(elem);
              this.mdrObservable.changeMDR(this.mdr);
            });
            break;
          default: // todos os outros status
            this.mdrService.getAlreadyExistentPrice(this.mdr).subscribe((elem) => {
              this.price = elem;
              this.price.addDelivery = elem.addEntrega;
              this.price.adValorem = elem.adValorem;
              this.price.price = elem.frete;
              this.price.total = elem.total;
              this.price.taxedPrice = elem.freteTax;
              this.priceObservable.changePrice(elem);
            });
            break;
        }

      }, (error) => {
        this.snackBar.open('Erro ao carregar MDR.', 'Ok', {
          duration: 4000,
        });
        this.router.navigate(['/']);
      });
    });
  }

  updateNFCost() {
    this.tax.nfePrice = this.attachments.totalNfCost;
  }

  ngAfterViewInit() {
  }

  mapButtonClick() {
    this.hiddenMap = !this.hiddenMap;
  }

  showMap() {
    switch (this.mdr.status) {
      case 'MDR em validação':
      case 'MDR em criação':
      case 'MDR devolvida por LSP':
      case 'MDR aguardando LSP':
      case 'MDR aprovada LSP':
      case 'Motorista e veiculo definidos':
      case 'Veículo carregado':
        return true;
      default:
        return false;
    }
  }

  onClickPrint() {
    const mdrID = this.mdr.id;
    this.mdrService.getPDF(this.mdr.id).subscribe((data) => {
      FileSaver.saveAs(data, `Report-Logistic_Order-${mdrID}.pdf`);
    });
  }

  editStatus() {
    this.editMDRStatus = !this.editMDRStatus;
  }

  saveStatus() {
    this.editMDRStatus = !this.editMDRStatus;
    this.mdrService.update(this.mdr, this.currentUser.user.id).subscribe((data) => {
      this.snackBar.open('Status atualizado com sucesso. Por favor, recarregue a página.', 'Ok', {
        duration: 4000,
      });
    });
  }

  editMDRTypeAllowed(status) {
    if (status === MDR_STATUS.MDR_EM_CRIACAO) {
      return true;
    }
    return false;
  }
  editType() {
    this.editMDRType = !this.editMDRType;
  }

  saveType(new_type) {
    this.editMDRType = !this.editMDRType;
    this.mdrService.update(this.mdr, this.currentUser.user.id).subscribe((data) => {
      this.snackBar.open('Tipo de MDR atualizado com sucesso. Por favor, recarregue a página.', 'Ok', {
        duration: 4000,
      });
    },
      (error) => {
        this.snackBar.open('Não foi possivel atualizar a MDR.', 'Ok', {
          duration: 4000,
        });
      });
  }

  createDrivers() {
    this.transport.drivers.forEach((el, i, arr) => {
      this.createDriver(el);
    });
  }

  createDriver(driver: Driver) {
    driver.mdrId = this.mdr.id;
    this.mdrService.createDriver(driver).subscribe((response) => {
      // console.log(response);
    });
  }
  private initIoConnection(id: number): void {
    this.user = this.currentUser.user.id;
    this.socketService.initSocketMDR(id, this.user);

    // this.ioConnection = this.socketService.onComeMDRLists()
    //   .subscribe((message) => {
    //    //console.log(message);
    //     this.idsocket= message['user'][this.user];
    //     if(message['socket'][0]!==this.idsocket){
    //       this.lock =false;
    //     }else{
    //       this.lock =true;
    //     }
    //     this.aRoute.params.subscribe( params => {
    //       this.mdrService.getMDR(params.id).subscribe((data) => {
    //         this.mdr = data;
    //         this.mdr.lock = this.lock;
    //         this.mdrObservable.changeMDR(this.mdr);
    //       })
    //     });
    //
    //   });

    this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        // console.log('connected');
      });

    this.socketService.onEvent(Event.NEW_MESSAGE)
      .subscribe((message) => {
        // console.log(message);
        // this.messages.push(message);
      });

    this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
        // console.log('disconnected');
      });

  }



  cancelMDR(): void {
    this.mdr.status = 'MDR aguardando LSP';
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
      this.notification(data);

    });
  }
  deleteMDR(): void {
    this.mdr.status = 'MDR Reprovado';
    this.mdrService.update(this.mdr, this.user).subscribe(async (response) => {
      await this.mdrService.liberateDR(this.mdr).subscribe((data) => {
        this.snackBar.open(this.mdr.status, 'Ok', {
          duration: 4000,
        });
      });
      this.router.navigate(['delivery-order']);

    });
  }

  createToken(): void {
    this.mdrService.createToken(this.mdr.id).subscribe((response) => {
      const link = 'view/' + response.token + '/mdr';
      const dialogRef = this.dialog.open(CreateTokenDialogComponent, {
        data: {
          link: link
        }
      });
    });
  }

  reproveMDR(): void {
    const dialogRef = this.dialog.open(AcceptMdrReproveDialogComponent, {
      width: '600px',
      data: {
        text: '',
      }
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response[0] === true) {
        this.mdr.shippingCompany = '';
        this.mdrService.updateReprove(this.mdr, response[1]).subscribe((data) => {
          this.snackBar.open(this.mdr.status, 'Ok', {
            duration: 4000,
          });
          this.router.navigate(['delivery-order']);
        });
      } else {
        this.router.navigate(['delivery-order']);


      }
    });
  }

  openHistory() {
    this.mdrService.getHistory(this.mdr.id).subscribe((history) => {
      const dialogRef = this.dialog.open(MdrHistoryDialogComponent, {
        width: '80vw',
        maxHeight: '80vh',
        data: { history: history }
      });
    });
  }


  giveBackMDR(): void {
    this.mdr.status = 'MDR devolvida por LSP';
    this.mdr.shippingCompany = '';
    this.mdrService.update(this.mdr, this.user).subscribe((response) => {
      this.mdrService.giveBack(this.mdr).subscribe((data) => {
      });
    });
  }

  cancelTake(): void {
    this.mdr.status = 'MDR em criação';
    this.mdr.shippingCompany = '';
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
    });
  }

  allShipmentReturned(): void {
    const dialogRef = this.dialog.open(AcceptMdrReturnDialogComponent, {
      width: '600px',
      data: {
        text: '',
      }
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response[0] === true) {
        this.mdr.status = 'Carga retornada total';
        this.notification(response);
        this.mdrService.updateReturn(this.mdr, response[1]).subscribe((data) => {

        });
      } else {
        this.router.navigate(['delivery-order']);


      }
    });
  }


  updateMDR(): void {
    this.transport.saveDriver();
    this.transport.saveConveyor();
    this.tax.saveConveyor();
    this.tax.saveUOC();
    this.tax.saveCOPQ();
    this.tax.saveCA();
    this.attachments.updateInfo();
  }

  approveWithConditionsMDR(): void {
    const dialogRef = this.dialog.open(AcceptMdrReservationDialogComponent, {
      width: '600px',
      data: {
        text: '',
      }
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response[0] === true) {
        this.mdr.status = 'MDR aprovada com ressalvas LSP';
        this.notification(response);
        this.mdrService.updateRessalva(this.mdr, response[1]).subscribe((data) => {

        });
      } else {
        this.router.navigate(['delivery-order']);


      }
    });
  }

  returnTodeliveryAgain(): void {
    this.mdr.status = 'Carga retornada para re-entrega';
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
      this.notification(data);

    });
  }

  vinculateNewUser(): void {
    this.mdrService.getAvailableUsers().subscribe((users) => {
      if (users !== null) {
        if (users.length > 0) {
          const dialogRef = this.dialog.open(LSPEmailDialogComponent, {
            data: {
              users: users,
              existeNfVermelha: this.attachments.existeNfVermelha,
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result[0]) {
              const selectedUsers: User[] = result[1].value;
              if (selectedUsers !== null) {
                console.log(this.mdr, selectedUsers);
                this.mdrService.sendFollower(this.mdr, selectedUsers).subscribe(el => {
                  this.snackBar.open('Vinculo Realizado', 'Ok', { duration: 4000 });
                });
                // this.mdrService.sendLSPEmail(this.mdr, selectedUsers).subscribe();
              }
            } else {
              console.log('HI');
            }
        });
      }
    }
    });
  }

  sendLSP(): void {
    // verifica se as informações de transportadora estão preenchidas
    // if ( ['shippingCompany', 'vehicle', 'pickupDate', 'pickupCode'].some( e => {
    //   if (this.transportInfo.mdr[e] === null ||
    //      this.transportInfo.mdr[e] === '' ) {
    //     return true;
    //   }
    //   return false;
    // })){
    //   this.snackBar.open('Complete as informações da transportadora antes de prosseguir', 'Ok', { duration: 4000 });
    //   return;
    // }

    // verifica se a data de coleta é válida
    // const pickupDate = new Date(this.transportInfo.mdr.pickupDate);
    // this.drService.getByMdr(this.transportInfo.mdr.id).subscribe((drList) => {
    //   if( drList.some( e => {
    //     let dateScheduling = new Date(e.dateScheduling);
    //     if(pickupDate.getTime() > dateScheduling.getTime()) {
    //       // se a data de coleta for maior que a data da entrega
    //       return true;
    //     }
    //     return false;
    //   })){
    //     this.snackBar.open("Data de coleta deve ser menor que a data de entrega",'Ok', { duration: 4000 });
    //   } else {
    // data é válida, prossegue

    this.mdrService.getLSPUsers(this.mdr.shippingCompany).subscribe((users) => {
      if (users !== null) {
        if (users.length > 0) {
          const dialogRef = this.dialog.open(LSPEmailDialogComponent, {
            data: {
              users: users,
              existeNfVermelha: this.attachments.existeNfVermelha,
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result[0]) {
              const selectedUsers: User[] = result[1].value;
              if (selectedUsers !== null) {
                this.mdrService.sendLSPEmail(this.mdr, selectedUsers).subscribe();
              }

              this.tax.savePrice();
              if (this.mdr.type === 'CMS' && this.mdr.SAD === 'true') {
                const uoc = new UOCMDR();
                uoc.idMDR = this.mdr.id;
                uoc.value = this.tax.getTotal() * 0.5 * 100;
                uoc.uoc = 'Retorno Ericsson';
                this.mdrService.createUOC(uoc).subscribe((resp) => {
                  // console.log(resp);
                });
              }
              this.mdr.status = 'MDR aguardando LSP';
              this.mdrService.update(this.mdr, this.user).subscribe((data) => {
                // this.router.navigate(['delivery-optimizer']);
              });
            }
          });
        } else {
          if (this.mdr.type === 'CMS' && this.mdr.SAD === 'true') {
            const uoc = new UOCMDR();
            uoc.idMDR = this.mdr.id;
            uoc.value = this.tax.getTotal() * 0.5 * 100;
            uoc.uoc = 'Retorno Ericsson';
            // console.log(uoc);
            this.mdrService.createUOC(uoc).subscribe((resp) => {
              // console.log(resp);
            });
          }
          this.tax.savePrice();
          this.mdr.status = 'MDR aguardando LSP';
          this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.router.navigate(['delivery-optimizer']);
          });
        }
      }
    });

    // this.mdrService.savePrice(this.price, this.mdr).subscribe((el) => {
    // console.log('chegou aqui!');
    // });

  }

  saveAddressInMapp(): void {
    this.mdr.status = 'MDR em criação';
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
      this.notification(data);
      this.router.navigate(['list-mdrs']);
    });
  }

  reproveRessalva(): void {
    this.mdr.status = 'MDR em criação';
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
      this.notification(data);
    });
  }
  aproveRessalva(): void {
    this.mdr.status = 'MDR aprovada LSP';
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
      this.notification(data);
    });
  }

  acceptMDR(): void {
    this.mdr.status = 'MDR aprovada LSP';
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
      this.notification(data);
    });
  }

  defineDriver(): void {
    if (this.transport.drivers.length === 0) {
      this.snackBar.open('Favor definir um motorista', 'Ok', { duration: 4000 });
      return;
    }

    this.mdr.status = 'Motorista e veiculo definidos';
    this.createDrivers();
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
      // this.notification(data);
    });
  }

  vehicleLoaded(): void {
    this.mdr.status = 'Veículo carregado';
    // //console.log(this.mdr);
    this.drService.getByMdr(this.mdr.id).subscribe((drList) => {
      for (let i = 0; i < drList.length; i++) {
        if (drList[i].status === 'READY_TO_PICKUP') {
          drList[i].status = 'InTransit';
          this.drService.update(drList[i]).subscribe(k => {
            // console.log("UPDATED");
          });
        }
      }
    });
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
      this.notification(data);
    });
  }

  delivered(): void {
    this.mdr.status = 'Carga entregue com sucesso';
    this.drService.getByMdr(this.mdr.id).subscribe((drList) => {
      for (let i = 0; i < drList.length; i++) {
        if (drList[i].status === 'READY_TO_PICKUP' || drList[i].status === 'InTransit') {
          drList[i].status = 'Delivered';
          this.drService.update(drList[i]).subscribe(k => {
            // console.log("UPDATED");
          });
        }
      }
    });
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
      this.notification(data);
    });
  }

  deliveredCertified(): void {
    if (this.attachments.howManyPOD()) {
      const dialogRef = this.dialog.open(DeliveredCertifiedDialogComponent, {
        width: '600px',
        data: {
          text: '',
        }
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (response[0] === true) {
          this.mdr.status = 'Entrega de carga certificada pelo LSP';
          this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.router.navigate(['delivery-order']);
          });
        }
      });
    } else {
      this.snackBar.open('Faltam inserir PODs ou Foto de UOC', 'Ok', {
        duration: 4000,
      });
    }
  }

  pay(): void {
    this.mdr.status = 'MDR paga / concluída';
    this.drService.getByMdr(this.mdr.id).subscribe((drList) => {
      let k = true;
      drList.forEach((dr) => {
        if (!dr.prolog) {
          k = false;
        }
      });
      // if (k) {
      this.mdrService.update(this.mdr, this.user).subscribe((data) => {
        this.notification(data);
      });
      // } else {
      //   this.snackBar.open('DRs sem prolog. Impossível realizar', 'Ok', {
      //     duration: 4000,
      //   });
      // }
    });
  }
  goToChat(chatType) {
    this.router.navigate(['chat/' + this.mdr.id + '/' + chatType]);
  }
  notification(data) {
    this.socketService.initSocketNotification(Number(this.user));
    const message = new Message(this.user, 'teste', this.user, new Date(), this.chatType);
    this.socketService.sendNotification(message);
    // console.log(data);
    data.forEach(value => {
      const notification = new Message(value.idUser, 'teste', Number(value.idUser), new Date(), this.chatType);
      this.socketService.sendNotification(notification);
    });
    this.snackBar.open(this.mdr.status, 'Ok', {
      duration: 4000,
    });
    this.router.navigate(['delivery-optimizer']);
  }
}

export interface Transportadora {
  id: number;
  name: string;
}

export interface Veiculo {
  id: number;
  name: string;
}

export interface Price {
  adValorem: number;
  addDelivery: number;
  price: number;
  taxedPrice: number;
  total: number;
  sc: string;
  vehi: string;
}

@Component({
  selector: 'app-accept-mdr-reservation-dialog',
  templateUrl: 'accept-mdr-reservation-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class AcceptMdrReservationDialogComponent {
  public valueRe;

  constructor(
    public dialogRef: MatDialogRef<AcceptMdrReservationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}
@Component({
  selector: 'app-accept-mdr-return-dialog',
  templateUrl: 'accept-mdr-return-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class AcceptMdrReturnDialogComponent {
  public valueRe;

  constructor(
    public dialogRef: MatDialogRef<AcceptMdrReturnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}


@Component({
  selector: 'app-accept-mdr-reprove-dialog',
  templateUrl: 'accept-mdr-reprove-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class AcceptMdrReproveDialogComponent {
  public valueRe;
  constructor(
    public dialogRef: MatDialogRef<AcceptMdrReproveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}

@Component({
  selector: 'app-delivered-certified-dialog',
  templateUrl: 'delivered-certified-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class DeliveredCertifiedDialogComponent {
  public valueRe;
  constructor(
    public dialogRef: MatDialogRef<AcceptMdrReproveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}

@Component({
  selector: 'app-lsp-email-dialog',
  templateUrl: 'lsp-email-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class LSPEmailDialogComponent {
  selected = new FormControl();
  constructor(
    public dialogRef: MatDialogRef<LSPEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}

@Component({
  selector: 'app-create-token-dialog',
  templateUrl: 'create-token-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class CreateTokenDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateTokenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onClickOk(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-mdr-history',
  templateUrl: 'mdr-history.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class MdrHistoryDialogComponent implements OnInit {
  history = [];
  columns = [
    { value: 'uid', name: 'LOGISTIC-ORDER/history/id/' },
    { value: 'status', name: 'LOGISTIC-ORDER/history/status/' },
    { value: 'quem', name: 'LOGISTIC-ORDER/history/by/' },
    { value: 'quando', name: 'LOGISTIC-ORDER/history/on/' },
    { value: 'pickupDate', name: 'LOGISTIC-ORDER/history/date/' },
    { value: 'pickupID', name: 'LOGISTIC-ORDER/history/pickupId/' },
    { value: 'observation', name: 'LOGISTIC-ORDER/history/observation/' },
    { value: 'vehicle', name: 'LOGISTIC-ORDER/history/vehicle/' },
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public datepipe: DatePipe) {
    this.history = data.history;
    this.history.forEach(info => {
      info.quando = this.datepipe.transform(info.quando, 'dd/MM/yyyy hh:mm:ss');
      info.pickupDate = this.datepipe.transform(info.pickupDate, 'dd/MM/yyyy hh:mm:ss');
    });
  }
  ngOnInit() {

  }
}
