import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { OV } from '../models/ov';
import {DR} from '../models/dr';
import {MDR} from '../models/mdr';
import {UOC} from '../models/uoc';
import {Transportadora} from '../pages/logistic-order/logistic-order.component';
import {environment} from '../../environments/environment';
import {Occurrence} from '../models/occurrence';
import {UOCMDR} from '../models/uoc__mdr';
import {CostAvoidance} from '../models/costAvoidance';
import {Copq} from '../models/copq';
import {CostAvoidanceMDR} from '../models/costAvoidance__mdr';
import {COPQMDR} from '../models/copq__mdr';
import {Driver} from '../models/driver';
import {Legs} from '../models/legs';
import { SiteService } from '../providers/site.service';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class MdrService {
  public token: string;
  private uid = 'MdrService-';

  constructor(private http: HttpClient, public siteService: SiteService ) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  public insertLatam(ovList: OV[], dr: DR, mdr: MDR): Observable<Boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'MDR/insertLatam', {
      mdr: mdr,
      ovList: ovList,
      dr: dr
    }, { headers: header}).map((response: MDR) => {
      return true;
    });
  }

  public insertPickup(mdr: MDR): Observable<MDR> {
    //// console.log(mdr);
     const token = JSON.parse(localStorage.getItem('currentUser')).token;
     const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
     return this.http.post(base_url + 'MDR/pickup', {
       mdr: mdr,
     }, { headers: header}).map((response: MDR) => {
         return response;
       });
   }


  public insert(mdr: MDR, type: boolean): Observable<MDR> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'MDR/', {
      mdr: mdr,
      returnLeg: type
    }, { headers: header}).map((response: MDR) => {
        return response;
      });
  }

  public insertConsolidation(mdr: MDR): Observable<MDR> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'MDR/consolidation', {
      mdr: mdr,
    }, { headers: header}).map((response: MDR) => {
        return response;
      });
  }

  public  getAllPriceR(mdr: MDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'PM/getAllPrice?'
      + 'vehicle=' + mdr.vehicle +
      '&shippingCompany=' + mdr.shippingCompany +
      '&id=' + mdr.id +
      '&isAll=true', {headers: header})
      .map( (response: any) => {
      return response;
    });
  }

  public getPickupID(mdrId: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/' + mdrId
                       , {headers: header}).map( (response: MDR) => {
                         if ( 'pickupID' in response ) {
                           return response.pickupID ? response.pickupID.trim() : response.pickupID;
                         }
                         return null;
                       });
  }

  public  getAllPrice(mdr: MDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    return this.http.get(base_url + 'PM/getPrice?'
      + 'vehicle=' + mdr.vehicle +
      '&shippingCompany=' + mdr.shippingCompany +
      '&id=' + mdr.id +
      '&isAll=true', {headers: header}).map( (response: MDR) => {
      return response;
    });
  }

  public  getAllPriceWithKm(mdr: MDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'PM/getPriceWithKMAndPrice?'
      + 'vehicle=' + mdr.vehicle +
      '&shippingCompany=' + mdr.shippingCompany +
      '&id=' + mdr.id +
      '&km=' + mdr.totalKM +
      '&value=' + mdr.totalNF +
      '&isAll=true', {headers: header}).map( (response: MDR) => {
      return response;
    });
  }

  public  getVehicleData(mdrId: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    return this.http.get(base_url + 'MDR/vehicle/' + mdrId, {headers: header}).map( (response: MDR) => {
      return response;
    });
  }

  public getDriverByMdrId(id: number): Observable <any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'driver/' + id
      , {headers: header}).map( (response: Driver[]) => {
      return response;
    });
  }
  public getDriverByMdrIdAll(id: number): Observable <any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'driver/findAll/' + id
      , {headers: header}).map( (response: Driver[]) => {
      return response;
    });
  }
  public getTransport(id: number): Observable <any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'mdr/transport/' + id
      , {headers: header}).map((response) => {
      return response;
    });
  }

  public createDriver(driver: Driver): Observable <any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'driver/', {
      driver: driver,
    }, {headers: header}).map((response) => {
      return true;
    });
  }

  public createOccurrence(occurrence: Occurrence): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'occurrence/', {
      occurrence: occurrence,
    }, {headers: header}).map((response) => {
      return true;
    });
  }

  public deleteOccurrence(occurrence: Occurrence): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.delete(base_url + 'occurrence/' + occurrence.id,
    {headers: header}).map((response) => {
      return true;
    });
  }

  public getUOC(mdr: MDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const params = new HttpParams().set('id', String(mdr.id));
    return this.http.get(base_url + 'UOC/AllFromMDR', {headers: header, params: params}).map( (response) => {
      return response;
    });
  }

  public getUOCWithInfo(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'UOC/AllFromMDRWithInfo', {id: id, entity: 'uoc'}, {headers: header}).map( (response) => {
      return response;
    });
  }

  public getCostAvoidance(mdr: MDR): Observable<CostAvoidanceMDR[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const params = new HttpParams().set('id', String(mdr.id));
    return this.http.get(base_url + 'costavoidance/AllFromMDR', {headers: header, params: params}).map( (response: CostAvoidanceMDR[]) => {
      return response;
    });
  }

  public getCoPQ(mdr: MDR): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const params = new HttpParams().set('id', String(mdr.id));
    return this.http.get(base_url + 'copq/AllFromMDR', {headers: header, params: params}).map( (response: COPQMDR[]) => {
      return response;
    });
  }

  public getUOCValue(uoc: Occurrence, mdr: MDR): Observable<number> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const params = new HttpParams().set('mdr', JSON.stringify(mdr)).append('uoc', JSON.stringify(uoc));
    return this.http.get(base_url + 'UOC/getUOCBaseValue', {headers: header, params: params}).map( (response: {price}) => {
      if (response === undefined ||
          response === null ||
          Number(response.price) === NaN
      ) {
        return 0;
      }
      return Number(response.price);
    });
  }


  public createUOC(uocmdr: UOCMDR): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'UOC/', {
      uocmdr: uocmdr,
    }, {headers: header}).map((response) => {
      return true;
    });
  }

  public createCoPQ(copqmdr: COPQMDR): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'copq/', {
      copqmdr: copqmdr,
    }, {headers: header}).map((response) => {
      return true;
    });
  }

  public createCostAvoidance(camdr: CostAvoidanceMDR): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'costavoidance/', {
      camdr: camdr,
    }, {headers: header}).map((response) => {
      return true;
    });
  }

  public updateOccurrence(occurrence: Occurrence): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    console.log(occurrence);
    return this.http.put(base_url + 'occurrence/', {
      occurrence: occurrence,
    }, {headers: header}).map((response) => {
      return true;
    });
  }


  public chageStatusOccurence(occurrence: Occurrence): Observable<Occurrence[]> {
    return null;
  }

  public getOccurences(mdr: MDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const params = new HttpParams().set('id', String(mdr.id));
    return this.http.get(base_url + 'occurrence/getByMDR', {headers: header, params: params}).map( (response) => {
      return response;
    });
  }

  public getOccurencesList(): Observable<Occurrence[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'occurrence/all', {headers: header}).map( (response: Occurrence[]) => {
      return response;
    });
  }

  public getAlreadyExistentPrice(mdr: MDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'price/getPrice?' + 'id=' + mdr.id, {headers: header}).map( (response: MDR) => {
      return response;
    });
  }

  public getAllPaymentList(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/getAllPaymentsAvailable', {headers: header}).map( response => {
      return response;
    });
  }

  public getPaymentList(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/payments', {headers: header}).map( response => {
      return response;
    });
  }

  public getReport(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'MDR/report', {headers: header}).map( response => {
      return response;
    });
  }

  public payNF(entityInfo: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'MDR/payments/pay', { pay: entityInfo }, {headers: header}).map( response => {
      return response;
    });
  }

  public updatePayment(payment: any[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'MDR/payments/update', payment, {headers: header}).map( response => {
      return response;
    });
  }

  public removeOccurrence(uoc: UOCMDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.delete(base_url + 'UOC/' + uoc.id, {headers: header}).map( response => {
      return response;
    });
  }

  public removeOccurrenceCOPQ(copq: COPQMDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.delete(base_url + 'copq/' + copq.id, {headers: header}).map( response => {
      return response;
    });
  }

  public createPay(entityInfo: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'MDR/payments/create', { entityInfo: entityInfo }, {headers: header}).map( response => {
      return response;
    });
  }

  public  getPrice(mdr: MDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'PM/getPrice?' + 'vehicle=' + mdr.vehicle + '&shippingCompany=' + mdr.shippingCompany + '&id=' + mdr.id, {headers: header}).map( (response: MDR) => {
      return response;
    });
  }

  public savePrice(price: Price, mdr: MDR): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const prMDR: PriceMDR = {addEntrega: 0, adValorem: 0, frete: 0, gris: 0, total: 0, freteTax: 0, mdrID: 0, id: 0};
    prMDR.addEntrega = price.addDelivery;
    prMDR.adValorem = price.adValorem;
    prMDR.frete = price.price;
    prMDR.gris = 0;
    prMDR.total = price.total;
    prMDR.freteTax = price.taxedPrice;
    prMDR.mdrID = mdr.id;
    return this.http.put(base_url + 'price/new', {
      price: prMDR,
    }, {headers: header}).map((response) => {
      return true;
    });
  }

  public getLSPUsers(lsp: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/GetLSPUsers/' + lsp,
                         {headers: header}).map((response) => {
      return response;
    });
  }

  public getAvailableUsers(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'client/getAllAvailable/',
                         {headers: header}).map((response) => {
      return response;
    });
  }


  public sendLSPEmail(mdr: MDR, users: User[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'MDR/SendLSPEmail',
                         {mdr: mdr.id, toUsers: users},
                         {headers: header}).map((response) => {
      return response;
    });
  }

  public sendFollower(mdr: MDR, users: User[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'client/UserTrip',
                         {mdr: mdr, toUsers: users},
                         {headers: header}).map((response) => {
      return response;
    });
  }

  public update(mdr: MDR, userID: string): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.put(base_url + 'MDR/', {
      mdr: mdr,
      user: userID,
    }, {headers: header}).map((response) => {
      return response;
    });
  }
  public giveBack(mdr: MDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.put(base_url + 'MDR/giveBack', {
      mdr: mdr
    }, {headers: header}).map((response) => {
      return response;
    });
  }

  public liberateDR(mdr: MDR): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.put(base_url + 'MDR/liberateDR', {
      mdr: mdr,
    }, {headers: header}).map((response) => {
      return true;
    });
  }

  public updateRessalva(mdr: MDR, ressalva: string): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.put(base_url + 'MDR/ressalva', {
      mdr: mdr,
      ressalva: ressalva
    }, {headers: header}).map((response) => {
      return true;
    });
  }
  public updateReturn(mdr: MDR, text: string): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.put(base_url + 'MDR/shipmentReturned', {
      mdr: mdr,
      text: text
    }, {headers: header}).map((response) => {
      return true;
    });
  }


  public updateReprove(mdr: MDR, reprove: string): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.put(base_url + 'MDR/reprove', {
      mdr: mdr,
      reprove: reprove
    }, {headers: header}).map((response) => {
      return true;
    });
  }
  public updateDriver(drivers: Driver[], mdrId: number): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'driver/updateDriver/', {
      drivers: drivers, mdrId: mdrId
    }, {headers: header}).map((response) => {
      return true;
    });
  }
  public haveNewOccurrence(): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'occurrence/HaveOccurrence', {headers: header}).map( (response: {count} ) => {
      if (response.count > 0) {
        return true;
      } else {
        return false;
      }
    });
  }

  public haveNewMDR(): Observable<boolean> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/HaveMDR', {headers: header}).map( (response: {count} ) => {
      if (response.count > 0) {
        return true;
      } else {
        return false;
      }
    });
  }

  public getAllWithHub(): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/AllWithHub', {headers: header}).map( (response: any[]) => {
      return response;
    });
  }

  public  getAll(): Observable<MDR[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/All', {headers: header}).map( (response: MDR[]) => {
      return response;
    });
  }

  public  getAllWithClient(): Observable<MDR[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/AllWithClient', {headers: header}).map( (response: MDR[]) => {
      return response;
    });
  }

  public  getAllDR(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'MDR/AllDR', {headers: header}).map( (response: any) => {
      return response;
    });
  }
  public  getAllLSP(): Observable<MDR[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/AllLSP', {headers: header}).map( (response: MDR[]) => {
      return response;
    });
  }

  public  getDocsInfo(): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/spreadsheet', {headers: header}).map( (response: any[]) => {
      return response;
    });
  }


  public getAllMDR(): Observable<MDR[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/AllMdr', {headers: header}).map( (response: MDR[]) => {
      return response;
    });
  }

  public createLegs(id: number, ret: boolean): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'Legs/', {mdrId: id, returnLeg: ret}, {headers: header})
      .map( (response: any) => {
      });
  }

  public getLegs(id: number): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'Legs/' + id, {headers: header})
      .map( (response: Legs[]) => {
        return response;
      });
  }

  public putLegs(leg: Legs, mdrId: number): Observable<Legs[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.put(base_url + 'Legs/', {leg: leg, mdrId: mdrId} , {headers: header})
      .map( (response: Legs[]) => {
        return response;
      });
  }

  public removeLeg(leg, mdrId: number): Observable<Legs[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.put(base_url + 'Legs/delete', {leg: leg, mdrId: mdrId} , {headers: header})
      .map( (response: Legs[]) => {
        return response;
      });
  }



  public  getMDR(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/' + id, {headers: header}).map( (response: MDR) => {
      if ( 'pickupID' in response ) {
        response.pickupID = response.pickupID ? response.pickupID.trim() : response.pickupID;
      }
      return response;
    });
  }
  public  getNf(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/nf/' + id, {headers: header}).map( (response: MDR) => {
      return response;
    });
  }
  public  getMDRReservation(id: number): Observable<MDR> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/Reservation/' + id, {headers: header}).map( (response) => {
      return response['reservation'];
    });
  }
  public  getMDRReprove(id: number): Observable<MDR> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'MDR/reprove/' + id, {headers: header}).map( (response) => {
      return response['text'];
    });
  }
  public  getMDReturned(id: number): Observable<MDR> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'MDR/shipmentReturned/' + id, {headers: header}).map( (response) => {
      return response['text'];
    });
  }

    public  unmakeDR(dr: DR): Observable<DR[]> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      return this.http.delete(base_url + 'DR/' + dr.id, {headers: header}).map( (response: DR[]) => {
        return response;
      });
    }

  public getOccurrenceEnum(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    if ( (this.uid + 'getOccurrenceEnum') in localStorage ) {
      return new Observable((observer) => {
        observer.next(JSON.parse(localStorage.getItem(this.uid + 'getOccurrenceEnum')));
      });
    } else {
      return this.http.get(base_url + 'occurrence/enum', {headers: header}).map((response: any) => {
        localStorage.setItem(this.uid + 'getOccurrenceEnum', JSON.stringify(response));
        return response;
      });
    }
  }

    public getListUOC(): Observable<UOC[]> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      if ( (this.uid + 'getListUOC') in localStorage ) {
        return new Observable((observer) => {
          observer.next(JSON.parse(localStorage.getItem(this.uid + 'getListUOC')));
        });
      } else {
        return this.http.get(base_url + 'UOC/AllUoc', {headers: header}).map( (response: UOC[]) => {
          localStorage.setItem(this.uid + 'getListUOC', JSON.stringify(response));
          return response;
        });
      }
    }

  public getListCoPQ(): Observable<Copq[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    if ( (this.uid + 'getListCoPQ') in localStorage ) {
      return new Observable((observer) => {
        observer.next(JSON.parse(localStorage.getItem(this.uid + 'getListCoPQ')));
      });
    } else {
      return this.http.get(base_url + 'copq/All', {headers: header}).map( (response: UOC[]) => {
        localStorage.setItem(this.uid + 'getListCoPQ', JSON.stringify(response));
        return response;
      });
    }
  }

  public getListCostAvoidance(): Observable<CostAvoidance[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'costavoidance/All', {headers: header}).map( (response: UOC[]) => {
      return response;
    });
  }

  public getDriverLastPosition(mdr: MDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'driver/GetDriverLastPosition/' + mdr.id, {headers: header}).map( (response: UOC[]) => {
      return response;
    });
  }

  public getLastPositions(mdrIdList: number[]): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'driver/GetLastPositions', {mdrIdList: mdrIdList}, {headers: header}).map( (response: any) => {
      return response;
    });
  }

  public getDriverPosition(mdrID: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'driver/GetDriverPositions/' + mdrID, {headers: header}).map( (response) => {
      return response;
    });
  }

  public isSLA(mdrID: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'sla/mdr/' + mdrID, {headers: header}).map( (response) => {
      return response;
    });
  }

  public unsetSLA(mdrID: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.delete(base_url + 'sla/mdr/' + mdrID, {headers: header}).map( (response) => {
      return response;
    });
  }

  public setSLA(mdrID: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.put(base_url + 'sla/mdr/' + mdrID, {}, {headers: header}).map( (response) => {
      return response;
    });
  }

  public getPDF(id: number): Observable<any> {
    const utc = -(( new Date() ).getTimezoneOffset() / 60); // o oposto do offset para converter de UTC para local
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'MDR/pdf/' + id + '?utc=' + utc, {
       headers: header,
       responseType: 'blob'
    });
  }

  public getStatusPayment(id: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/payments/' + id, {headers: header}).map( (response) => {
      return response;
    });
  }

  /*******************
   *
   * Funções pra acesso sem login
   *
   */
  public createToken(idMDR: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'MDR/token/create', {mdrID: idMDR}, {headers: header})
               .map( (response: any) => {
                 return response;
               });
  }


  public getMdrWithToken(token: string): Observable<any> {
    return this.http.get(base_url + 'no-login/MDR/' + token);
  }

  public getLegsWithToken(id: number, token: string): Observable<any[]> {
    return this.http.get(base_url + 'no-login/Legs/' + id + '/' + token).map((response: Legs[]) => {
      return response;
    });
  }

  public getDriverLastPositionWithToken(mdrID: number, token: string): Observable<any> {
    return this.http.get(base_url + 'no-login/driver/GetDriverLastPosition/' + mdrID + '/' + token).map((response: UOC[]) => {
      return response;
    });
  }

  public getDriverPositionWithToken(mdrID: number, token: string): Observable<any> {
    return this.http.get(base_url + 'no-login/driver/GetDriverPositions/' + mdrID + '/' + token).map( (response) => {
      return response;
    });
  }


  public getHistory(id: number): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'MDR/history/' + id, {headers: header}).map((response: any[]) => {
      return response.map(e => {
        if (e.quando) { e.quando = new Date(e.quando); }
        if (e.pickupDate) { e.pickupDate = new Date(e.pickupDate); }
        return e;
      });
    });
  }

  public removePayment(payId: Number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.delete(base_url + 'mdr/payments/' + payId, {headers: header}).map( response => {
      return response;
    });
  }

  public removeDriverPosition(pointId: Number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.delete(base_url + 'driver/driverPosition/' + pointId, {headers: header}).map( response => {
      return response;
    });
  }
}



export interface Price {
  adValorem: number;
  addDelivery: number;
  price: number;
  taxedPrice: number;
  total: number;
  sc: string;
  vehi: string;
}

export interface PriceMDR {
  id: number;
  frete: number;
  addEntrega: number;
  freteTax: number;
  adValorem: number;
  gris: number;
  total: number;
  mdrID: number;
}
