<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'TRANSPORT/title' | translate }}
        </div>
      </div>
      <hr>
      <div class="row ">
        <div class="col-md-9">
          <span class="title"> {{ 'TRANSPORT/conveyor' | translate }}</span>
        </div>
      </div>
      <div class="border-button">
        <div class="row" >
          <mat-form-field *ngIf="role === 1" class="col-md-6">
            <mat-select  placeholder="{{ 'TRANSPORT/conveyor' | translate }}" [(ngModel)]="mdr.shippingCompany"  >
              <mat-option *ngFor="let elem of transportadora" (click)="updateShippingCompany(elem)" [value]="elem.name"  >
                {{ elem.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="role === 5 || role === 9" class="col-md-6">
            <p>{{ 'TRANSPORT/conveyor' | translate }}:<span> {{mdr.shippingCompany}}</span></p>
          </div>
          <mat-form-field *ngIf="role === 1" class="col-md-4">
            <mat-select placeholder="{{ 'TRANSPORT/vehicle' | translate }}" [(ngModel)]="mdr.vehicle">
              <mat-option *ngFor="let elem of veiculo" (click)="updateVehicle(elem)" [value]="elem.name" >
                {{ elem.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <div *ngIf="role === 1 || role === 5 || role === 9" class="col-md-4">
          <i  [matTooltip]="'Clique para ver valores'" (click)="(showVehicleData = !showVehicleData)" [matTooltipClass] = "'my-tooltip'" [matTooltipPosition]="positionOptions" class="fa fa-question-circle" ></i>
          </div> -->
          <mat-form-field *ngIf="role === 1" class="col-md-2">
            <input matInput type="text" placeholder="Quantity" [(ngModel)]="mdr.freight">
          </mat-form-field>
          <div *ngIf="role === 5 || role === 9" class="col-md-2">
            <p>Quantity:<span> {{mdr.freight}}</span></p>
          </div>
        </div>
        <div *ngIf="showVehicleData" class="row">
          <p class="col-md-4">Altura: {{tooltip.maxH}} m</p>
          <p class="col-md-4">Largura: {{ tooltip.maxW}} m</p>
          <p class="col-md-4">Comprimento: {{tooltip.maxL}} m</p>
          <p class="col-md-4">Peso: {{tooltip.wei}} Kg</p>
          <p class="col-md-4">Volume: {{tooltip.vol}} m³</p>
        </div>
        <div class="row" >
          <mat-form-field *ngIf="role === 1" class="col-md-6">
            <input matInput
              placeholder="{{ 'COCKPIT/date_search' | translate }}"
              [(ngModel)]="mdr.pickupDate"
              [owlDateTimeTrigger]="rangeDatepicker"
              [owlDateTime]="rangeDatepicker">
            <owl-date-time #rangeDatepicker></owl-date-time>
          </mat-form-field>
          <div *ngIf="role === 5 || role === 9" class="col-md-6">
            <p>Data de Coleta:<span> {{mdr.pickupDate | date:"dd/MM/yy, h:mm a"}}</span></p>
          </div>
          <mat-form-field *ngIf="role === 1" class="col-md-6">
            <input matInput  type="text" placeholder="{{ 'TRANSPORT/code_collect' | translate }}" [(ngModel)]="mdr.pickupID">
          </mat-form-field>
          <div *ngIf="role === 5 || role === 9" class="col-md-6">
            <p>Código da Coleta:<span> {{mdr.pickupID}}</span></p>
          </div>
        </div>
        <div class="row" *ngIf="role === 1" >
          <mat-form-field class="col-md-12">
            <input    matInput type="text" placeholder="{{ 'TRANSPORT/obs' | translate }}" [(ngModel)]="mdr.observation">
          </mat-form-field>
        </div>
        <div *ngIf="role === 5 || role === 9" class="col-md-12">
          <p>Observações:<span> {{mdr.observation}}</span></p>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-9">
          <span class="title">{{ 'TRANSPORT/driver' | translate }}</span>
        </div>
      </div>
      <div *ngIf="role !== 9" class="row">
        <div class="col-md-12">
          <div *ngFor="let driver of drivers;index as i">
            <div *ngIf="drivers[i].status === true">
              <div class="row">
                <div class="col-md-1">
                  <span>{{ 'TRANSPORT/leader' | translate }}</span>
                  <label class="container">
                    <input   *ngIf="leader!== null && drivers.length>=2"type="radio" [(ngModel)]="leader" [value]="i">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <mat-form-field class="col-md-3">
                  <input    matInput [(ngModel)]="drivers[i].name" placeholder="{{ 'TRANSPORT/name' | translate }}">
                </mat-form-field >
                <mat-form-field class="col-md-3">
                  <input  matInput #tel   mask="00 (00) 00009-0000"   [(ngModel)]="drivers[i].phoneNumber" placeholder="{{ 'TRANSPORT/phone' | translate }}">
                </mat-form-field>
                <mat-form-field class="col-md-3">
                  <input   matInput mask="SSS-0000"  [(ngModel)]="drivers[i].plate" placeholder="{{ 'TRANSPORT/board' | translate }}">
                </mat-form-field>
                <div class="col-md-2">
                  <button  *ngIf="drivers.length!== 0"  mat-raised-button class="button red-button text-white"    (click)="removerDriver(i)" >{{ 'TRANSPORT/remove' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="col-md-8" style="margin-top:10px">
        <button mat-raised-button class="button green-button text-white "   (click)="addDriver()">{{ 'TRANSPORT/add' | translate }}</button>
      </div>
      <div class="col-md-4" style="margin-top:10px">
        <button mat-raised-button class="button green-button text-white"  (click)="saveDriver()" >{{ 'TRANSPORT/save_driver' | translate }}</button>
      </div>
      </div>
    </div>
  </div>
</div>
