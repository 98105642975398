import { OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';
var MdrPieChartComponent = /** @class */ (function () {
    function MdrPieChartComponent(translate) {
        this.translate = translate;
        this.noData = false;
    }
    MdrPieChartComponent.prototype.ngOnInit = function () { };
    MdrPieChartComponent.prototype.createChart = function (data) {
        if (data.length > 0) {
            this.noData = false;
            var series_1 = [];
            data.forEach(function (element) {
                var shippingCompany = element.shippingCompany;
                if (element.shippingCompany === '' || element.shippingCompany === null) {
                    shippingCompany = 'Sem transportadora associada';
                }
                series_1.push({ name: shippingCompany, y: element.count });
            });
            this.chart = new Chart({
                chart: {
                    type: 'pie'
                },
                title: {
                    text: ''
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                            distance: -50,
                            filter: {
                                property: 'percentage',
                                operator: '>',
                                value: 4
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                        name: 'MDRS',
                        colorByPoint: true,
                        type: undefined,
                        data: series_1
                    }]
            });
        }
        else {
            this.noData = true;
        }
    };
    return MdrPieChartComponent;
}());
export { MdrPieChartComponent };
