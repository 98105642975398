import {Component, OnInit, Input, Inject} from '@angular/core';
import {MDR} from '../../../models/mdr';
import {DR} from '../../../models/dr';
import {Transport} from '../../../models/transport';

import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Occurrence} from '../../../models/occurrence';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, TooltipPosition} from '@angular/material';
import {MdrObservable} from '../../../services/mdr.service';
import {PriceObservable} from '../../../services/price.service';
import {MdrService} from '../../../providers/mdr.service';
import {TranslateService} from '@ngx-translate/core';
import {Driver} from '../../../models/driver';
import { DrObservable } from '../../../services/dr.service';
import { SiteService } from '../../../providers/site.service';
import { OVService } from '../../../providers/ov.service';

@Component({
   selector: 'app-transport',
   templateUrl: './transport.component.html',
   styleUrls: ['./transport.component.scss']
})
export class TransportComponent implements OnInit {
  mdr: MDR = new MDR;
  dr: DR[];
  transports: Transport[];
  role: any;
  currentUser: any;
  time: any;
  statusContainer = false;

  veiculo: Veiculo[] = [
    {id: '1', name: 'CARRETA' },
    {id: '2', name: 'CARRETA SIDER'},
    {id: '3', name: 'CARRETA PRANCHA'},
    {id: '4', name: 'CONTAINER 40FT'},
    {id: '5', name: 'CONTAINER 20FT'},
    {id: '6', name: 'AEREO'},
    {id: '7', name: 'COURIER'}];

  transportadora: any[] = [];
  @Input() drivers: Driver[] = [];
  leader: any = null;
  user: any;
  tooltip: any;
  showVehicleData = false;
  positionOptions: TooltipPosition = 'after';

  constructor(public translate: TranslateService, public router: Router,
              public priceObservable: PriceObservable , public mdrService: MdrService, public ovService: OVService,
              public mdrObservable: MdrObservable, public drObservable: DrObservable, public aRoute: ActivatedRoute,
              public siteService: SiteService,
              public dialog: MatDialog, public snackBar: MatSnackBar) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.user = this.currentUser.user.id;
    this.siteService.getShippingCompanys().subscribe((data) => {
      this.transportadora = data;
    });
  }

  ngOnInit() {
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };

    this.mdrObservable.currentMDR.subscribe(mdr => {
      this.mdr = mdr;
      // console.log(this.mdr.pickupDate);
      this.ovService.getNfByMdr(this.mdr.id).subscribe((data) => {
        const ovs = data.map((e) => e.ov).filter(unique);
        this.ovService.getVehicle(ovs).subscribe((response) => {
          this.tooltip.wei = (response.searched.weight).toFixed(2);
          this.tooltip.maxH = (response.searched.height / 10).toFixed(3);
          this.tooltip.maxW = (response.searched.width / 10).toFixed(3);
          this.tooltip.maxL = (response.searched.length / 10).toFixed(3);
          this.tooltip.vol = (response.searched.volume / 1000).toFixed(2);
        });
      });
      const date = new Date(this.mdr.pickupDate);
      // console.log(this.mdr.pickupDate);
      if (this.mdr.pickupDate === null) {
        this.time = '00:00';
      } else {
        this.time = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
      }
      this.getDriver();
    });
  }

   updateShippingCompany(elem: Transportadora) {
      // console.log(elem);
      this.mdr.shippingCompany = elem.name;
      this.mdrService.getAllPrice(this.mdr).subscribe( (resp) => {
         this.priceObservable.changePrice(resp);
      });
   }

   changeTime() {
      const time = this.time.split(':');
      // console.log(time);
      this.mdr.pickupDate = new Date(this.mdr.pickupDate);
      this.mdr.pickupDate.setHours(time[0]);
      this.mdr.pickupDate.setMinutes((time[1]));
   }

   updateVehicle(elem: Veiculo) {
      // console.log(elem);
      this.mdr.vehicle = elem.name;
      this.mdrService.getAllPrice(this.mdr).subscribe( (resp) => {
         // console.log(resp);
         this.priceObservable.changePrice(resp);
      });
   }

   goToChat() {
      this.router.navigate(['chat/' + this.mdr.id + '-driver']);
   }

   getVehicleSizes() {
      // End Point para pega valores que seta tamanho do veículo
   }

   getDriver() {
      this.mdrService.getDriverByMdrIdAll(this.mdr.id).subscribe( (response) => {
         // console.log(response);
         this.drivers = response;
         const index = this.drivers.map((driver, i) => [i, driver]).filter(driver => driver[1]['leader'] === true);
         if ( index.length ) {
            this.leader = index[0][0];
         }
      });
   }
   saveDriver() {
      // se algum dos motoristas estiver incompleto
      if ( this.drivers.some( (el, i, arr) => {
         if ( el.name === null
            || el.phoneNumber === null
            || el.plate === null ) {
            return true;
         }
      }) ) {
         this.snackBar.open('Favor preencher os detalhes do motorista', 'Ok', {duration: 4000});
         return;
      }

      this.drivers = this.drivers.map(driver => {driver.leader = false; return driver; });
      const driversCompare = this.drivers.filter(driver => driver.status === true ).length;
      if (driversCompare >= 2) {
         this.drivers[this.leader].leader = true;
      }
      // console.log(this.drivers);
      this.mdrService.updateDriver(this.drivers, this.mdr.id).subscribe( (response) => {
         this.snackBar.open('Alterações Salvas ', 'Ok', {duration: 4000});
         this.getDriver();
      });
   }
   saveConveyor() {
      // console.log(this.mdr);
      this.mdrService.update(this.mdr, this.user).subscribe( (response) => {
         // console.log(response);
      });
   }

   addDriver() {
      const newDriver = new Driver();
      this.drivers.push(newDriver);
      const index = this.drivers.map((driver, i) => [i, driver]).filter(driver => driver[1]['status'] !== false);
      if ( index.length === 2 ) {
         this.leader = index[0][0];
      }
   }

   removerDriver(i) {
      this.drivers[i].status = false;
      if (i === this.leader) {
         const index = this.drivers.map((driver, idx) => [idx, driver]).filter(driver => driver[1]['status'] !== false);
         // console.log(index);
         if (index.length === 1) {
            this.leader = null;
         } else {
            this.leader = index[0][0];
         }
      } else {
         const index = this.drivers.map((driver, idx) => [idx, driver]).filter(driver => driver[1]['status'] !== false);
         if (index.length === 1) {
            this.leader = null;
         }
      }
   }

}
export interface Transportadora {
   id: string;
   name: string;
}

export interface Veiculo {
   id: string;
   name: string;
}
