import { Injectable } from '@angular/core';
import { HttpRequest, HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { OV } from '../models/ov';
import {environment} from '../../environments/environment';
import {NF} from '../models/nfe';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class NfeService {
  public token: string;

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  getAll(id: string): Observable<NF> {
    if (id === null) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // let params = new HttpParams().set("token",token);
    return this.http.get(base_url + 'nf/' + id, {headers: header})
               .map((response: NF) => {
                 // console.log(response);
                 const OVdata = response;
                 return OVdata;
               });
  }

    create(nf: NF): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'nf/', {nfe: nf}, {headers: header})
        .map((response) => {
            // console.log(response);
            const OVdata = response;
            return OVdata;
        });
    }

  attach(nfeid: number, file: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const param = new HttpParams().set('nfeid', String(nfeid));

    return this.http.post(base_url + 'nf/attachment', formData, {
      headers: header,
      params: param,
      reportProgress: true
    }).map( (response) => {
      return response;
    });
  }

  create_xml(nf: NF[], file: File[]): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('nfe', JSON.stringify(nf));
    file.forEach((subfile) => {
      formdata.append('upload', subfile);
    });

    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    // const param = new HttpParams().set('nfe', JSON.stringify(nf));

    return this.http.post(base_url + 'nf/xml', formdata, {
      headers: header,
      // params: param,
    });
  }

  getAll_xml(id: number): Observable<NF[]> {
    if (id === null) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'nf/xml/' + id, {
      headers: header,
    })
               .map((response: NF[]) => {
                 // console.log(response);
                 const OVdata = response;
                 return OVdata;
               });
  }
}
