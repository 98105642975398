import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SideNavService } from '../../services/sidenav.service';
import {Router} from '@angular/router';
import {DrService} from '../../providers/dr.service';
import {DatePipe} from '@angular/common';
import {MdrService} from '../../providers/mdr.service';
import {MatTableDataSource} from '@angular/material';
import {MDR} from '../../models/mdr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  role = 0;

  constructor(private sidenavService: SideNavService, private router: Router,
              private drService: DrService, private mdrService: MdrService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;

    if ( this.currentUser.user.role === 1 || this.currentUser.user.role === 3 ) {
      this.mdrService.haveNewOccurrence().subscribe( (response) => {
        this.haveNewOccurrence = response;
      });
    }
  }
  version: string;
  @Output() toggled = new EventEmitter<boolean>();
  currentUser: any = {token: '', user: ''};
  lastUpdate: Date;
  haveNewMDR = false;
  haveNewOccurrence = false;
  haveNewMDRLSP = false;

  searchValue: string;

  ngOnInit() {
    this.version = environment.VERSION;

    this.mdrService.getAllLSP().subscribe( (data) => {
      // console.log(data);
      if (data.length !== 0) {
        this.haveNewMDRLSP = true;
     }
    });
  }

  sideNavToggle() {
    this.toggled.emit(true);
  }
  search() {
    this.router.navigate(['ovsearch/' + this.searchValue]);
    this.searchValue = '';
  }

  goToHome() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const role = user.user.role;
    if (role === 1 || role === 3) {
      this.router.navigate(['cockpit']);
    } else if (role === 5) {
      this.router.navigate(['list-mdrs']);
    } else if (role === 7 ) {
      this.router.navigate(['finance-list']);
    } else if (role === 9 ) {
      this.router.navigate(['cockpit']);
    } else if (role === 2 ) {
      this.router.navigate(['new-delivery-request']);
    }
  }

  goToNotification() {
    this.router.navigate(['occurrence-list']);
    this.toggled.emit(true);
  }
}
