import { OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';
var MdrChartComponent = /** @class */ (function () {
    function MdrChartComponent(translate) {
        this.translate = translate;
        this.noData = false;
    }
    MdrChartComponent.prototype.ngOnInit = function () { };
    MdrChartComponent.prototype.createChart = function (data) {
        if (data.length > 0) {
            this.noData = false;
            var series_1 = [];
            data.forEach(function (element) {
                var status = element.status;
                if (element.status === '' || element.status === null) {
                    status = 'Sem status';
                }
                series_1.push({ name: status, type: undefined, data: [element.count] });
            });
            this.chart = new Chart({
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        format: 'Shipping Company'
                    }
                },
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.series.name + '</b>: ' + this.y;
                    }
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: series_1
            });
        }
        else {
            this.noData = true;
        }
    };
    return MdrChartComponent;
}());
export { MdrChartComponent };
