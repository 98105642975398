import { OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';
var OccurrenceChartComponent = /** @class */ (function () {
    function OccurrenceChartComponent(translate) {
        this.translate = translate;
        this.noData = false;
    }
    OccurrenceChartComponent.prototype.ngOnInit = function () {
    };
    OccurrenceChartComponent.prototype.createChart = function (data) {
        var _this = this;
        if (data.length > 0) {
            this.noData = false;
            var series = data.map(function (op) {
                return { name: _this.translate.instant(op.occ_status), type: undefined, data: [op.count] };
            });
            this.chart = new Chart({
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        format: 'Status'
                    }
                },
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.series.name + ':</b> ' + this.y;
                    }
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: series
            });
        }
        else {
            this.noData = true;
        }
    };
    return OccurrenceChartComponent;
}());
export { OccurrenceChartComponent };
