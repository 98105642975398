import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatSnackBar, MatDatepicker } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from '../../providers/report.service';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import * as XLSX from 'xlsx';
import { ConfigurationService } from '../../providers/configuration.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
    @ViewChild(MatDatepicker) start: MatDatepicker<Date>;
    @ViewChild(MatDatepicker) end: MatDatepicker<Date>;

    startDate: Date = null;
    endDate: Date = null;
    role: number;

    minDate = new Date(2000, 0, 1);
    maxDate = new Date(2021, 0, 1);

    reportList = [
        { type: 'LSP', name: 'LSP' },
        { type: 'LSPKPI', name: 'LSP KPI' },
        { type: 'perfLSP', name: 'Performance' },
        { type: 'operation', name: 'Operação' },
        { type: 'cockpit', name: 'MDRs e DRs' },
        { type: 'stakeholders', name: 'Stakeholders' },
        { type: 'cmdr', name: 'CMDR' },
        { type: 'cov', name: 'COV' },
        { type: 'poddp', name: 'POD DP' },
        { type: 'hub', name: 'Hub' },
        { type: 'dpunion', name: 'DP Union' },
        { type: 'uoc', name: 'UOC' },
    ];
    report = 'LSP';

    constructor(public translate: TranslateService,
        public reportService: ReportService,
        public excel: ExcelService,
        public router: Router,
        public configService: ConfigurationService,
        public snackBar: MatSnackBar) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = currentUser.user.role;
    }

    ngOnInit() {
        if (this.role !== 1) {
            this.reportList = [{ type: 'stakeholders', name: 'Stakeholders' }];
        }
    }

  checkAvailability(report: string) {
    return new Observable((observer) => {
      this.configService.getConfiguration('reports', true).subscribe(configuration => {
        // console.log(report, configuration[report], configuration);
        if ( report in configuration && 'available' in configuration[report]) {
          // se o relatório existe na configuração
          //   => retorna sua disponibilidade
          observer.next(configuration[report].available);
        } else {
          // caso contrário
          //   => permite baixar
          observer.next(true);
        }
      }, (err) => {
        // não existe esta configuração/restrição
        //   => permite baixar
        observer.next(true);
      });
    });
  }

    async download() {
        if (!this.startDate || !this.endDate) {
            this.snackBar.open('Selecione as datas a consultar', 'Fechar', {
                duration: 2000,
            });
            return;
        }
      this.checkAvailability(this.report).subscribe((available) => {
        if (available === true) {
          this.reportService.getReport(this.startDate, this.endDate, this.report).subscribe(data => {
                    let workbook: XLSX.WorkBook;
                    let sheets;
                    let names;
            // console.log(this.report);
                    switch (this.report) {
                        case 'stakeholders': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets =  {  'stake': worksheet};
                            names = ['stake'];
                            break;
                        }
                        case 'lsp': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets =  {  'LSP': worksheet};
                            names = ['LSP'];
                            break;
                        }
                        case 'LSPKPI': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets =  {  'KPI': worksheet};
                            names = ['KPI'];
                            break;
                        }
                        case 'perfLSP': {
                            sheets = {};
                            names = [];
                            for (const title of Object.keys(data)) {
                              // console.log(title);
                                sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                    skipHeader: true
                                });
                                names.push(title);
                            }
                            break;
                        }
                        case 'operation': {
                            sheets = {};
                            names = [];
                            for (const title of Object.keys(data)) {
                                sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                    skipHeader: true
                                });
                                names.push(title);
                            }
                            break;
                        }
                        case 'bat': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets =  {  'LSP': worksheet};
                            names = ['LSP'];
                            break;
                        }
                        case 'cmdr': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets =  {  'CMDR': worksheet};
                            names = ['CMDR'];
                            break;
                        }
                        case 'cov': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets =  {  'COV': worksheet};
                            names = ['COV'];
                            break;
                        }
                        case 'poddp': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets =  {  'PODDP': worksheet};
                            names = ['PODDP'];
                            break;
                        }
                      case 'hub': {
                        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                            skipHeader: true
                        });
                            sheets =  {  'Hub': worksheet};
                            names = ['Hub'];
                            break;
                        }
                        case 'dpunion': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets =  { 'DPUnion': worksheet };
                            names = ['DPUnion'];
                            break;
                        }
                        case 'uoc': {
                            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                                skipHeader: true
                            });
                            sheets =  { 'UOC': worksheet };
                            names = ['UOC'];
                            break;
                        }
                        case 'cockpit': {
                            sheets = {};
                            names = [];
                            for (const title of Object.keys(data)) {
                                sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                    skipHeader: true
                                });
                                names.push(title);
                            }
                            break;
                        }
                    }
                    workbook = { Sheets: sheets, SheetNames: names };
            // console.log(sheets);
            // console.log(names);
            // console.log(workbook);
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, this.report);
                });
            } else {
                this.configService.getConfiguration('reports', true).subscribe();
                this.snackBar.open('Estamos em manutenção, por favor tente em outro momento', 'Fechar', {
                    duration: 2000,
                });
            }
        });
    }
}
