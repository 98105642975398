import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import {DrService} from '../../providers/dr.service';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExcelService} from '../../misc/export-xlsx/export-xlsx';
import { PAYMENT_STATUS } from '../../models/payment';

@Component({
  selector: 'app-finance-list',
  templateUrl: './finance-list.component.html',
  styleUrls: ['./finance-list.component.scss']
})

export class FinanceListComponent implements OnInit {
  payments: any[] = [];
  data: any[] = [];
  listPay: any[] = [];
  role: number;

  columns = [
    {value: 'status', name: 'FINANCE_LIST/table/status'},
    {value: 'client', name: 'FINANCE_LIST/table/client'},
    {value: 'shippingCompany', name: 'FINANCE_LIST/table/lsp'},
    {value: 'mdrPickupId', name: 'FINANCE_LIST/table/mdr'},
    {value: 'prolog', name: 'FINANCE_LIST/table/prolog'},
    {value: 'costProject', name: 'FINANCE_LIST/table/costProject'},
    {value: 'OV', name: 'FINANCE_LIST/table/ov'},
    {value: 'type', name: 'FINANCE_LIST/table/type'},
    {value: 'origem', name: 'FINANCE_LIST/table/origem'},
    {value: 'currency', name: 'FINANCE_LIST/table/value', currency: 'BRL'},
    {value: 'createdAt', name: 'FINANCE_LIST/table/date'}
  ];

  constructor(public dialog: MatDialog,
              private mdrService: MdrService,
              public excelService: ExcelService,
              private drService: DrService,
              public router: Router,
              public snackBar: MatSnackBar,
              public dpipe: DatePipe) {
    this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
  }

  ngOnInit() { }

  cleanData(data: any) {
    this.payments = [];
   //// console.log(data);
    data.nf.forEach( (el, i, arr) => {
      this.payments.push({
        paymentId: {
          type: 'nf',
          idPay: el.referenceNoNf
        },
        clientGroup: el.customerName ,
        lsp: ('mdr' in el) ? el.mdr.shippingCompany : null,
        mdr: ('mdr' in el) ? el.mdr.id : 0,
        ov: el.salesDocument,
        nf: el.referenceNoNf,
        prolog: el.assignmentInformation,
      ccNet: ('pro' in el) ? el.pro.net + ' ' + el.pro.frete : null,
        sap: ('mdr' in el) ? el.mdr.sapCode : null,
        extraCost: 'Frete',
        total: parseFloat(el.price.total),
        status: ('payment' in el) ? el.payment.status : 'pending',
        romaneio: ('mdr' in el && el.mdr.pickupID !== null) ? el.mdr.pickupID : ('EDB-TP-' + ( '00000000' ).slice(-6)),
        statusPT: ('payment' in el) ? (el.payment.status === 'paid' ? 'Pago' : 'Pendente') : 'Pendente'
      });
    });
    data.nfe.forEach( (el, i, arr) => {
      this.payments.push({
        paymentId: {
          type: 'nf',
          idPay: el.invoiceNumber
        },
        clientGroup: ('client' in el) ? el.client.clientGroup : null,
        lsp: ('mdr' in el) ? el.mdr.shippingCompany : null,
        mdr: ('mdr' in el) ? el.mdr.id : 0,
        ov: null,
        nf: el.invoiceNumber,
        extraCost: 0,
        total: el.totalValue / 100,
        status: ('payment' in el) ? el.payment.status : 'pending',
        romaneio: ('mdr' in el && el.mdr.pickupID !== null) ? el.mdr.pickupID : ('EDB-TP-' + ( '00000000' ).slice(-6)),
        statusPT: ('payment' in el) ? (el.payment.status === 'paid' ? 'Pago' : 'Pendente') : 'Pendente'
      });
      data.forEach( (k) => {
        k.currency = k.value / 100;
      });
      this.data = data;
    });
  }

  insertPaymentFromFile(fileupload: any) {
   // console.log(fileupload);
    // console.log(fileupload);
    const input = fileupload.target;
    // console.log(fileupload);
    // this.paymentList = [];
    for (let index = 0; index < input.files.length; index++) {
      this.excelService.importFromExcel(fileupload).subscribe( (data) => {
        data.forEach( (el, i, arr) => {
          const payment = {};
         // console.log(el);
          try {
            payment[''] = el.pickup_address;
            // console.log(drAux.status);

          } catch (ex) {
            // console.log('erro ao ler arquivo xlsx ',ex);
            this.snackBar.open('Erro ao ler arquivo', 'Ok', {duration: 4000});
          }

        });
      });
    }
  }

  updateFromFile(fileupload: any) {
    const input = fileupload.target;
    this.excelService.importFromExcel(fileupload).subscribe( (data) => {
      // console.log(data);
      const avisos = [];
      const checkRow = (row) => {
        // status é válido
        if ( 'status' in row
          && !Object.values(PAYMENT_STATUS).includes(row['status']) ) {
          avisos.push(`Linha ${row.__rowNum__} contém status inválido`);
          return false;
        }
        // campos necessários estão presentes
        if ( !('origemId' in row)
          || !('invoice' in row)
          || !('OV' in row)
          || !('origem' in row)) {
          avisos.push(`Linha ${row.__rowNum__} não contém campos obrigatórios [origemId,invoice,OV,origem]`);
          return false;
        }
        // linha já existe, portanto pode ser atualizada
        if ( !this.data.find((el) => {
          return (
            // tslint:disable-next-line:triple-equals
            el['origemId'] == row['origemId']
            // tslint:disable-next-line:triple-equals
            && el['invoice'] == row['invoice']
            // tslint:disable-next-line:triple-equals
            && el['OV'] == row['OV']
            // tslint:disable-next-line:triple-equals
            && el['origem'] == row['origem']
          );
        }) ) {
          avisos.push(`Linha ${row.__rowNum__} não existe no sistema`);
          return false;
        }
        return true;
      };
      // se todas as linhas recebidas são válidas
      if ( data.every(checkRow) ) {
        // atualiza as linhas
        const total = data.length;
        // console.log(total);
        this.mdrService.updatePayment(data).subscribe((response) => {
          this.snackBar.open('Pagamentos atualizados com sucesso', 'OK', { duration: 4000 });
        }, (error) => {
          if ( 'status' in error ) {
            this.snackBar.open('Erro ao subir arquivo, verifique que todas as linhas já existem no sistema.', 'OK', { duration: 4000 });
          } else {
            this.snackBar.open('Estamos ainda atualizando, por favor, aguarde mais 5 minutos.', 'OK', { duration: 4000 });
          }
        });
      } else {
        this.snackBar.open('Arquivo contém linhas inválidas', 'OK', { duration: 4000 });
        // mostra avisos gerados
        for (let i = 0, len = avisos.length ; i < len ; i++) {
          setTimeout(() => {
            this.snackBar.open(avisos[i], 'OK', { duration: 2000 });
          }, 2000 * (i + 1));
        }
      }
    });
  }

  goToMDR(row, that) {
    if ( row.mdrId !== 0 ) {
      that.router.navigate(['logistic-order/' + row.mdrId]);
    } else {
      that.snackBar.open('Não há MDR associada a este item', 'Ok', { duration: 4000 });
    }
  }

  addList(listPayments) {
    this.listPay = listPayments;
  }

  payNF() {
    this.mdrService.payNF(this.listPay).subscribe( (response) => {
      this.snackBar.open('Pagamento realizado corretamente', 'Ok', { duration: 4000 });
    });
  }

  remove() {
    let removed = 0;
    const total = this.listPay.length;
    this.listPay.forEach( payment => {
      this.mdrService.removePayment(payment.id).subscribe((response) => {
        removed++;
        this.snackBar.open('PO (' + removed + '/' + total + ') removido', 'OK', { duration: 4000 });
        const paymentI = this.data.findIndex(e => e.id === payment.id);
        if (paymentI >= 0) {
          this.data.splice(paymentI, 1);
        }
      }, (error) => {
        removed++;
        this.snackBar.open('PO (' + removed + '/' + total + ') falhou remoção', 'OK', { duration: 4000 });
      });
    });
  }

}
