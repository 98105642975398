import { OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { COPQMDR } from '../../../models/copq__mdr';
import { TranslateService } from '@ngx-translate/core';
import { PriceObservable } from '../../../services/price.service';
import { MdrService } from '../../../providers/mdr.service';
import { MdrObservable } from '../../../services/mdr.service';
import { UOCObservable } from '../../../services/uoc.service';
import { ActivatedRoute } from '@angular/router';
import { MDR } from '../../../models/mdr';
import { CostAvoidanceMDR } from '../../../models/costAvoidance__mdr';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
import * as XLSX from 'xlsx';
var TaxComponent = /** @class */ (function () {
    function TaxComponent(translate, snackBar, priceObservable, mdrService, mdrObservable, uocObservable, aRoute, excel, dpipe, cpipe, dialog) {
        this.translate = translate;
        this.snackBar = snackBar;
        this.priceObservable = priceObservable;
        this.mdrService = mdrService;
        this.mdrObservable = mdrObservable;
        this.uocObservable = uocObservable;
        this.aRoute = aRoute;
        this.excel = excel;
        this.dpipe = dpipe;
        this.cpipe = cpipe;
        this.dialog = dialog;
        this.statusContainer = false;
        this.mdr = new MDR;
        this.sumCOPQ = 0;
        this.sumAvoid = 0;
        this.sumUOC = 0;
        this.sumTotal = 0;
        this.COPQs = [];
        this.costAvoidances = [];
        this.UOCs = [];
        this.uoc = [];
        this.copq = [];
        this.ov = [];
        this.update = true;
        this.payment = {
            'tooltips': {
                'green': 'Pagamento Realizado',
                'yellow': 'Pagamento Parcial Realizado',
                'red': 'Aguardando Pagamento',
                'undefined': '',
            },
            'icons': {
                'green': 'fa-check',
                'yellow': 'fa-exclamation-triangle',
                'red': 'fa-exclamation-triangle',
                'undefined': '',
            },
            'classes': {
                'green': 'icon-green',
                'yellow': 'icon-yellow',
                'red': 'icon-red',
                'undefined': '',
            }
        };
        this.payField = {
            'color': 'undefined',
            'ov': []
        };
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.user = this.currentUser.user.id;
    }
    TaxComponent.prototype.ngOnInit = function () {
        var _this = this;
        // pega price
        this.priceObservable.currentPrice.subscribe(function (price) {
            _this.price = price;
        });
        // quando a MDR mudar
        this.mdrObservable.currentMDR.subscribe(function (mdr) {
            _this.mdr = mdr;
            _this.sumTotal = mdr.valorem + mdr.frete + mdr.additional;
            // pega os CoPQs da MDR
            _this.mdrService.getCoPQ(_this.mdr).subscribe(function (data) {
                _this.sumCOPQ = 0;
                data.forEach(function (elem) {
                    elem.value = Number(elem.value) / 100;
                    _this.sumCOPQ += elem.value;
                    elem.value = elem.value.toFixed(2);
                });
                // this.sumCOPQ = this.sumCOPQ / 100;
                _this.COPQs = data;
            });
            // pega os CostAvoidances da MDR
            _this.mdrService.getCostAvoidance(_this.mdr).subscribe(function (data) {
                _this.sumAvoid = 0;
                data.forEach(function (elem) {
                    _this.sumAvoid += Number(elem.value);
                });
                _this.costAvoidances = data;
            });
            // pega a lista de UOCs possíveis
            _this.mdrService.getListUOC().subscribe(function (data) {
                _this.uoc = data;
            });
            // pega a lista de CoPQs possíveis
            _this.mdrService.getListCoPQ().subscribe(function (data) {
                _this.copq = data;
            });
            // quando os UOCs mudarem
            _this.mdrService.getUOC(_this.mdr).subscribe(function (data) {
                _this.sumUOC = 0;
                data.forEach(function (elem) {
                    elem.value = Number(elem.value) / 100;
                    _this.sumUOC += elem.value;
                    elem.value = Number(elem.value.toFixed(2));
                });
                _this.sumUOC /= 100;
                _this.UOCs = data;
                // pega os Payments
                _this.mdrService.getStatusPayment(_this.mdr.id).subscribe(function (payments) {
                    var frete_count = 0;
                    var frete_paid = 0;
                    var _loop_1 = function (i) {
                        if (payments[i].origem === 'FRETE') {
                            frete_count = frete_count + 1;
                            if (payments[i].status === 'PAID') {
                                frete_paid = frete_paid + 1;
                            }
                        }
                        else { // pra origem='UOC'
                            var uoc = _this.UOCs.find(function (u) { return (u.id === parseInt(payments[i].origemId, 10)); });
                            if (!uoc) {
                                // pagamento nao está presente na lista de pagamentos
                            }
                            else if (payments[i].status === 'PAID') {
                                uoc['color'] = 'green';
                            }
                            else if (payments[i].status === 'READY_TO_PAY') {
                                uoc['color'] = 'red';
                            }
                            else {
                                uoc['color'] = 'undefined';
                            }
                        }
                    };
                    // itera sobre os pagamentos
                    for (var i = 0; i < payments.length; i++) {
                        _loop_1(i);
                    }
                    if (frete_count === frete_paid) {
                        _this.payField.color = 'green';
                    }
                    else if (frete_paid === 0) {
                        _this.payField.color = 'red';
                    }
                    else {
                        _this.payField.color = 'yellow';
                    }
                });
            });
        });
    };
    TaxComponent.prototype.removeUOC = function (uoc) {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response[0] === true) {
                _this.mdrService.removeOccurrence(uoc).subscribe(function (elem) {
                    _this.mdrService.getUOC(_this.mdr).subscribe(function (data) {
                        _this.snackBar.open('Custo deletado corretamente', 'Ok', {
                            duration: 4000,
                        });
                        _this.sumUOC = 0;
                        data.forEach(function (item) {
                            item.value = Number(item.value) / 100;
                            _this.sumUOC += item.value;
                            item.value = item.value.toFixed(2);
                        });
                        _this.sumUOC /= 100;
                        _this.UOCs = data;
                    });
                });
            }
        });
    };
    TaxComponent.prototype.removeCOPQ = function (copq) {
        var _this = this;
        // console.log(copq);
        var dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response[0] === true) {
                _this.mdrService.removeOccurrenceCOPQ(copq).subscribe(function (elem) {
                    _this.mdrService.getCoPQ(_this.mdr).subscribe(function (data) {
                        _this.snackBar.open('Custo deletado corretamente', 'Ok', {
                            duration: 4000,
                        });
                        _this.sumCOPQ = 0;
                        data.forEach(function (item) {
                            item.value = Number(item.value) / 100;
                            _this.sumCOPQ += item.value;
                            item.value = item.value.toFixed(2);
                        });
                        _this.sumCOPQ /= 100;
                        _this.COPQs = data;
                    });
                });
            }
        });
    };
    TaxComponent.prototype.sanitizeInputMdr = function (field, value, tag) {
        var input = value.srcElement.value;
        this.update = false;
        input = input.replace('R$', '');
        input = input.replace(/ /g, '');
        input = input.replace(/\./g, '');
        input = input.replace(',', '.');
        input = input.replace(/[^\d.]/g, '');
        this.mdr[field] = input;
        this.update = true;
    };
    TaxComponent.prototype.sanitizeInputPrice = function (field, value, tag) {
        var input = value.srcElement.value;
        this.update = false;
        input = input.replace('R$', '');
        input = input.replace(' ', '');
        input = input.replace('.', '');
        input = input.replace(',', '.');
        input = input.replace(/[^\d.]/g, '');
        this.price[field] = input;
        this.update = true;
    };
    TaxComponent.prototype.amountCOPQ = function () {
        var _this = this;
        this.sumCOPQ = 0;
        this.COPQs.forEach(function (elem) {
            _this.sumCOPQ += Number(elem.value);
        });
        return (this.sumCOPQ);
    };
    TaxComponent.prototype.amountCA = function () {
        var _this = this;
        this.sumAvoid = 0;
        this.costAvoidances.forEach(function (elem) {
            _this.sumAvoid += Number(elem.value);
        });
        return this.sumAvoid;
    };
    TaxComponent.prototype.saveConveyor = function () {
        this.mdrService.update(this.mdr, this.user).subscribe(function (response) {
            // console.log(response);
        });
    };
    TaxComponent.prototype.savePrice = function () {
        // console.log(this.price);
        this.mdrService.savePrice(this.price, this.mdr).subscribe(function (data) {
            // console.log('teste');
        });
    };
    TaxComponent.prototype.getTotal = function () {
        return this.price.total;
    };
    TaxComponent.prototype.saveUOC = function () {
    };
    TaxComponent.prototype.saveCOPQ = function () {
        var _this = this;
        this.COPQs.forEach(function (copq) {
            _this.mdrService.createCoPQ(copq).subscribe(function (response) {
                // console.log(response);
                _this.mdrService.getCoPQ(_this.mdr).subscribe(function (data) {
                    _this.sumCOPQ = 0;
                    data.forEach(function (elem) {
                        _this.sumCOPQ += Number(elem.value);
                    });
                    _this.COPQs = data;
                });
            });
        });
    };
    TaxComponent.prototype.getAllPriceWithKm = function () {
        var _this = this;
        this.mdrService.getAllPriceWithKm(this.mdr).subscribe(function (data) {
            _this.price = data;
        });
    };
    TaxComponent.prototype.saveCA = function () {
        var _this = this;
        this.costAvoidances.forEach(function (costAv) {
            _this.mdrService.createCostAvoidance(costAv).subscribe(function (response) {
                // console.log(response);
                _this.mdrService.getCostAvoidance(_this.mdr).subscribe(function (data) {
                    _this.sumAvoid = 0;
                    data.forEach(function (elem) {
                        _this.sumAvoid += Number(elem.value);
                    });
                    _this.costAvoidances = data;
                });
            });
        });
    };
    TaxComponent.prototype.amountUOC = function () {
        var _this = this;
        this.sumUOC = 0;
        this.UOCs.forEach(function (elem) {
            _this.sumUOC += Number(elem.value) * Number(elem['multiplicator']);
        });
        return this.sumUOC;
    };
    TaxComponent.prototype.amountTotal = function () {
        this.sumTotal = Number(this.mdr.valorem) + Number(this.mdr.valorem) + Number(this.mdr.additional);
        return this.sumTotal;
    };
    TaxComponent.prototype.addCOPQ = function () {
        this.COPQs.push(new COPQMDR);
        this.COPQs[this.COPQs.length - 1].idMDR = this.mdr.id;
    };
    TaxComponent.prototype.addCA = function () {
        this.costAvoidances.push(new CostAvoidanceMDR);
        this.costAvoidances[this.costAvoidances.length - 1].idMDR = this.mdr.id;
    };
    TaxComponent.prototype.toggleClass = function () {
        if (this.statusContainer === true) {
            this.statusContainer = false;
        }
        else {
            this.statusContainer = true;
        }
    };
    TaxComponent.prototype.download = function () {
        var json = [];
        json.push({
            0: 'MDR ID',
            1: 'Veiculo',
            2: 'Data de coleta',
            3: 'Total NF subida',
            4: 'KM',
            5: 'Total',
            6: 'Tipo',
            7: 'Obs'
        });
        json.push({
            0: this.mdr.pickupID,
            1: this.mdr.vehicle,
            2: this.dpipe.transform(this.mdr.pickupDate, 'dd/MM/yyyy hh:mm'),
            3: this.cpipe.transform(this.nfePrice, 'BRL'),
            4: this.mdr.totalKM,
            5: this.cpipe.transform(this.price.total, 'BRL'),
            6: 'Frete',
            7: ''
        });
        for (var _i = 0, _a = this.UOCs; _i < _a.length; _i++) {
            var u = _a[_i];
            json.push({
                0: this.mdr.pickupID,
                1: this.mdr.vehicle,
                2: this.dpipe.transform(this.mdr.pickupDate, 'dd/MM/yyyy hh:mm'),
                3: this.cpipe.transform(this.nfePrice, 'BRL'),
                4: this.mdr.totalKM,
                5: this.cpipe.transform(u.value, 'BRL'),
                6: 'UOC',
                7: u.uoc
            });
        }
        var worksheet = XLSX.utils.json_to_sheet(json, {
            skipHeader: true
        });
        var workbook = { Sheets: { 'taxes': worksheet }, SheetNames: ['taxes'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.excel.saveAsExcelFile(excelBuffer, 'taxes');
    };
    return TaxComponent;
}());
export { TaxComponent };
var ConfirmDeleteDialogComponent = /** @class */ (function () {
    function ConfirmDeleteDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    ConfirmDeleteDialogComponent.prototype.ngOnInit = function () {
    };
    return ConfirmDeleteDialogComponent;
}());
export { ConfirmDeleteDialogComponent };
