import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { User } from '../models/user';
import { of, Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { OV } from '../models/ov';
import { environment } from '../../environments/environment';
import { DR } from '../models/dr';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class DashboardService {
  private token: string;
  private header;
  private url: string;

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    this.url = base_url + 'statistics/';
    this.token = JSON.parse(localStorage.getItem('currentUser')).token;
    this.header = new HttpHeaders().set('Authorization', 'Bearer ' + this.token).set('x-api-key', api_key);

  }
  getMdrByShippingCompany() {
    return this.http.get(this.url + 'mdr/shippingCompany', {headers: this.header });
  }

  getMdrByStatus() {
    return this.http.get(this.url + 'mdr/status         ', { headers: this.header });
  }

  getDrByStatus() {
    return this.http.get(this.url + 'dr/status', { headers: this.header });
  }


  getDrByDriverPosition() {
    return this.http.get(this.url + 'dr/driverPosition', { headers: this.header });
  }

  getOccurrence() {
    return this.http.get(this.url + 'occurrence', { headers: this.header });
  }

  getPods() {
    return this.http.get(this.url + 'pod', { headers: this.header });
  }

  getCostKg() {
    return this.http.get(this.url + 'mdr/costPerKilo', { headers: this.header });
  }
}
