import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Occurrence, OCCURRENCE_STATUS } from '../../../models/occurrence';
import { MDR } from '../../../models/mdr';
import { MdrService } from '../../../providers/mdr.service';
import { UOCMDR } from '../../../models/uoc__mdr';
import { UOC } from '../../../models/uoc';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DrService } from '../../../providers/dr.service';
import { DrObservable } from '../../../services/dr.service';
import { UOCObservable } from '../../../services/uoc.service';
import { MdrObservable } from '../../../services/mdr.service';
import { DomSanitizer } from '@angular/platform-browser';
import { COPQMDR } from '../../../models/copq__mdr';
import { UploadFileService } from '../../../providers/upload.service';
import { environment } from '../../../../environments/environment';
var OccurrenceComponent = /** @class */ (function () {
    function OccurrenceComponent(dialog, mdrService, snackBar, aRoute, drService, drObservable, mdrObservable, uploadService, sanitizer) {
        var _this = this;
        this.dialog = dialog;
        this.mdrService = mdrService;
        this.snackBar = snackBar;
        this.aRoute = aRoute;
        this.drService = drService;
        this.drObservable = drObservable;
        this.mdrObservable = mdrObservable;
        this.uploadService = uploadService;
        this.sanitizer = sanitizer;
        this.base_url = environment.apiUrl;
        this.dataOccurrence = new MatTableDataSource();
        this.columnsToDisplay = ['uoc', 'text', 'status'];
        this.myControl = new FormControl();
        this.drList = [];
        this.occurrence = new Occurrence();
        this.role = 0;
        this.mdr = new MDR;
        this.uocColumns = ['uoc', 'value', 'observation'];
        this.uoc = [];
        this.sumTotal = 0;
        this.data = [];
        this.copq = [];
        this.dataSource = new MatTableDataSource(this.data);
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.mdrObservable.currentMDR.subscribe(function (mdr) {
            _this.mdr = mdr;
            _this.drObservable.currentDR.subscribe(function (dr) {
                _this.drList = dr;
            });
            _this.getListOccurrence();
        });
        this.mdrService.getListCoPQ().subscribe(function (copq) {
            _this.copq = copq;
        });
        this.mdrService.getOccurrenceEnum().subscribe(function (data) {
            _this.uoc = data.map(function (e) {
                var u = new UOC();
                u.id = e.id;
                u.name = e.occurrence;
                return u;
            });
        });
    }
    OccurrenceComponent.prototype.ngOnInit = function () {
    };
    OccurrenceComponent.prototype.createOccurrence = function () {
        var _this = this;
        this.occurrence.idMDR = this.mdr.id;
        // console.log(this.occurrence);
        this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
        if (this.occurrence.uoc === 'Status') {
            this.occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED;
        } // gambiarra, remover, corrigir TODO DEBUG
        this.mdrService.createOccurrence(this.occurrence).subscribe(function (response) {
            if (response !== undefined) {
                _this.snackBar.open('Ocorrência criada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.getListOccurrence();
            }
        });
    };
    OccurrenceComponent.prototype.getListOccurrence = function () {
        var _this = this;
        this.mdrService.getOccurences(this.mdr).subscribe(function (data) {
            if (_this.role === 1) {
                data = data.filter(function (occurrence) { return occurrence.status !== OCCURRENCE_STATUS.SENT_TO_LSP; })
                    .filter(function (occurrence) { return occurrence.status !== OCCURRENCE_STATUS.OCCURRENCE_DENIED; });
            }
            var _loop_1 = function (j) {
                _this.uploadService.findByEntity(data[j].id, 'occurrence').subscribe(function (ans) {
                    if (ans.length === 0) {
                        data[j].file = undefined;
                    }
                    else {
                        data[j].file = ans;
                    }
                });
            };
            for (var j = 0; j < data.length; j++) {
                _loop_1(j);
            }
            _this.dataOccurrence = new MatTableDataSource(data);
        });
    };
    OccurrenceComponent.prototype.approveOccurrence = function (occurrence) {
        var _this = this;
        occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_TO_RESOLVE;
        this.mdrService.updateOccurrence(occurrence).subscribe(function (response) {
            if (response !== undefined) {
                _this.snackBar.open('Ocorrência enviada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.getListOccurrence();
            }
        });
    };
    OccurrenceComponent.prototype.receiveFile = function ($event) {
        this.getListOccurrence();
    };
    OccurrenceComponent.prototype.resolveOccurrence = function (occurrence) {
        var _this = this;
        occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_RESOLVED;
        this.mdrService.updateOccurrence(occurrence).subscribe(function (response) {
            if (response !== undefined) {
                _this.snackBar.open('Ocorrência atualizada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.getListOccurrence();
            }
        });
    };
    OccurrenceComponent.prototype.removeOccurrence = function (element) {
        var _this = this;
        this.mdrService.deleteOccurrence(element).subscribe(function (data) {
            _this.getListOccurrence();
        });
    };
    OccurrenceComponent.prototype.isArray = function (val) { return typeof val === 'object'; };
    OccurrenceComponent.prototype.getSafeURL = function (element) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.base_url + element.fileName);
    };
    OccurrenceComponent.prototype.getUOCValue = function () {
        var _this = this;
        this.mdrService.getUOCValue(this.occurrence, this.mdr).subscribe(function (data) {
            _this.occurrence.value = data;
        });
    };
    OccurrenceComponent.prototype.openDialog = function (occurrence) {
        var _this = this;
        var dialogRef = this.dialog.open(AcceptOccurrenceDialogComponent, {
            width: '600px',
            data: {
                text: '',
                mdr: this.mdr,
                copq: this.copq,
                drList: this.drList,
                occ: occurrence,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            // console.log(response);
            if (response !== undefined) {
                occurrence.resolution = response;
                occurrence.updatedAt = new Date();
                _this.resolveOccurrence(occurrence);
            }
        });
    };
    OccurrenceComponent.prototype.rejectOccurrence = function (occurrence) {
        var _this = this;
        occurrence.status = OCCURRENCE_STATUS.OCCURRENCE_DENIED;
        this.mdrService.updateOccurrence(occurrence).subscribe(function (response) {
            if (response !== undefined) {
                _this.snackBar.open('Ocorrência negada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.getListOccurrence();
            }
        });
    };
    return OccurrenceComponent;
}());
export { OccurrenceComponent };
var AcceptOccurrenceDialogComponent = /** @class */ (function () {
    function AcceptOccurrenceDialogComponent(dialogRef, mdrService, uocObservable, snackBar, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.mdrService = mdrService;
        this.uocObservable = uocObservable;
        this.snackBar = snackBar;
        this.data = data;
        this.isUOC = false;
        this.isCOPQ = false;
        this.totalValue = 0;
        this.uocMult = 1;
        this.copqFinalValue = 0;
        this.copqMult = 1;
        this.valueCOPQ = 0;
        this.myControl = new FormControl();
        // pega UOCs possíveis
        this.mdrService.getListUOC().subscribe(function (response) {
            _this.uoc = response;
        });
        this.data.needsAttachment = false;
        this.copq = data.copq;
        this.drList = data.drList;
        this.occ = data.occ;
        this.uocSelected = this.occ.uoc;
    }
    AcceptOccurrenceDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        // se é UOC
        if (this.isUOC === true) {
            if (this.myControl.value === null) {
                this.snackBar.open('Preencha pelo menos uma DR', 'Ok', {
                    duration: 4000,
                });
                return;
            }
            // nao permite criar UOC com value 0
            if ((this.valueUOC * this.uocMult) === 0 || isNaN(this.valueUOC * this.uocMult)) {
                this.snackBar.open('Não é possível criar UOC sem valor', 'Ok', {
                    duration: 4000,
                });
                return;
            }
            var uoc = new UOCMDR();
            uoc.idMDR = this.data.mdr.id;
            uoc.value = this.valueUOC * this.uocMult * 100;
            uoc.uoc = this.uocSelected;
            uoc.needsAttachment = this.data.needsAttachment;
            uoc.idOccurrence = this.occ.id;
            for (var i = 0; i < this.myControl.value.length; i++) {
                for (var j = 0; j < this.drList.length; j++) {
                    if (this.drList[j].id === this.myControl.value[i]) {
                        uoc.drList.push(this.drList[j]);
                    }
                }
            }
            // cria a UOC
            this.mdrService.createUOC(uoc).subscribe(function (responseUOC) {
                // atualiza lista de UOCs da MDR
                _this.mdrService.getUOC(_this.data.mdr).subscribe(function (uocmdr) {
                    _this.uocObservable.changeUOC(uocmdr);
                });
                // se tambem é CoPQ
                if (_this.isCOPQ === true) {
                    var copq = new COPQMDR();
                    copq.idMDR = _this.data.mdr.id;
                    copq.value = _this.valueCOPQ * _this.copqMult * 100;
                    copq.copq = _this.copqSelected;
                    _this.mdrService.createCoPQ(copq).subscribe(function (responseCOPQ) {
                        // fecha dialog
                        _this.dialogRef.close(_this.data.text);
                    });
                }
                else { // nao é CoPQ
                    // fecha dialog
                    _this.dialogRef.close(_this.data.text);
                }
            });
        }
        else { // nao é UOC
            // fecha dialog
            this.dialogRef.close(this.data.text);
        }
    };
    AcceptOccurrenceDialogComponent.prototype.getUOCValue = function (elem) {
        var _this = this;
        // console.log('teste')
        this.mdrService.getUOCValue(elem, this.data.mdr).subscribe(function (data) {
            _this.valueUOC = data;
            // console.log(data);
        });
    };
    AcceptOccurrenceDialogComponent.prototype.onNoClick = function () {
        this.data = 0;
        this.dialogRef.close();
    };
    return AcceptOccurrenceDialogComponent;
}());
export { AcceptOccurrenceDialogComponent };
