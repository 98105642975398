import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {DrService} from '../../providers/dr.service';
import {DR} from '../../models/dr';
import {MDR} from '../../models/mdr';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';

import {OverviewExampleDialogComponent} from '../new-delivery-request/new-delivery-request.component';
import {ClientUserService} from '../../providers/client-user.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {TranslateService} from '@ngx-translate/core';
import { Occurrence } from '../../models/occurrence';
import { ConfigurationService } from '../../providers/configuration.service';

@Component({
  selector: 'app-tower-control',
  templateUrl: './tower-control.component.html',
  styleUrls: ['./tower-control.component.scss']
})
export class TowerControlComponent implements OnInit {
  data: any[] = [];
  role: number;
  icons = {
    'undefined': 'help',
    'red':       'error',
    'yellow':    'warning',
    'green':     'check_circle'
  };
  backupData: any[];

  columns = [
    {value: 'client', name: 'CONTROL_TOWER/table/client'},
    {value: 'pickupID', name: 'CONTROL_TOWER/table/mdr'},
    {value: 'icon', name: 'Status Card', icon: 'icon', tooltip: 'tooltip', color: this.styleIcon},
    {value: 'mdrShippingCompany', name: 'CONTROL_TOWER/table/lsp'},
    {value: 'site', name: 'CONTROL_TOWER/table/site'},
    {value: 'infraType', name: 'CONTROL_TOWER/table/infra'},
    {value: 'ops', name: 'CONTROL_TOWER/table/specialOps'},
    {value: 'salesOrders', name: 'CONTROL_TOWER/table/salesOrders'},
    {value: 'dateScheduling', name: 'CONTROL_TOWER/table/date'},
    {value: 'status', name: 'CONTROL_TOWER/table/status'},
    {value: 'occurrence', name: 'CONTROL_TOWER/table/occurrence'},
    {value: 'city', name: 'CONTROL_TOWER/table/city'},
    {value: 'uf', name: 'CONTROL_TOWER/table/uf'},
    {value: 'copqSum', name: 'CONTROL_TOWER/table/copq', currency: 'BRL'},
  ];

  constructor(public translate: TranslateService,
              public dialog: MatDialog,
              private mapsAPILoader: MapsAPILoader,
              public clientService: ClientUserService,
              private drService: DrService,
              private excelService: ExcelService,
              public router: Router,
              public snackBar: MatSnackBar,
              public configService: ConfigurationService,
              public dpipe: DatePipe) {
    this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
    this.configService.getConfiguration('tower-control').subscribe((response) => {
      this.columns = response['columns'];
      // this.advancedSearchColumns = this.columns.map(e => e.value);
    }, (error) => {
      // mantém as colunas pre-definidas
    });
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.drService.getTowerTPX().subscribe( (elem) => {
      elem.forEach( (k) => {
        if (k.header) {
          k.plantName = k.header.plantName;
          k.country = k.header.country;
          k.shipToPartyName = k.header.shipToPartyName;
          k.shippingTypeDescription = k.header.shippingTypeDescription;
        }
        if (k.mdr) {
          k.parsedCreatedAt = new Date(k.mdr.createdAt);
          k.sapCode = k.mdr.sapCode;
          k.SAD = k.mdr.SAD;
          k.lsp = k.mdr.shippingCompany;
          k.pickupID = k.mdr.pickupID;
          k.goTo = k.mdr.id + '';
          switch (k.mdr.status) {
            case 'MDR em criação':
              k.selectedIndex = 'Sent To LSP';
              break;
            case 'MDR aguardando LSP':
              k.selectedIndex = 'LSP Validation';
              break;
            case 'MDR aprovada LSP':
              k.selectedIndex = 'Insert Documents';
              break;
            case 'Motorista e veiculo definidos':
              k.selectedIndex = 'Expedition';
              break;
            case 'Veículo carregado':
              k.selectedIndex = 'In Transit';
              break;
            case 'Carga entregue com sucesso':
              k.selectedIndex = 'Delivered';
              break;
            case 'Entrega de carga certificada pelo LSP':
              k.selectedIndex = 'Done';
              break;
            default:
              k.selectedIndex = 'LSP Validation';
              break;
          }
        }
        if (k.occ !== undefined) {
          if (k.occ.length > 0) {
            let max = 0;
            let occAux = null;
            // console.log(k.occ.length);
            for (let i = 0; i < k.occ.length ; i++) {
              if (k.occ[i].id > max) {
                max = k.occ[i].id;
                occAux = i;
              }
            }
            k.occurrence = k.occ[occAux].uoc;
            k.occurrenceObservation = k.occ[occAux].text;
            k.occurrenceStatus = k.occ[occAux].status === 'OCCURRENCE_RESOLVED' ? 'CLOSED' : 'OPEN';
          } else {
            k.occurrence = '';
            k.occurrenceObservation = '';
            k.occurrenceStatus = '';
                      }
        } else {
          k.occurrence = '';
          k.occurrenceObservation = '';
          k.occurrenceStatus = '';
        }
      });
      // setta a table
      this.data = elem;
      this.backupData = this.data;
    });
  }

  getInfo(dr) {
    const tooltip = [];
    let color = 'green';
    const hora = 1000 * 60 * 60;
    const now = Date.now();

    // se está aguardando atualizações da LSP
    if ( dr.status === 'Waiting LSP' ) {
      if ( dr.updatedAt === null ) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if ( now - d.getTime() < 1 * hora ) {
          // se a última atualização ocorreu a menos de 1h
          color = 'green';
        } else if ( now - d.getTime() < 2 * hora ) {
          // se a última atualização ocorreu a menos de 2h
          color = 'yellow';
          tooltip.push('última atualização a mais de 1h');
        } else {
          // se a última atualização ocorreu a mais de 2h
          color = 'red';
          tooltip.push('última atualização a mais de 2h');
        }
      }
    }

    // se está pronto para coleta
    if ( dr.status === 'READY_TO_PICKUP' ) {
      if ( dr.mdr.pickupDate === null ) {
        color = 'undefined';
        tooltip.push('data de coleta ausente');
      } else {
        const d = new Date(dr.mdr.pickupDate);
        if ( d.getTime() - now > 1 * hora ) {
          // mais de 1h até a hora de coleta
          color = 'green';
        } else if ( d.getTime() - now > 0.5 * hora ) {
          // mais de 30min até a hora de coleta
          color = 'yellow';
          tooltip.push('menos de 1h até a hora de coleta');
        } else {
          // menos de 30min até a hora de coleta
          color = 'red';
          tooltip.push('menos de 30 minutos até a hora de coleta');
        }
      }
    }

    // se já foi entregue
    if ( dr.status === 'Delivered' ) {
      if ( dr.updatedAt === null ) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if ( now - d.getTime() < 6 * hora ) {
          // se a última atualização ocorreu a menos de 6h
          color = 'green';
        } else if ( now - d.getTime() < 12 * hora ) {
          // se a última atualização ocorreu a menos de 12h
          color = 'yellow';
          tooltip.push('última atualização a mais de 6h');
        } else {
          // se a última atualização ocorreu a mais de 12h
          color = 'red';
          tooltip.push('última atualização a mais de 12h');
        }
      }
    }

    if ( ['HubReturn',
          'Replan - Retido',
          'Replan - Sefaz',
          'Replan - Antecipação',
          'Replan - Postergação',
          'Replan - Pernoite'].indexOf(dr.status) !== -1 ) {
      if ( dr.updatedAt === null ) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if ( now - d.getTime() < 5 * 24 * hora ) {
          // se a última atualização ocorreu a menos de 5d
          color = 'green';
        } else if ( now - d.getTime() < 10 * 24 * hora ) {
          // se a última atualização ocorreu a menos de 10d
          color = 'yellow';
          tooltip.push('última atualização a mais de 5 dias');
        } else {
          // se a última atualização ocorreu a mais de 10d
          color = 'red';
          tooltip.push('última atualização a mais de 10 dias');
        }
      }
    }

    // verifica as ocorrencias da MDR
    let occStatus = 'SENT_TO_LSP';
    if ( this.role === 1 ) { occStatus = 'OCCURRENCE_TO_RESOLVE'; }
    if ( 'occurrences' in dr && dr.occurrences.length > 0 ) {
      const occurrencesNotSolved = [];
      for (let i = 0; i < dr.occurrences.length; i++ ) {
        if (dr.occurrences[i].status === occStatus ) {
          occurrencesNotSolved.push(dr.occurrences[i]);
        }
      }

      if (occurrencesNotSolved.length > 0) {
        const s = ((occurrencesNotSolved.length === 1) ? '' : 's');
        if (occurrencesNotSolved.some((el) => {
          const d = new Date(el.createdAt);
          return (now - d.getTime() > 2 * hora);
        })) {
          // se alguma ocorrencia foi criada mais de 2h atrás
          color = 'red';
          tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
        } else {
          // se alguma ocorrencia foi criada, e todas menos de 2h atrás
          color = (color === 'red') ? color : 'yellow';
          tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
        }
      }
    } // fim verificação ocorrencias

    return {
      color: color,
      tooltip: tooltip
    };
  }

  downloadReport() {
    const drs = [];
    this.data.forEach((elem) => {
      const card = elem;
      const dr = {};
      dr['shipment'] = card.shipmentNumber;
      dr['status'] = card.selectedIndex;
      dr['origin'] = card.plantName;
      dr['country'] = card.country;
      dr['destination'] = card.shipToPartyName;
      dr['MoT'] = card.shippingTypeDescription;
      dr['ETA'] = card.sapCode;
      dr['ATA'] = card.SAD;
      dr['Call-Off'] = card.pickupID;
      dr['occurrence'] = card.occurrence;
      dr['obs'] = card.occurrenceObservation;
      dr['occStatus'] = card.occurrenceStatus;
      drs.push(dr);
    });
    this.excelService.exportAsExcelFile(drs, 'tower_control');
  }

  getStatusColor(status) {
    const colors = {
      green: ['Delivered'],
      yellow: ['READY_TO_PICKUP']
    };
    for (const color in colors) {
      if (colors[color].indexOf(status) >= 0) {
        return 'delivery-status-' + color;
      }
    }
    return 'delivery-status-undefined';
  }

  getTowerControl() {
    this.drService.getTower().subscribe( (elem) => {
      // console.log(elem);
    });
  }

  onRowClick(row, that) {
    that.goToMDR(row);
  }

  goToMDR(k) {
    // TODO adicionar isso no expandable-row
    window.open('https://www.tpx.aware.blue/logistic-order/' + k.mdr.id);
    // this.router.navigate(['logistic-order/' + k.mdr.id]);
  }

  styleIcon(row, that) {
    // colore a celula de acordo com o icon
    for ( const key of Object.keys(that.icons) ) {
      if (that.icons[key] === row.icon) {
        return key;
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '600px',
      data: {
        data: this.data,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      this.data = this.backupData;
      console.log(this.backupData);
      if (data.country !== null) {
        this.data = this.data.filter( el => data.country.indexOf(el.country) !== -1 );
      }
      console.log(this.data);
      if (data.mot !== null) {
        this.data = this.data.filter( el => data.mot.indexOf(el.shippingTypeDescription) !== -1 );
      }
      console.log(this.data);
      if (data.status !== null) {
        this.data = this.data.filter( el => data.status.indexOf(el.selectedIndex) !== -1 );
      }
      console.log(this.data);
    });
  }

}

@Component({
  selector: 'app-create-occurrence-status-dialog',
  templateUrl: 'create-occurrence-status-dialog.html',
})
export class CreateOccurrenceStatusDialogComponent {
  occurrence = new Occurrence();
  constructor(
    public mdrService: MdrService, public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CreateOccurrenceStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }

  onClickSave(): void {
    this.occurrence.idMDR = this.data.mdrID;
    this.occurrence.status = 'OCCURRENCE_RESOLVED';
    this.occurrence.uoc = 'Status';
    this.mdrService.createOccurrence(this.occurrence).subscribe((response) => {
      if (response !== undefined) {
        this.snackBar.open('Ocorrência criada com sucesso', 'Ok', {
          duration: 4000,
        });
      }
    });
    this.dialogRef.close({ what: 'update', data: this.data });
  }

}

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
  styleUrls: ['./tower-control.component.scss']
})
export class FilterDialogComponent {
  myControl = new FormControl();
  myControl2 = new FormControl();
  myControl3 = new FormControl();
  listCoutry = [{value: 'BO', name: 'BO'}, {value: 'UY', name: 'UY'}, {value: 'AR', name: 'AR'}, {value: 'CL', name: 'CL'}, {value: 'CO', name: 'CO'}];
  listMoT = [{value: 'Road transport', name: 'Road transport'}, {value: 'Air transport', name: 'Air transport'}, {value: 'Maritime transport', name: 'Maritime transport'}, {value: 'Mail / Courier', name: 'Mail / Courier'}];
  listStatus = [{value: 'Sent To LSP', name: 'Sent To LSP'}, {value: 'LSP Validation', name: 'LSP Validation'}, {value: 'Insert Documents', name: 'Insert Documents'}, {value: 'Expedition', name: 'Expedition'}, {value: 'In Transit', name: 'In Transit'}, {value: 'Delivered', name: 'Delivered'}, {value: 'Done', name: 'Done'}];

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    }

  onYesClick(): void {
    this.dialogRef.close({country: this.myControl.value, mot: this.myControl2.value, status: this.myControl3.value});
  }

  onNoClick(): void {
    this.data.value = 0;
    this.dialogRef.close();
  }

}
