import { OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';
var PodsChartComponent = /** @class */ (function () {
    function PodsChartComponent(translate) {
        this.translate = translate;
        this.noData = false;
    }
    PodsChartComponent.prototype.ngOnInit = function () {
    };
    PodsChartComponent.prototype.createChart = function (data) {
        if (data.length > 0) {
            this.noData = false;
            this.chart = new Chart({
                chart: {
                    type: 'line'
                },
                title: {
                    text: ''
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                        }
                    }
                },
                xAxis: {
                    categories: data.map(function (day) { return day.createdAt; }),
                },
                credits: {
                    enabled: false
                },
                series: [{
                        name: this.translate.instant('PODS/counting'),
                        type: undefined,
                        data: data.map(function (day) { return day.count; })
                    }]
            });
        }
        else {
            this.noData = true;
        }
    };
    return PodsChartComponent;
}());
export { PodsChartComponent };
