export class ZZTPXHEADER {
      public id: number;
      public shipment: string;
      public country: string;
      public salesOrder: string;
      public customerPO: string;
      public delivery: string;
      public incoterms1: string;
      public shippingTypeDescription: string;
      public delRouteDescription: string;
      public shppnt: string;
      public shppntName: string;
      public plant: string;
      public plantName: string;
      public shipToPartyName: string;
      public city: string;
      public forwardingAgent: string;
      public forwardingAgentName: string;
      public plannedLoadEnd: string;
      public shipmentStartStatus: string;
      public actLoadingEnd: string;
      public plannedShipmentEnd: string;
      public actShipmentCompleteDate: string;
      public extId1: string;
      public extId2: string;
      public shipmEndStatus: string;
      public insertedBy: number;
      public updatedBy: number;
      public insertDate: Date;
      public updateDate: Date;
      public active: boolean;


    constructor() {
      this.id = null;
      this.shipment = null;
      this.country = null;
      this.salesOrder = null;
      this.customerPO = null;
      this.delivery = null;
      this.incoterms1 = null;
      this.shippingTypeDescription = null;
      this.delRouteDescription = null;
      this.shppnt = null;
      this.shppntName = null;
      this.plantName = null;
      this.plant = null;
      this.shipToPartyName = null;
      this.city = null;
      this.forwardingAgent = null;
      this.forwardingAgentName = null;
      this.plannedLoadEnd = null;
      this.shipmentStartStatus = null;
      this.actLoadingEnd = null;
      this.plannedShipmentEnd = null;
      this.actShipmentCompleteDate = null;
      this.extId1 = null;
      this.extId2 = null;
      this.insertedBy = null;
      this.updatedBy = null;
      this.insertDate = null;
      this.updateDate = null;
      this.active = null;
    }
  }

