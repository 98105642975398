import { OnInit } from '@angular/core';
var DrsIndiceComponent = /** @class */ (function () {
    function DrsIndiceComponent() {
    }
    DrsIndiceComponent.prototype.ngOnInit = function () { };
    DrsIndiceComponent.prototype.createIndice = function (data) {
        this.presentData = data.filter(function (element) { return element.last_driver_position === 'present'; })[0].count;
        this.pastData = data.filter(function (element) { return element.last_driver_position === 'missing'; })[0].count;
    };
    return DrsIndiceComponent;
}());
export { DrsIndiceComponent };
