import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var ShipmentService = /** @class */ (function () {
    function ShipmentService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    ShipmentService.prototype.getAllShipment = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'shipment/getAll', { headers: header })
            .map(function (response) {
            var ShipmentData = response;
            return ShipmentData;
        });
    };
    ShipmentService.prototype.unmakeShipments = function (list) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(base_url + 'shipment/unmake', {
            shipmentList: list,
        }, { headers: header });
    };
    ShipmentService.prototype.insertMultiples = function (list) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'driver/saveMultiplePositions', { list: list }, { headers: header })
            .map(function (response) {
            if (response !== undefined) {
                if (response.text === 'Success') {
                    return true;
                }
            }
            return false;
        });
    };
    ShipmentService.prototype.getAll = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'shipment/' + id, { headers: header })
            .map(function (response) {
            var ShipmentData = response;
            return ShipmentData;
        });
    };
    ShipmentService.prototype.getAirports = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'shipment/airports/list', { headers: header })
            .map(function (response) {
            var airports = response;
            return airports;
        });
    };
    ShipmentService.prototype.getAirways = function (mdr) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'shipment/airway/' + mdr, { headers: header })
            .map(function (response) {
            var airports = response;
            return airports;
        });
    };
    ShipmentService.prototype.insertAirway = function (ship) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'shipment/airway', { airway: ship }, { headers: header })
            .map(function (response) {
            var ShipmentData = response;
            return ShipmentData;
        });
    };
    ShipmentService.prototype.updateAirway = function (airport) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.put(base_url + 'airports/' + airport.id, airport.airport, { headers: header })
            .map(function (response) {
            var ShipmentData = response;
            return ShipmentData;
        });
    };
    ShipmentService.prototype.removeAirway = function (ship) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.delete(base_url + 'shipment/' + ship.id, { headers: header })
            .map(function (response) {
            var ShipmentData = response;
            return ShipmentData;
        });
    };
    ShipmentService.prototype.getAllByMDR = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'shipment/byMDR/' + id, { headers: header })
            .map(function (response) {
            var ShipmentData = response;
            return ShipmentData;
        });
    };
    ShipmentService.prototype.getAllInfo = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'shipment/info/' + id, { headers: header })
            .map(function (response) {
            var ShipmentData = response;
            return ShipmentData;
        });
    };
    ShipmentService.prototype.insert = function (ship) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'shipment/', { shippment: ship }, { headers: header })
            .map(function (response) {
            var ShipmentData = response;
            return ShipmentData;
        });
    };
    return ShipmentService;
}());
export { ShipmentService };
