import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';
const base_url: string = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private configurations: any[];

  constructor(private http: HttpClient) { }

  private loadConfigurations(): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'configuration', {headers: header}).map((configurations: any[]) => {
      this.configurations = configurations;
      return configurations;
    });
  }

  public getConfiguration(page: string, force?: boolean): Observable<any> {
    // quando as configurations já foram carregadas
    if (this.configurations && force !== true) {
      return new Observable((observer) => {
        if (this.configurations.length > 0) {
          const found = this.configurations.find(e => e.page === page);
          if (found) {
            observer.next(found.configuration);
          } else {
            throw new Error('Not found');
          }
        }
      });
    } else {
      // quando as configurations ainda não foram carregadas
      return this.loadConfigurations().pipe(
        map(
          (configurations) => {
              const found = configurations.find(e => e.page === page);
              if (found) {
                return found.configuration;
              } else {
                throw new Error('Not found');
              }
          }
        )
      );
    }
  }

}
