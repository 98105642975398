<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'AIRPORT/title' | translate }}</p>
  <p class="mx-4">{{ 'AIRPORT/subtitle' | translate }}</p>
  <button mat-raised-button
    class="green-button"
    (click)="addAirport()"
  >
    {{ 'AIRPORT/create' | translate }}
  </button>
</div>

<div class="colored-background table-background px-5 py-2">
  <app-table
    [data]="data"
    [columns]="columns"
    [this]="this"
    [clickCallback]="onRowClick"
  ></app-table>
</div>
