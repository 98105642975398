import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { ClientUserService } from '../../providers/client-user.service';
import { TranslateService } from '@ngx-translate/core';
import { Occurrence } from '../../models/occurrence';
import { ConfigurationService } from '../../providers/configuration.service';
var TowerControlComponent = /** @class */ (function () {
    function TowerControlComponent(translate, dialog, mapsAPILoader, clientService, drService, excelService, router, snackBar, configService, dpipe) {
        var _this = this;
        this.translate = translate;
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.clientService = clientService;
        this.drService = drService;
        this.excelService = excelService;
        this.router = router;
        this.snackBar = snackBar;
        this.configService = configService;
        this.dpipe = dpipe;
        this.data = [];
        this.icons = {
            'undefined': 'help',
            'red': 'error',
            'yellow': 'warning',
            'green': 'check_circle'
        };
        this.columns = [
            { value: 'client', name: 'CONTROL_TOWER/table/client' },
            { value: 'pickupID', name: 'CONTROL_TOWER/table/mdr' },
            { value: 'icon', name: 'Status Card', icon: 'icon', tooltip: 'tooltip', color: this.styleIcon },
            { value: 'mdrShippingCompany', name: 'CONTROL_TOWER/table/lsp' },
            { value: 'site', name: 'CONTROL_TOWER/table/site' },
            { value: 'infraType', name: 'CONTROL_TOWER/table/infra' },
            { value: 'ops', name: 'CONTROL_TOWER/table/specialOps' },
            { value: 'salesOrders', name: 'CONTROL_TOWER/table/salesOrders' },
            { value: 'dateScheduling', name: 'CONTROL_TOWER/table/date' },
            { value: 'status', name: 'CONTROL_TOWER/table/status' },
            { value: 'occurrence', name: 'CONTROL_TOWER/table/occurrence' },
            { value: 'city', name: 'CONTROL_TOWER/table/city' },
            { value: 'uf', name: 'CONTROL_TOWER/table/uf' },
            { value: 'copqSum', name: 'CONTROL_TOWER/table/copq', currency: 'BRL' },
        ];
        this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
        this.configService.getConfiguration('tower-control').subscribe(function (response) {
            _this.columns = response['columns'];
            // this.advancedSearchColumns = this.columns.map(e => e.value);
        }, function (error) {
            // mantém as colunas pre-definidas
        });
    }
    TowerControlComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    TowerControlComponent.prototype.getData = function () {
        var _this = this;
        this.drService.getTowerTPX().subscribe(function (elem) {
            elem.forEach(function (k) {
                if (k.header) {
                    k.plantName = k.header.plantName;
                    k.country = k.header.country;
                    k.shipToPartyName = k.header.shipToPartyName;
                    k.shippingTypeDescription = k.header.shippingTypeDescription;
                }
                if (k.mdr) {
                    k.parsedCreatedAt = new Date(k.mdr.createdAt);
                    k.sapCode = k.mdr.sapCode;
                    k.SAD = k.mdr.SAD;
                    k.lsp = k.mdr.shippingCompany;
                    k.pickupID = k.mdr.pickupID;
                    k.goTo = k.mdr.id + '';
                    switch (k.mdr.status) {
                        case 'MDR em criação':
                            k.selectedIndex = 'Sent To LSP';
                            break;
                        case 'MDR aguardando LSP':
                            k.selectedIndex = 'LSP Validation';
                            break;
                        case 'MDR aprovada LSP':
                            k.selectedIndex = 'Insert Documents';
                            break;
                        case 'Motorista e veiculo definidos':
                            k.selectedIndex = 'Expedition';
                            break;
                        case 'Veículo carregado':
                            k.selectedIndex = 'In Transit';
                            break;
                        case 'Carga entregue com sucesso':
                            k.selectedIndex = 'Delivered';
                            break;
                        case 'Entrega de carga certificada pelo LSP':
                            k.selectedIndex = 'Done';
                            break;
                        default:
                            k.selectedIndex = 'LSP Validation';
                            break;
                    }
                }
                if (k.occ !== undefined) {
                    if (k.occ.length > 0) {
                        var max = 0;
                        var occAux = null;
                        // console.log(k.occ.length);
                        for (var i = 0; i < k.occ.length; i++) {
                            if (k.occ[i].id > max) {
                                max = k.occ[i].id;
                                occAux = i;
                            }
                        }
                        k.occurrence = k.occ[occAux].uoc;
                        k.occurrenceObservation = k.occ[occAux].text;
                        k.occurrenceStatus = k.occ[occAux].status === 'OCCURRENCE_RESOLVED' ? 'CLOSED' : 'OPEN';
                    }
                    else {
                        k.occurrence = '';
                        k.occurrenceObservation = '';
                        k.occurrenceStatus = '';
                    }
                }
                else {
                    k.occurrence = '';
                    k.occurrenceObservation = '';
                    k.occurrenceStatus = '';
                }
            });
            // setta a table
            _this.data = elem;
            _this.backupData = _this.data;
        });
    };
    TowerControlComponent.prototype.getInfo = function (dr) {
        var tooltip = [];
        var color = 'green';
        var hora = 1000 * 60 * 60;
        var now = Date.now();
        // se está aguardando atualizações da LSP
        if (dr.status === 'Waiting LSP') {
            if (dr.updatedAt === null) {
                // se a DR ainda não foi atualizada
                color = 'undefined';
                tooltip.push('não há atualização');
            }
            else {
                var d = new Date(dr.updatedAt);
                if (now - d.getTime() < 1 * hora) {
                    // se a última atualização ocorreu a menos de 1h
                    color = 'green';
                }
                else if (now - d.getTime() < 2 * hora) {
                    // se a última atualização ocorreu a menos de 2h
                    color = 'yellow';
                    tooltip.push('última atualização a mais de 1h');
                }
                else {
                    // se a última atualização ocorreu a mais de 2h
                    color = 'red';
                    tooltip.push('última atualização a mais de 2h');
                }
            }
        }
        // se está pronto para coleta
        if (dr.status === 'READY_TO_PICKUP') {
            if (dr.mdr.pickupDate === null) {
                color = 'undefined';
                tooltip.push('data de coleta ausente');
            }
            else {
                var d = new Date(dr.mdr.pickupDate);
                if (d.getTime() - now > 1 * hora) {
                    // mais de 1h até a hora de coleta
                    color = 'green';
                }
                else if (d.getTime() - now > 0.5 * hora) {
                    // mais de 30min até a hora de coleta
                    color = 'yellow';
                    tooltip.push('menos de 1h até a hora de coleta');
                }
                else {
                    // menos de 30min até a hora de coleta
                    color = 'red';
                    tooltip.push('menos de 30 minutos até a hora de coleta');
                }
            }
        }
        // se já foi entregue
        if (dr.status === 'Delivered') {
            if (dr.updatedAt === null) {
                // se a DR ainda não foi atualizada
                color = 'undefined';
                tooltip.push('não há atualização');
            }
            else {
                var d = new Date(dr.updatedAt);
                if (now - d.getTime() < 6 * hora) {
                    // se a última atualização ocorreu a menos de 6h
                    color = 'green';
                }
                else if (now - d.getTime() < 12 * hora) {
                    // se a última atualização ocorreu a menos de 12h
                    color = 'yellow';
                    tooltip.push('última atualização a mais de 6h');
                }
                else {
                    // se a última atualização ocorreu a mais de 12h
                    color = 'red';
                    tooltip.push('última atualização a mais de 12h');
                }
            }
        }
        if (['HubReturn',
            'Replan - Retido',
            'Replan - Sefaz',
            'Replan - Antecipação',
            'Replan - Postergação',
            'Replan - Pernoite'].indexOf(dr.status) !== -1) {
            if (dr.updatedAt === null) {
                // se a DR ainda não foi atualizada
                color = 'undefined';
                tooltip.push('não há atualização');
            }
            else {
                var d = new Date(dr.updatedAt);
                if (now - d.getTime() < 5 * 24 * hora) {
                    // se a última atualização ocorreu a menos de 5d
                    color = 'green';
                }
                else if (now - d.getTime() < 10 * 24 * hora) {
                    // se a última atualização ocorreu a menos de 10d
                    color = 'yellow';
                    tooltip.push('última atualização a mais de 5 dias');
                }
                else {
                    // se a última atualização ocorreu a mais de 10d
                    color = 'red';
                    tooltip.push('última atualização a mais de 10 dias');
                }
            }
        }
        // verifica as ocorrencias da MDR
        var occStatus = 'SENT_TO_LSP';
        if (this.role === 1) {
            occStatus = 'OCCURRENCE_TO_RESOLVE';
        }
        if ('occurrences' in dr && dr.occurrences.length > 0) {
            var occurrencesNotSolved = [];
            for (var i = 0; i < dr.occurrences.length; i++) {
                if (dr.occurrences[i].status === occStatus) {
                    occurrencesNotSolved.push(dr.occurrences[i]);
                }
            }
            if (occurrencesNotSolved.length > 0) {
                var s = ((occurrencesNotSolved.length === 1) ? '' : 's');
                if (occurrencesNotSolved.some(function (el) {
                    var d = new Date(el.createdAt);
                    return (now - d.getTime() > 2 * hora);
                })) {
                    // se alguma ocorrencia foi criada mais de 2h atrás
                    color = 'red';
                    tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
                }
                else {
                    // se alguma ocorrencia foi criada, e todas menos de 2h atrás
                    color = (color === 'red') ? color : 'yellow';
                    tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
                }
            }
        } // fim verificação ocorrencias
        return {
            color: color,
            tooltip: tooltip
        };
    };
    TowerControlComponent.prototype.downloadReport = function () {
        var drs = [];
        this.data.forEach(function (elem) {
            var card = elem;
            var dr = {};
            dr['shipment'] = card.shipmentNumber;
            dr['status'] = card.selectedIndex;
            dr['origin'] = card.plantName;
            dr['country'] = card.country;
            dr['destination'] = card.shipToPartyName;
            dr['MoT'] = card.shippingTypeDescription;
            dr['ETA'] = card.sapCode;
            dr['ATA'] = card.SAD;
            dr['Call-Off'] = card.pickupID;
            dr['occurrence'] = card.occurrence;
            dr['obs'] = card.occurrenceObservation;
            dr['occStatus'] = card.occurrenceStatus;
            drs.push(dr);
        });
        this.excelService.exportAsExcelFile(drs, 'tower_control');
    };
    TowerControlComponent.prototype.getStatusColor = function (status) {
        var colors = {
            green: ['Delivered'],
            yellow: ['READY_TO_PICKUP']
        };
        for (var color in colors) {
            if (colors[color].indexOf(status) >= 0) {
                return 'delivery-status-' + color;
            }
        }
        return 'delivery-status-undefined';
    };
    TowerControlComponent.prototype.getTowerControl = function () {
        this.drService.getTower().subscribe(function (elem) {
            // console.log(elem);
        });
    };
    TowerControlComponent.prototype.onRowClick = function (row, that) {
        that.goToMDR(row);
    };
    TowerControlComponent.prototype.goToMDR = function (k) {
        // TODO adicionar isso no expandable-row
        window.open('https://www.tpx.aware.blue/logistic-order/' + k.mdr.id);
        // this.router.navigate(['logistic-order/' + k.mdr.id]);
    };
    TowerControlComponent.prototype.styleIcon = function (row, that) {
        // colore a celula de acordo com o icon
        for (var _i = 0, _a = Object.keys(that.icons); _i < _a.length; _i++) {
            var key = _a[_i];
            if (that.icons[key] === row.icon) {
                return key;
            }
        }
    };
    TowerControlComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(FilterDialogComponent, {
            width: '600px',
            data: {
                data: this.data,
            }
        });
        dialogRef.afterClosed().subscribe(function (data) {
            _this.data = _this.backupData;
            console.log(_this.backupData);
            if (data.country !== null) {
                _this.data = _this.data.filter(function (el) { return data.country.indexOf(el.country) !== -1; });
            }
            console.log(_this.data);
            if (data.mot !== null) {
                _this.data = _this.data.filter(function (el) { return data.mot.indexOf(el.shippingTypeDescription) !== -1; });
            }
            console.log(_this.data);
            if (data.status !== null) {
                _this.data = _this.data.filter(function (el) { return data.status.indexOf(el.selectedIndex) !== -1; });
            }
            console.log(_this.data);
        });
    };
    return TowerControlComponent;
}());
export { TowerControlComponent };
var CreateOccurrenceStatusDialogComponent = /** @class */ (function () {
    function CreateOccurrenceStatusDialogComponent(mdrService, snackBar, dialogRef, data) {
        this.mdrService = mdrService;
        this.snackBar = snackBar;
        this.dialogRef = dialogRef;
        this.data = data;
        this.occurrence = new Occurrence();
    }
    CreateOccurrenceStatusDialogComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    CreateOccurrenceStatusDialogComponent.prototype.onClickSave = function () {
        var _this = this;
        this.occurrence.idMDR = this.data.mdrID;
        this.occurrence.status = 'OCCURRENCE_RESOLVED';
        this.occurrence.uoc = 'Status';
        this.mdrService.createOccurrence(this.occurrence).subscribe(function (response) {
            if (response !== undefined) {
                _this.snackBar.open('Ocorrência criada com sucesso', 'Ok', {
                    duration: 4000,
                });
            }
        });
        this.dialogRef.close({ what: 'update', data: this.data });
    };
    return CreateOccurrenceStatusDialogComponent;
}());
export { CreateOccurrenceStatusDialogComponent };
var FilterDialogComponent = /** @class */ (function () {
    function FilterDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.myControl = new FormControl();
        this.myControl2 = new FormControl();
        this.myControl3 = new FormControl();
        this.listCoutry = [{ value: 'BO', name: 'BO' }, { value: 'UY', name: 'UY' }, { value: 'AR', name: 'AR' }, { value: 'CL', name: 'CL' }, { value: 'CO', name: 'CO' }];
        this.listMoT = [{ value: 'Road transport', name: 'Road transport' }, { value: 'Air transport', name: 'Air transport' }, { value: 'Maritime transport', name: 'Maritime transport' }, { value: 'Mail / Courier', name: 'Mail / Courier' }];
        this.listStatus = [{ value: 'Sent To LSP', name: 'Sent To LSP' }, { value: 'LSP Validation', name: 'LSP Validation' }, { value: 'Insert Documents', name: 'Insert Documents' }, { value: 'Expedition', name: 'Expedition' }, { value: 'In Transit', name: 'In Transit' }, { value: 'Delivered', name: 'Delivered' }, { value: 'Done', name: 'Done' }];
    }
    FilterDialogComponent.prototype.onYesClick = function () {
        this.dialogRef.close({ country: this.myControl.value, mot: this.myControl2.value, status: this.myControl3.value });
    };
    FilterDialogComponent.prototype.onNoClick = function () {
        this.data.value = 0;
        this.dialogRef.close();
    };
    return FilterDialogComponent;
}());
export { FilterDialogComponent };
