<mat-card *ngIf="show" class="p-0 py-1" (click)="goToMDR()" [ngClass]="getStatusClass()">
  <mat-card-title class="m-0 pl-2 d-flex justify-content-between align-items-center">
    <a [href]="setRedirectUrl()">{{ dr.mdr ? (pickupID ? pickupID : (dr.idMDR | mdrId)) : 'sem MDR associada' }}</a>
    <div class="m-0 p-0 align-items-center">
      <i text class="fa fa-comments"
        matTooltip="há mensagens não lidas entre transportadora e embarcadora"
        matTooltipPosition="left"
        *ngIf="novasMensagens[6]">
      </i>
      <i text class="fa fa-comments"
        matTooltip="há mensagens não lidas entre transportadora e motorista"
        matTooltipPosition="left"
        *ngIf="novasMensagens[5]">
      </i>
      <i text class="fa fa-truck-monster"
        matTooltip="Necessita Op. Especiais"
        matTooltipPosition="left"
        *ngIf="dr.thirdPartInvoice">
      </i>
      <i text class="fa fa-street-view"
        matTooltip="Motorista no Site"
        matTooltipPosition="left"
        *ngIf="dr.arrivalTime && dr.status === 'InTransit'">
      </i>
      <i text class="fa fa-wifi"
        matTooltip="Caminhão visto na última hora"
        matTooltipPosition="left"
        *ngIf="!truckSaw && dr.status === 'InTransit'">
      </i>

      <i text class="fa"
        [matTooltip]="tooltip.join(', ')"
        matTooltipPosition="left"
        [ngClass]="icons[color]"
        *ngIf="icons[color]">
      </i>
      <i text class="fa fa-plane"
        style="color: white"
        matTooltipPosition="left"
        *ngIf="dr.mdr.airport === true">
      </i>
      <i text class="fa fa-truck"
        style="color: white"
        matTooltipPosition="left"
        *ngIf="dr.mdr.airport === null">
       </i>
      <i text class="fa fa-ship"
        style="color: white"
        matTooltipPosition="left"
        *ngIf="dr.mdr.airport === false">
    </i>
    </div>
  </mat-card-title>
  <mat-card-content class="px-2 py-1">
    <div class="d-flex justify-content-center smaller-text">
      <div>{{dr.supervisorName }}</div>
    </div>
    <div class="d-flex justify-content-center smaller-text">
      <div>{{ dr.site }}</div>
    </div>
    <div style="" class="d-flex justify-content-between">
      <div style="margin: auto;" class="d-flex justify-content-center align-items-center">
        <div>{{ dr.idDRidentifier }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="p-0 col-md-5 d-flex justify-content-center align-items-center">
        <div>{{ dr.deliveryAddress }}</div>
      </div>
      <i text class="fa fa-arrow-right"></i>
      <div class="p-0 col-md-5 d-flex justify-content-center align-items-center">
        <div>{{ dr.infraType }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex justify-content-center  smaller-text align-items-center">
        <div>RFS: {{ dr.obs | date: "dd/MM/yy" }}</div>
      </div>
      <div class="d-flex justify-content-center  smaller-text align-items-center">
        <div>ETA: {{dr.mdr.sapCode}}</div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex justify-content-center  smaller-text align-items-center">
        <div>1st ETA: {{ dr.receiverName | date: "dd/MM/yy" }}</div>
      </div>
      <div class="d-flex justify-content-center  smaller-text align-items-center">
        <div>ATA: {{dr.mdr.SAD}}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
