<div style="max-height: 550px;">
  <div class="mat-elevation-z8" style="margin-bottom: 30px;">
    <div class="more-info">
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/lat' | translate }}</span>: {{ data.dr['lat'].toFixed(6) }}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/lng' | translate }}</span>: {{ data.dr['lng'].toFixed(6) }}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/userArrivalTime' | translate }}</span>: {{ data.dr['userArrivalTime'] | date: 'medium'}}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/arrivalTime' | translate }}</span>: {{ data.dr['arrivalTime'] | date: 'medium' }}</p>
    </div>
    <div *ngIf="role !== 5 && dataSource.data.length > 0">
      <table mat-table #table2 [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="delivery">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> PO Number </th>
          <td mat-cell *matCellDef="let element"> {{element.zzt.customerPO}} </td>
        </ng-container>

        <ng-container matColumnDef="shipment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Shipment </th>
          <td mat-cell *matCellDef="let element"> {{element.zzt.shipment}} </td>
        </ng-container>

        <ng-container matColumnDef="material">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Article No </th>
          <td mat-cell *matCellDef="let element"> {{element.material}} </td>
        </ng-container>

        <ng-container matColumnDef="handlingUnit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> HU </th>
          <td mat-cell *matCellDef="let element"> {{element.handlingUnit}} </td>
        </ng-container>

        <ng-container matColumnDef="packedQty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Qty per Box </th>
          <td mat-cell *matCellDef="let element"> {{element.packedQty}} </td>
        </ng-container>

        <ng-container matColumnDef="so">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>EDB SO </th>
          <td mat-cell *matCellDef="let element"> {{element.zzt.salesOrder}} </td>
        </ng-container>

        <ng-container matColumnDef="dateScheduling">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DELIVERY_ROUTE/date' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.dateScheduling | date:'d/M/yy, h:mm a'}} </td>
        </ng-container>
        salesOrderLineItem
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DELIVERY_ROUTE/status' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.status | translate}} </td>
        </ng-container>

        <ng-container matColumnDef="nameOfTheEndUserForFTrade">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer </th>
          <td mat-cell *matCellDef="let element"> {{element.nameOfTheEndUserForFTrade }} </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element"> <span (click)="openDialog(element)">{{ 'DELIVERY_ROUTE/edit' | translate }}</span> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

      </table>
      <mat-paginator #paginator
        [pageSize]="5"
        [pageSizeOptions]="[5, 10]"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </div>
</div>
