import { Component } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';

@Component({
  selector: 'app-menu-component',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  currentUser: any;
  role: number;
  lastUpdate: Date;
  show = false;

  menuItems = [
    { name: 'MENU/REQUISITION',        icon: 'fa-clipboard',           link: '/new-shipment-request' },
    { name: 'MENU/OPTIMIZER',          icon: 'fa-cogs',                link: '/shipment-optimizer' },
    // { name: 'MENU/CONSOLIDE',       icon: 'fa-boxes',               link: '/mdr-optimizer' },
    // { name: 'MENU/NEW_MDR',         icon: 'fa-shield-alt',          link: '/mdr-management' },
    { name: 'MENU/FOLLOW_MDR',         icon: 'fa-truck',               link: '/list-mdrs' },
    // { name: 'Torre de Controle',    icon: 'fa-building',            link: '/control-tower' },
    // { name: 'MDR LSP',              icon: 'fa-building',            link: '/mdr-lsp' },
    // { name: 'Notificações',         icon: 'fa-building',            link: '/notification' },
    { name: 'MENU/MAP_MDR',            icon: 'fa-map',                 link: '/mdr-map'},
    { name: 'MENU/TOWER_CONTROL',      icon: 'fa-broadcast-tower',     link: '/control-tower' },
    { name: 'MENU/COCKPIT',            icon: 'fa-building',            link: '/cockpit' },
    // { name: 'MENU/WAREHOUSE',       icon: 'fa-warehouse',           link: '/hub'},
    // { name: 'MENU/ADD_LSP_USER',    icon: 'fa-users-cog',           link: '/lsp-user-admin' },
    // { name: 'MENU/FINANCE',         icon: 'fa-file-invoice-dollar', link: '/finance-list' },
    { name: 'MENU/UPLOAD',             icon: 'fa-cloud-upload-alt',    link: '/insert-new-data' },
    { name: 'MENU/AIRPORTS',           icon: 'fa-plane-departure',     link: '/airports' },
    // { name: 'MENU/CALCULATOR',      icon: 'fa-calculator',          link: '/price-maker' },
  ];

  constructor(public auth: AuthService, public router: Router, public drService: DrService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;

    if (this.role === 5) {
      this.menuItems = [
        { name: 'MENU/FOLLOW_MDR',         icon: 'fa-truck',               link: '/list-mdrs'          },
        { name: 'MENU/OCCURRENCE',         icon: 'fa-building',            link: '/occurrence-list'    },
        { name: 'MENU/COCKPIT',            icon: 'fa-building',            link: '/cockpit'            },
        { name: 'MENU/TOWER_CONTROL',      icon: 'fa-dolly',               link: '/control-tower'      },
        { name: 'MENU/FINANCE',            icon: 'fa-file-invoice-dollar', link: '/finance-list'       },
        { name: 'MENU/REPORTS',            icon: 'fa-clipboard-list',      link: '/reports'            },
        { name: 'MENU/SITE_INVESTIGATION', icon: 'fa-cogs',                link: '/site-investigation' },
        { name: 'MENU/LOGOUT',             icon: 'fa-sign-out-alt',        link: '/logout'             }
      ];
    } else if (this.role === 2) {
      this.menuItems = [
        { name: 'MENU/REQUISITION', icon: 'fa-clipboard', link: '/new-delivery-request' },
        { name: 'MENU/FOLLOW_MDR', icon: 'fa-truck', link: '/list-mdrs' },
        { name: 'MENU/MAP_MDR', icon: 'fa-map', link: '/mdr-map'},
        { name: 'MENU/TOWER_CONTROL', icon: 'fa-dolly', link: '/lsp-control-tower' },
        { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
      ];
    } else if (this.role === 7) {
      this.menuItems = [
        {name: 'MENU/FINANCE', icon: 'fa-file-invoice-dollar', link: '/finance-list'},
        {name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout'}
      ];
    } else if (this.role === 8) {
        this.menuItems = [
          { name: 'MENU/CALCULATOR', icon: 'fa-calculator', link: '/price-maker' },
          { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
        ];
    } else if (this.role === 6) {
      this.menuItems = [];
    } else if (this.role === 9) {
      this.menuItems = [
        { name: 'MENU/COCKPIT',            icon: 'fa-building',            link: '/cockpit'            },
        { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
      ];
    } else if (this.role === 1) {
      this.menuItems.push({ name: 'MENU/OCCURRENCE', icon: 'fa-building', link: '/occurrence-list' });
    }

    // this.drService.getLastInserted().subscribe((response) => {
    //   this.lastUpdate = response;
    // });
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['login']);
  }
}
