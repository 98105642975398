<h1 mat-dialog-title>Requisição de entrega cadastrada como sucesso!</h1>
<div mat-dialog-content>
  <mat-form-field class="col-md-6">
    <mat-select placeholder="Country" [formControl]="myControl" multiple>
       <mat-option *ngFor="let dr of listCoutry" [value]="dr.value">{{dr.name}}</mat-option>
    </mat-select>
 </mat-form-field>
 <mat-form-field class="col-md-6">
  <mat-select placeholder="MoT" [formControl]="myControl2" multiple>
     <mat-option *ngFor="let dr of listMoT" [value]="dr.value">{{dr.name}}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field class="col-md-6">
  <mat-select placeholder="Status" [formControl]="myControl3" multiple>
     <mat-option *ngFor="let dr of listStatus" [value]="dr.value">{{dr.name}}</mat-option>
  </mat-select>
</mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button  (click)="onYesClick()">Confirmar</button>
  <button mat-button cdkFocusInitial (click)="onNoClick()">Cancelar</button>
</div>