var DriverPosition = /** @class */ (function () {
    function DriverPosition() {
        this.id = null;
        this.mdrId = null;
        this.phoneNumber = null;
        this.lat = null;
        this.lng = null;
        this.createdAt = null;
    }
    return DriverPosition;
}());
export { DriverPosition };
