import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AirportService } from '../../providers/airport.service';
import { Airport } from '../../models/airport';
var AirportsComponent = /** @class */ (function () {
    function AirportsComponent(dialog, airportService) {
        this.dialog = dialog;
        this.airportService = airportService;
        this.columns = [
            { value: 'icao', name: 'AIRPORT/table/icao' },
            { value: 'iata', name: 'AIRPORT/table/iata' },
            { value: 'name', name: 'AIRPORT/table/name' },
            { value: 'city', name: 'AIRPORT/table/city' },
            { value: 'state', name: 'AIRPORT/table/state' },
            { value: 'coutry', name: 'AIRPORT/table/coutry' },
            { value: 'elevation', name: 'AIRPORT/table/elevation' },
            { value: 'lat', name: 'AIRPORT/table/lat' },
            { value: 'lng', name: 'AIRPORT/table/lng' },
            { value: 'tz', name: 'AIRPORT/table/tz' },
            { value: 'icon', name: 'AIRPORT/table/type', icon: 'icon', tooltip: 'tooltip' },
        ];
        this.data = [];
        this.icons = {
            air: 'airplanemode_active',
            sea: 'directions_boat',
        };
        this.tooltips = {
            air: 'Aeroporto',
            sea: 'Porto marítmo',
        };
    }
    AirportsComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    AirportsComponent.prototype.getData = function () {
        var _this = this;
        this.airportService.getAll().subscribe(function (response) {
            _this.data = response.map(function (row) {
                // convert is_airport into type
                row['type'] = row['is_airport'] ? 'air' : 'sea';
                // set the icon
                row['icon'] = _this.icons[row['type']];
                // set the tooltip
                row['tooltip'] = _this.tooltips[row['type']];
                return row;
            });
        });
    };
    AirportsComponent.prototype.onRowClick = function (row, that) {
        // open dialog to edit airport
        var dialogRef = that.dialog.open(EditAirportDialogComponent, {
            data: {
                title: 'AIRPORT/dialog/edit',
                airport: row,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response && response.refresh === true) {
                // refresh the table
                that.getData();
            }
        });
    };
    AirportsComponent.prototype.addAirport = function () {
        var _this = this;
        // open dialog to create airport
        var dialogRef = this.dialog.open(CreateAirportDialogComponent, {
            data: {
                title: 'AIRPORT/dialog/create',
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response && response.refresh === true) {
                // refresh the table
                _this.getData();
            }
        });
    };
    return AirportsComponent;
}());
export { AirportsComponent };
var CreateAirportDialogComponent = /** @class */ (function () {
    function CreateAirportDialogComponent(dialogRef, snackBar, data, airportService) {
        this.dialogRef = dialogRef;
        this.snackBar = snackBar;
        this.data = data;
        this.airportService = airportService;
        this.airport = new Airport();
        this.dialogTitle = data.title;
    }
    CreateAirportDialogComponent.prototype.back = function () {
        this.dialogRef.close({
            refresh: false,
        });
    };
    CreateAirportDialogComponent.prototype.save = function () {
        var _this = this;
        this.airportService.create(this.airport).subscribe(function (response) {
            _this.dialogRef.close({
                refresh: true,
            });
        }, function (err) {
            console.error(err);
            _this.snackBar.open('Erro ao tentar criar aeroporto', 'Ok', { duration: 4000 });
        });
    };
    return CreateAirportDialogComponent;
}());
export { CreateAirportDialogComponent };
var EditAirportDialogComponent = /** @class */ (function () {
    function EditAirportDialogComponent(dialogRef, snackBar, data, airportService) {
        this.dialogRef = dialogRef;
        this.snackBar = snackBar;
        this.data = data;
        this.airportService = airportService;
        this.airport = data.airport;
        this.dialogTitle = data.title;
    }
    EditAirportDialogComponent.prototype.back = function () {
        this.dialogRef.close({
            refresh: false,
        });
    };
    EditAirportDialogComponent.prototype.save = function () {
        var _this = this;
        this.airportService.update(this.airport).subscribe(function (response) {
            _this.dialogRef.close({
                refresh: true,
            });
        }, function (err) {
            console.error(err);
            _this.snackBar.open('Erro ao tentar atualizar aeroporto', 'Ok', { duration: 4000 });
        });
    };
    return EditAirportDialogComponent;
}());
export { EditAirportDialogComponent };
