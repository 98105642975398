var Shipment = /** @class */ (function () {
    function Shipment() {
        this.id = null;
        this.shipmentNumber = null;
        this.receiverName = null;
        this.receiverPhone = null;
        this.observation = null;
        this.truckId = null;
        this.shipToCity = null;
        this.nameOfTheEndUserForFTrade = null;
        this.shipToStreet = null;
        this.confirmedDeliveryDate = null;
        this.weight = null;
        this.volume = null;
        this.createdAt = null;
        this.status = null;
    }
    return Shipment;
}());
export { Shipment };
