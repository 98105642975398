import { OnInit } from '@angular/core';
import { MatTableDataSource, MatSort, MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DrService } from '../../../providers/dr.service';
import { DrObservable } from '../../../services/dr.service';
import { TranslateService } from '@ngx-translate/core';
import { HubService } from '../../../providers/hub.service';
import { HUBDR } from '../../../models/hub__dr';
import { OVService } from '../../../providers/ov.service';
import { MDR } from '../../../models/mdr';
import { ShipmentService } from '../../../providers/shipment.service';
import { ESTRUTURA } from '../../../providers/site.service';
import { ClientUserService } from '../../../providers/client-user.service';
import { DatePipe } from '@angular/common';
var DeliveryRouteComponent = /** @class */ (function () {
    function DeliveryRouteComponent(aRoute, drService, hubService, snackBar, drObservable, dialog, ovService) {
        this.aRoute = aRoute;
        this.drService = drService;
        this.hubService = hubService;
        this.snackBar = snackBar;
        this.drObservable = drObservable;
        this.dialog = dialog;
        this.ovService = ovService;
        this.data = [];
        this.dataSource = new MatTableDataSource(this.data);
        this.displayedColumns = ['shipmentNumber', 'receiverName', 'receiverPhone', 'observation', 'action'];
        this.columnsToDisplay = ['deliveryAddress', 'site', 'status', 'dateScheduling', 'client'];
        this.siteNomes = {
            'form_6_1': 'Grua',
            'form_6_2': 'Correia',
            'form_6_3': 'Veículos Esp.',
            'form_6_4': 'Operação Manual',
            'form_6_5': 'Guindates'
        };
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        if (this.role === 1) { // pra role!==5 adiciona botoes de editar e visualizar
            this.displayedColumns = [].concat(this.displayedColumns, ['action', 'view']);
        }
    }
    DeliveryRouteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.drObservable.currentDR.subscribe(function (dr) {
            _this.data = dr;
            // console.log(this.data);
            for (var i = 0; i < _this.data.length; i++) { // TODO optimize this n3 disgusting function
                var ovAux = [];
                var str = '';
                if (_this.data[i].ovList !== undefined) {
                    for (var j = 0; j < _this.data[i].ovList.length; j++) {
                        var flag = 0;
                        for (var k = 0; k < ovAux.length; k++) {
                            if (ovAux[k].salesDocument === _this.data[i].ovList[j].salesDocument) {
                                flag = 1;
                                break;
                            }
                        }
                        if (flag === 0) {
                            ovAux.push(_this.data[i].ovList[j]);
                            if (j !== 0) {
                                str += ', ';
                            }
                            str += _this.data[i].ovList[j].salesDocument;
                        }
                    }
                    _this.data[i].ovList = ovAux;
                    _this.data[i].obs = str;
                }
                // detalhes do site.form_6_?
                _this.data[i]['site_form6'] = [];
                if (_this.data[i]['site_form']) {
                    var keys = Object.keys(_this.data[i]['site_form']).filter(function (e) { return e.indexOf('form_6_') === 0; }).filter(function (e) { return e.indexOf('_obs') === -1; });
                    for (var j = 0, len = keys.length; j < len; j++) {
                        // console.log(this.data[i]['site_form'][keys[j]]);
                        if (_this.data[i]['site_form'][keys[j]] === ESTRUTURA.EX) {
                            _this.data[i]['site_form6'].push(_this.siteNomes[keys[j]]);
                        }
                    }
                }
            }
            _this.dataSource = new MatTableDataSource(_this.data);
        });
    };
    DeliveryRouteComponent.prototype.setUserArrivalTime = function (dr) {
        var _this = this;
        dr.userArrivalTime = new Date();
        this.drService.update(dr).subscribe(function (response) {
            if (response === true) {
                _this.snackBar.open('DR atualizada com sucesso', 'Ok', { duration: 2000 });
            }
            else {
                dr.userArrivalTime = null;
                _this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', { duration: 2000 });
            }
        });
    };
    DeliveryRouteComponent.prototype.openEditDialog = function (dr) {
        var dialogRef = this.dialog.open(EditDrDialogComponent, {
            data: {
                role: this.role,
                dr: dr,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
        });
    };
    DeliveryRouteComponent.prototype.openViewDialog = function (dr) {
        var dialogRef = this.dialog.open(ViewInfoComponent, {
            width: '600px',
            data: {
                dr: dr,
                role: this.role
            },
        });
        dialogRef.afterClosed().subscribe(function (response) {
            // console.log(response);
        });
    };
    DeliveryRouteComponent.prototype.openHistoryDialog = function (dr) {
        var _this = this;
        this.drService.getHistory(dr.id).subscribe(function (history) {
            var dialogRef = _this.dialog.open(HistoryDialogComponent, {
                width: '80vw',
                maxHeight: '80vh',
                data: { history: history }
            });
        });
    };
    return DeliveryRouteComponent;
}());
export { DeliveryRouteComponent };
var EditDrDialogComponent = /** @class */ (function () {
    function EditDrDialogComponent(dialogRef, hubService, data, translate, drService, clientService, snackBar) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.hubService = hubService;
        this.data = data;
        this.translate = translate;
        this.drService = drService;
        this.clientService = clientService;
        this.snackBar = snackBar;
        this.hubList = [];
        this.clientList = [];
        this.statusList = ['InTransit',
            'READY_TO_PICKUP',
            'Replan - Retido',
            'Replan - Sefaz',
            'Replan - Pernoite',
            'Replan - Antecipação',
            'Replan - Postergação',
            'HubReturn',
            'Canceled',
            'Delivered'];
        this.hubService.getAll().subscribe(function (hubs) {
            _this.hubList = hubs;
        });
        this.clientService.getClients().subscribe(function (clients) {
            _this.clientList = clients.map(function (e) { return e.clientGroup; });
        });
    }
    EditDrDialogComponent.prototype.ngOnInit = function () {
        // faz uma copia da DR para edição
        this.current = Object.assign({}, this.data.dr);
    };
    EditDrDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        // compara DR copiada para edição com DR anterior
        var checkChanges = function (key) {
            return (_this.current[key] !== _this.data.dr[key]);
        };
        // se nao houve alguma modificação
        var keys = Object.keys(this.current);
        if (!keys.some(checkChanges)) {
            this.snackBar.open('Nenhum valor foi alterado', 'Ok', { duration: 2000 });
            this.dialogRef.close();
            return;
        }
        if (this.current.status === 'HubReturn') {
            var newHUBDR = new HUBDR();
            newHUBDR.hubID = this.hubId;
            newHUBDR.drID = this.data.dr.id;
            newHUBDR.when = new Date();
            newHUBDR.observation = this.hubObs;
            this.hubService.createHubDr(newHUBDR).subscribe(function (elem) {
                // console.log(elem);
            });
        }
        this.drService.update(this.current).subscribe(function (response) {
            if (response === true) {
                _this.snackBar.open('DR atualizada com sucesso', 'Ok', { duration: 2000 });
                // copia valores pra DR anterior
                keys.forEach(function (key) {
                    _this.data.dr[key] = _this.current[key];
                });
                _this.dialogRef.close();
            }
            else {
                _this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', { duration: 2000 });
            }
        });
    };
    EditDrDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return EditDrDialogComponent;
}());
export { EditDrDialogComponent };
var ViewInfoComponent = /** @class */ (function () {
    function ViewInfoComponent(dialogRef, hubService, data, translate, drService, snackBar) {
        this.dialogRef = dialogRef;
        this.hubService = hubService;
        this.data = data;
        this.translate = translate;
        this.drService = drService;
        this.snackBar = snackBar;
        this.dataSource = new MatTableDataSource(this.data);
        this.displayedColumns2 = ['material', 'handlingUnit', 'packedQty', 'delivery', 'so', 'shipment'];
        this.role = data.role;
    }
    ViewInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.data);
        this.drService.getAllInfo(this.data.dr.idDRidentifier).subscribe(function (elem) {
            console.log(elem);
            _this.dataSource = new MatTableDataSource(elem);
        });
    };
    ViewInfoComponent.prototype.onYesClick = function () {
        if (this.previous.select === this.select
            && this.previous.dateCollect === this.dateCollect
            && this.previous.hour === this.hour
            && this.previous.deliveryAddress === this.data.dr.deliveryAddress) {
            this.snackBar.open('Nenhum valor foi alterado', 'Ok', { duration: 2000 });
            this.dialogRef.close();
            return;
        }
        if (this.select === 'HubReturn') {
            var newHUBDR = new HUBDR();
            newHUBDR.hubID = this.hubId;
            newHUBDR.drID = this.data.dr.id;
            newHUBDR.when = new Date();
            newHUBDR.observation = this.hubObs;
            this.hubService.createHubDr(newHUBDR).subscribe(function (elem) {
            });
        }
        // if (this.previous === 'HubReturn') {
        //
        // }
        this.data.dr.status = this.select;
        if (this.hour !== undefined && this.dateCollect !== undefined) {
            // console.log('cheguei!');
            var time = this.hour.split(':');
            this.data.dr.dateScheduling = new Date(this.dateCollect.setHours(parseInt(time[0], 0), parseInt(time[1], 0)));
        }
        //// console.log(this.data);
        // this.drService.update(this.data).subscribe( (el) => {
        //   if( el === true ){
        //     this.snackBar.open('Shipmet updated', 'Ok', {duration: 2000});
        //     this.dialogRef.close();
        //   } else {
        //     this.snackBar.open('Erro in update Shipmet', 'Ok', {duration: 2000});
        //   }
        // });
    };
    ViewInfoComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return ViewInfoComponent;
}());
export { ViewInfoComponent };
var HistoryDialogComponent = /** @class */ (function () {
    function HistoryDialogComponent(data, datepipe) {
        var _this = this;
        this.data = data;
        this.datepipe = datepipe;
        this.history = [];
        this.columns = [
            { value: 'deliveryAddress', name: 'DELIVERY_ROUTE/address' },
            { value: 'status', name: 'DELIVERY_ROUTE/status' },
            { value: 'quem', name: 'DELIVERY_ROUTE/by' },
            { value: 'quando', name: 'DELIVERY_ROUTE/on' },
            { value: 'dateScheduling', name: 'DELIVERY_ROUTE/date' }
        ];
        this.history = data.history;
        this.history.forEach(function (day) {
            day.dateScheduling = new Date(day.dateScheduling);
            day.dateScheduling = _this.datepipe.transform(day.dateScheduling, 'dd/MM/yyyy hh:mm:ss');
            day.quando = new Date(day.quando);
            day.quando = _this.datepipe.transform(day.quando, 'dd/MM/yyyy hh:mm:ss');
        });
    }
    HistoryDialogComponent.prototype.ngOnInit = function () {
    };
    return HistoryDialogComponent;
}());
export { HistoryDialogComponent };
