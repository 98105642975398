var ZZTPXHEADER = /** @class */ (function () {
    function ZZTPXHEADER() {
        this.id = null;
        this.shipment = null;
        this.country = null;
        this.salesOrder = null;
        this.customerPO = null;
        this.delivery = null;
        this.incoterms1 = null;
        this.shippingTypeDescription = null;
        this.delRouteDescription = null;
        this.shppnt = null;
        this.shppntName = null;
        this.plantName = null;
        this.plant = null;
        this.shipToPartyName = null;
        this.city = null;
        this.forwardingAgent = null;
        this.forwardingAgentName = null;
        this.plannedLoadEnd = null;
        this.shipmentStartStatus = null;
        this.actLoadingEnd = null;
        this.plannedShipmentEnd = null;
        this.actShipmentCompleteDate = null;
        this.extId1 = null;
        this.extId2 = null;
        this.insertedBy = null;
        this.updatedBy = null;
        this.insertDate = null;
        this.updateDate = null;
        this.active = null;
    }
    return ZZTPXHEADER;
}());
export { ZZTPXHEADER };
