/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./pages/header/header.component.ngfactory";
import * as i4 from "./pages/header/header.component";
import * as i5 from "./services/sidenav.service";
import * as i6 from "./providers/dr.service";
import * as i7 from "./providers/mdr.service";
import * as i8 from "../../node_modules/@angular/material/sidenav/typings/index.ngfactory";
import * as i9 from "@angular/material/sidenav";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "@angular/cdk/scrolling";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "@angular/cdk/platform";
import * as i15 from "@angular/common";
import * as i16 from "./pages/menu/menu.component.ngfactory";
import * as i17 from "./pages/menu/menu.component";
import * as i18 from "./providers/auth.service";
import * as i19 from "../../node_modules/ng4-loading-spinner/ng4-loading-spinner.ngfactory";
import * as i20 from "ng4-loading-spinner";
import * as i21 from "./app.component";
import * as i22 from "@ngx-translate/core";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header-component", [["class", "mat-elevation-z3"]], null, null, null, i3.View_HeaderComponent_0, i3.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i4.HeaderComponent, [i5.SideNavService, i2.Router, i6.DrService, i7.MdrService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 11, "mat-drawer-container", [["class", "mat-drawer-container"]], [[2, "mat-drawer-container-explicit-backdrop", null]], null, null, i8.View_MatDrawerContainer_0, i8.RenderType_MatDrawerContainer)), i1.ɵdid(4, 1490944, null, 2, i9.MatDrawerContainer, [[2, i10.Directionality], i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef, i9.MAT_DRAWER_DEFAULT_AUTOSIZE, [2, i11.ANIMATION_MODULE_TYPE], [2, i12.ViewportRuler]], null, null), i1.ɵqud(603979776, 1, { _drawers: 1 }), i1.ɵqud(335544320, 2, { _content: 0 }), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "mat-drawer", [["class", "mat-elevation-z3 mat-drawer"], ["mode", "side"], ["opened", ""], ["tabIndex", "-1"]], [[40, "@transform", 0], [1, "align", 0], [2, "mat-drawer-end", null], [2, "mat-drawer-over", null], [2, "mat-drawer-push", null], [2, "mat-drawer-side", null]], [["component", "@transform.start"], ["component", "@transform.done"]], function (_v, en, $event) { var ad = true; if (("component:@transform.start" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._animationStarted.next($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@transform.done" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8)._animationEnd.next($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_MatDrawer_0, i8.RenderType_MatDrawer)), i1.ɵdid(8, 3325952, [[1, 4]], 0, i9.MatDrawer, [i1.ElementRef, i13.FocusTrapFactory, i13.FocusMonitor, i14.Platform, i1.NgZone, [2, i15.DOCUMENT]], { mode: [0, "mode"], opened: [1, "opened"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-menu-component", [], null, null, null, i16.View_MenuComponent_0, i16.RenderType_MenuComponent)), i1.ɵdid(10, 49152, null, 0, i17.MenuComponent, [i18.AuthService, i2.Router, i6.DrService], null, null), (_l()(), i1.ɵeld(11, 0, null, 1, 3, "mat-drawer-content", [["class", "mat-drawer-content"]], [[4, "margin-left", "px"], [4, "margin-right", "px"]], null, null, i8.View_MatDrawerContent_0, i8.RenderType_MatDrawerContent)), i1.ɵdid(12, 1294336, [[2, 4]], 0, i9.MatDrawerContent, [i1.ChangeDetectorRef, i9.MatDrawerContainer, i1.ElementRef, i12.ScrollDispatcher, i1.NgZone], null, null), (_l()(), i1.ɵeld(13, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(14, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); var currVal_7 = "side"; var currVal_8 = ""; _ck(_v, 8, 0, currVal_7, currVal_8); _ck(_v, 12, 0); _ck(_v, 14, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4)._backdropOverride; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 8)._animationState; var currVal_2 = null; var currVal_3 = (i1.ɵnov(_v, 8).position === "end"); var currVal_4 = (i1.ɵnov(_v, 8).mode === "over"); var currVal_5 = (i1.ɵnov(_v, 8).mode === "push"); var currVal_6 = (i1.ɵnov(_v, 8).mode === "side"); _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 12)._container._contentMargins.left; var currVal_10 = i1.ɵnov(_v, 12)._container._contentMargins.right; _ck(_v, 11, 0, currVal_9, currVal_10); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng4-loading-spinner", [], null, null, null, i19.View_Ng4LoadingSpinnerComponent_0, i19.RenderType_Ng4LoadingSpinnerComponent)), i1.ɵdid(1, 180224, null, 0, i20.Ng4LoadingSpinnerComponent, [i20.Ng4LoadingSpinnerService], { threshold: [0, "threshold"], timeout: [1, "timeout"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 1000; var currVal_1 = (4 * 1000); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = !_co.auth.isAuthenticated(); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.auth.isAuthenticated(); _ck(_v, 5, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i21.AppComponent, [i18.AuthService, i22.TranslateService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i21.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
