import { OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TranslateService } from '@ngx-translate/core';
var CostKgChartComponent = /** @class */ (function () {
    function CostKgChartComponent(translate) {
        this.translate = translate;
        this.noData = false;
        this.translations = {
            title: '',
            seriesName: []
        };
        this.translations.title = this.translate.instant('COST_PER_KG');
        this.translations.seriesName.push(this.translate.instant('COST_KG'));
        this.translations.seriesName.push(this.translate.instant('COST'));
        this.translations.seriesName.push(this.translate.instant('WEIGTH'));
    }
    CostKgChartComponent.prototype.ngOnInit = function () {
    };
    CostKgChartComponent.prototype.createChart = function (data) {
        if (data.length > 0) {
            this.noData = false;
            this.chart = new Chart({
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: data.map(function (day) { return day.day; }),
                },
                credits: {
                    enabled: false
                },
                series: [{
                        name: this.translations.seriesName[0],
                        type: undefined,
                        data: data.map(function (day) { return day.med; })
                    }, {
                        name: this.translations.seriesName[1],
                        visible: false,
                        type: 'line',
                        data: data.map(function (day) { return day.cost; })
                    }, {
                        name: this.translations.seriesName[2],
                        visible: false,
                        type: 'line',
                        data: data.map(function (day) { return day.weight; })
                    }]
            });
        }
        else {
            this.noData = true;
        }
    };
    return CostKgChartComponent;
}());
export { CostKgChartComponent };
