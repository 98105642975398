import { Injector } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { ForgotComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotMobileComponent } from './pages/forgot-password/forgot-password-mobile.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DeliveryRequestComponent } from './pages/delivery-request/delivery-request.component';
import { DeliveryOptimizerComponent } from './pages/delivery-optimizer/delivery-optimizer.component';
import { RoleGuardService } from './providers/role-guard.service';
import { DeliveryOrderComponent } from './pages/delivery-order/delivery-order.component';
import { ChatComponent } from './pages/chat/chat.component';
import { ShippingCompanyAdminComponent } from './pages/shipping-company-admin/shipping-company-admin.component';
import { NewMdrRequestComponent } from './pages/new-mdr-request/new-mdr-request.component';
import { MdrManagementComponent } from './pages/mdr-management/mdr-management.component';
import { OccurrenceListComponent } from './pages/occurrence-list/occurrence-list.component';
import { FinanceListComponent } from './pages/finance-list/finance-list.component';
import { DrBookingComponent } from './pages/dr-booking/dr-booking.component';
import { DrValidationComponent } from './pages/dr-booking/dr-validation/dr-validation.component';
import { AnnexComponent } from './pages/logistic-order/annex/annex.component';
import { InsertNfeComponent } from './pages/insert-nfe/insert-nfe.component';
import { BulkNfeComponent } from './pages/bulk-nfe-upload/bulk-nfe-upload.component';
import { ClientUserComponent } from './pages/client-user/client-user.component';
import localept from '@angular/common/locales/pt';
import { NewDeliveryRequestComponent } from './pages/new-delivery-request/new-delivery-request.component';
import { MdrLspComponent } from './pages/mdr-lsp/mdr-lsp.component';
import { PriceMakerComponent } from './pages/price-maker/price-maker.component';
import { PriceMakerTableComponent } from './pages/price-maker/table/price-maker-table.component';
import { MdrMapComponent } from './pages/mdr-map/mdr-map.component';
registerLocaleData(localept, 'pt');
import { HubComponent } from './pages/hub/hub.component';
import { AddHubComponent } from './pages/add-hub/add-hub.component';
import { MdrOptimizerComponent } from './pages/mdr-optimizer/mdr-optimizer.component';
import { InsertDataComponent } from './pages/insert-data/insert-data.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { LspUserAdminComponent } from './pages/lsp-user-admin/lsp-user-admin.component';
import { NewShipmentRequestComponent } from './pages/new-shipment-request/new-shipment-request.component';
import { ShipmentOptimizerComponent } from './pages/shipment-optimizer/shipment-optimizer.component';
import { SiteInvestigationComponent } from './pages/site-investigation/site-investigation.component';
import { SiteInvestigationWithTokenComponent } from './pages/site-investigation-with-token/site-investigation-with-token.component';
import { SiteInvestigationReportComponent } from './pages/site-investigation-report/site-investigation-report.component';
import { DocsCntrlComponent } from './pages/docs-cntrl/docs-cntrl.component';
import { OvsearchComponent } from './pages/ovsearch/ovsearch.component';
import { VehicleCalculatorComponent } from './pages/vehicle-calculator/vehicle-calculator.component';
import { AirportsComponent } from './pages/airports/airports.component';
var appRoutes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'forgot', component: ForgotComponent },
    { path: 'forgot-mobile', component: ForgotMobileComponent },
    { path: 'dr-booking', component: DrBookingComponent, canActivate: [RoleGuardService] },
    { path: 'dr-validation/:id', component: DrValidationComponent, canActivate: [RoleGuardService] },
    { path: 'delivery-requests', component: DeliveryRequestComponent, canActivate: [RoleGuardService] },
    { path: 'new-delivery-request', component: NewDeliveryRequestComponent, canActivate: [RoleGuardService] },
    { path: 'delivery-optimizer', component: DeliveryOptimizerComponent, canActivate: [RoleGuardService] },
    { path: 'list-mdrs', component: DeliveryOrderComponent, canActivate: [RoleGuardService] },
    { path: 'mdr-lsp', component: MdrLspComponent, canActivate: [RoleGuardService] },
    { path: 'delivery-order', component: DeliveryOrderComponent, canActivate: [RoleGuardService] },
    { path: 'attachments', component: AnnexComponent, canActivate: [RoleGuardService] },
    { path: 'shipping-company-admin', component: ShippingCompanyAdminComponent, canActivate: [RoleGuardService] },
    { path: 'new-mdr-request', component: NewMdrRequestComponent, canActivate: [RoleGuardService] },
    { path: 'price-maker', component: PriceMakerComponent, canActivate: [RoleGuardService] },
    { path: 'price-maker/table', component: PriceMakerTableComponent, canActivate: [RoleGuardService] },
    { path: 'mdr-management', component: MdrManagementComponent, canActivate: [RoleGuardService] },
    { path: 'insert-nfe', component: InsertNfeComponent, canActivate: [RoleGuardService] },
    { path: 'bulk-nfe-upload', component: BulkNfeComponent, canActivate: [RoleGuardService] },
    { path: 'occurrence-list', component: OccurrenceListComponent, canActivate: [RoleGuardService] },
    { path: 'finance-list', component: FinanceListComponent, canActivate: [RoleGuardService] },
    { path: 'chat/:id/:type', component: ChatComponent, canActivate: [RoleGuardService] },
    { path: 'insert-nfe', component: InsertNfeComponent, canActivate: [RoleGuardService] },
    { path: 'client-tp', component: ClientUserComponent, canActivate: [RoleGuardService] },
    { path: 'mdr-map', component: MdrMapComponent, canActivate: [RoleGuardService] },
    { path: 'hub', component: HubComponent, canActivate: [RoleGuardService] },
    { path: 'add-hub', component: AddHubComponent, canActivate: [RoleGuardService] },
    { path: 'mdr-optimizer', component: MdrOptimizerComponent, canActivate: [RoleGuardService] },
    { path: 'insert-new-data', component: InsertDataComponent, canActivate: [RoleGuardService] },
    { path: 'add-hub/:id', component: AddHubComponent, canActivate: [RoleGuardService] },
    { path: 'lsp-user-admin', component: LspUserAdminComponent, canActivate: [RoleGuardService] },
    { path: 'new-shipment-request', component: NewShipmentRequestComponent, canActivate: [RoleGuardService] },
    { path: 'shipment-optimizer', component: ShipmentOptimizerComponent, canActivate: [RoleGuardService] },
    { path: 'site-investigation', component: SiteInvestigationComponent, canActivate: [RoleGuardService] },
    { path: 'site-report/:siteid', component: SiteInvestigationReportComponent, canActivate: [RoleGuardService] },
    { path: 'sheets', component: DocsCntrlComponent, canActivate: [RoleGuardService] },
    { path: 'site-investigation/:siteid/:token', component: SiteInvestigationWithTokenComponent, canDeactivate: [RoleGuardService] },
    { path: 'reports', component: ReportsComponent, canActivate: [RoleGuardService] },
    { path: 'airports', component: AirportsComponent, canActivate: [RoleGuardService] },
    { path: 'vehicle-calculator', component: VehicleCalculatorComponent, canActivate: [RoleGuardService] },
    { path: 'ovsearch/:input', component: OvsearchComponent, canActivate: [RoleGuardService] },
    { path: '**', component: PageNotFoundComponent }
];
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
export function appInitializerFactory(translate, injector) {
    return function () { return new Promise(function (resolve) {
        translate.setDefaultLang('en');
        // Uncomment for set language based on the browser preferences
        var browserLang = translate.getBrowserLang();
        // console.log(browserLang);
        if (browserLang === undefined) {
            browserLang = 'pt';
        }
        translate.use(browserLang).subscribe(function () {
            console.log("Successfully initialized language.'");
        }, function (err) {
            console.error("Problem with language initialization.'");
        }, function () {
            resolve(null);
        });
    }); };
}
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
