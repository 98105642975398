import { OnInit } from '@angular/core';
import { MatTableDataSource, MatSort, MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DrService } from '../../../providers/dr.service';
import { DrObservable } from '../../../services/dr.service';
import { TranslateService } from '@ngx-translate/core';
import { HubService } from '../../../providers/hub.service';
import { OVService } from '../../../providers/ov.service';
import { ShipmentService } from '../../../providers/shipment.service';
import { MDR } from '../../../models/mdr';
import { Airport } from '../../../models/airport';
import { Airway } from '../../../models/airway';
var AirportComponent = /** @class */ (function () {
    function AirportComponent(aRoute, drService, shipmentService, drObservable, dialog, ovService, snackBar) {
        this.aRoute = aRoute;
        this.drService = drService;
        this.shipmentService = shipmentService;
        this.drObservable = drObservable;
        this.dialog = dialog;
        this.ovService = ovService;
        this.snackBar = snackBar;
        this.data = [];
        this.showAirport = false;
        this.dataSource = new MatTableDataSource(this.data);
        this.displayedColumns = ['idDRidentifier', 'deliveryAddress', 'receiverName', 'receiverPhone', 'supervisorName', 'supervisorPhone', 'site', 'dateScheduling', 'status', 'obs', 'action'];
        this.airportList = [];
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        if (this.role === 5) {
            this.displayedColumns = ['idDRidentifier', 'deliveryAddress', 'receiverName', 'receiverPhone', 'supervisorName', 'supervisorPhone', 'site', 'dateScheduling', 'status', 'obs'];
        }
    }
    AirportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.shipmentService.getAirways(this.mdr.id).subscribe(function (aList) {
            console.log('airWays');
            console.log(aList);
            _this.airportList = aList;
            if (_this.airportList.length === 0) {
                var newAirway_1 = new Airway();
                newAirway_1.airportId = 28257;
                newAirway_1.mdrID = _this.mdr.id;
                _this.shipmentService.insertAirway(newAirway_1).subscribe(function (response_1) {
                    var newAirway_2 = new Airway();
                    newAirway_2.airportId = 28256;
                    newAirway_2.mdrID = _this.mdr.id;
                    _this.shipmentService.insertAirway(newAirway_2).subscribe(function (response_2) {
                        var newAirway_3 = new Airway();
                        newAirway_3.airportId = 28256;
                        newAirway_3.mdrID = _this.mdr.id;
                        _this.shipmentService.insertAirway(newAirway_3).subscribe(function (response_3) {
                            var newAirway_4 = new Airway();
                            newAirway_4.airportId = 28258;
                            newAirway_4.mdrID = _this.mdr.id;
                            _this.shipmentService.insertAirway(newAirway_4).subscribe(function (response_4) {
                                _this.shipmentService.getAirways(_this.mdr.id).subscribe(function (naList) {
                                    _this.airportList = naList;
                                    _this.airportList.forEach(function (el) {
                                        if (el.mawb === undefined) {
                                            el.mawb = '';
                                        }
                                    });
                                    _this.showAirport = true;
                                });
                            });
                        });
                    });
                });
                console.log(_this.airportList);
            }
            else {
                _this.airportList.forEach(function (el) {
                    if (el.mawb === undefined) {
                        el.mawb = '';
                    }
                });
                _this.showAirport = true;
            }
        });
    };
    AirportComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(EditAirplaneComponent, {
            data: { airportList: this.airportList, mdr: this.mdr },
            width: '600px',
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response !== undefined) {
                _this.showAirport = false;
                _this.shipmentService.getAirways(_this.mdr.id).subscribe(function (elem) {
                    elem.forEach(function (el) {
                        if (el.mawb === undefined) {
                            el.mawb = '';
                        }
                    });
                    console.log(elem);
                    _this.airportList = elem;
                    _this.showAirport = true;
                });
            }
        });
    };
    AirportComponent.prototype.openInfoAirport = function (elem) {
        console.log(elem.airport);
        this.snackBar.open(elem.airport.name, null, { duration: 3000 });
    };
    return AirportComponent;
}());
export { AirportComponent };
var EditAirplaneComponent = /** @class */ (function () {
    function EditAirplaneComponent(dialogRef, hubService, shipmentService, data, translate, drService, snackBar) {
        this.dialogRef = dialogRef;
        this.hubService = hubService;
        this.shipmentService = shipmentService;
        this.data = data;
        this.translate = translate;
        this.drService = drService;
        this.snackBar = snackBar;
        this.dataReceived = [];
        this.dataSource = new MatTableDataSource();
        this.displayedColumns = ['airport', 'eta', 'etd', 'ata', 'status', 'awb', 'order', 'action'];
        this.status = [
            { id: '1', status: 'Aguardando Embarque' },
            { id: '2', status: 'Em Viagem' },
            { id: '3', status: 'Embarque Confirmado' },
            { id: '4', status: 'Liberação Aduaneira' },
            { id: '5', status: 'Voo atrasado' },
            { id: '6', status: 'Viagem finalizada' }
        ];
    }
    EditAirplaneComponent.prototype.ngOnInit = function () {
        var _this = this;
        for (var _i = 0, _a = this.data.airportList; _i < _a.length; _i++) {
            var el = _a[_i];
            el.edit = false;
            el.remove = false;
            el.airportIATA = el.airport.iata;
            this.dataReceived.push(el);
        }
        this.shipmentService.getAirports().subscribe(function (elem) {
            _this.airports = elem;
        });
        this.dataSource = new MatTableDataSource(this.dataReceived);
    };
    EditAirplaneComponent.prototype.selectDate = function (x) {
        // TODO
    };
    EditAirplaneComponent.prototype.removeAirport = function (element) {
        if (element.id !== null) {
            this.shipmentService.removeAirway(element).subscribe(function (elem) {
                console.log('removed');
            });
        }
        for (var i = 0; i < this.dataReceived.length; i++) {
            if (this.dataReceived[i] === element) {
                this.dataReceived.splice(i, 1);
                this.dataSource = new MatTableDataSource(this.dataReceived);
            }
        }
    };
    EditAirplaneComponent.prototype.addAirport = function () {
        this.dataReceived.push({ id: null, eta: new Date(), etd: null, airport: new Airport(), color: 'green', rtd: null });
        this.dataSource = new MatTableDataSource(this.dataReceived);
    };
    // function to check if airport is editable and is not part of nonEditable list
    EditAirplaneComponent.prototype.editableAirport = function (element) {
        if (element.edit) {
            var nonEditableAirportIds = [28256, 28257, 28258];
            return !nonEditableAirportIds.includes(element.airport.id);
        }
        else {
            return false;
        }
    };
    EditAirplaneComponent.prototype.showInput = function (element) {
        element.edit = true;
    };
    EditAirplaneComponent.prototype.onYesClick = function () {
        this.data.airportList = [];
        var _loop_1 = function (el) {
            var airportCreated = this_1.airports.filter(function (airport) { return airport.iata === el.airportIATA; })[0];
            if (el.remove === true) {
                console.log('Removing');
                this_1.shipmentService.removeAirway(el).subscribe(function (elem) {
                    console.log('removed');
                });
            }
            else {
                if (airportCreated === null || airportCreated === undefined) {
                    this_1.snackBar.open('Não foi possível encontrar um ou mais aeroportos', 'OK', { duration: 4000 });
                }
                else {
                    if (this_1.editableAirport(airportCreated)) {
                        this_1.shipmentService.updateAirway({ airport: { iata: el.airportIATA }, id: el.airport.id }).subscribe(function (elm) {
                            console.log('Atualizado corretamente');
                        });
                    }
                    else {
                        el.airport = airportCreated;
                        el.airportId = airportCreated.id;
                        el.mdrID = this_1.data.mdr.id;
                        console.log(el);
                        this_1.shipmentService.insertAirway(el).subscribe(function (elm) {
                            console.log('Inserido corretamente');
                        });
                        this_1.data.airportList.push(el);
                    }
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = this.dataReceived; _i < _a.length; _i++) {
            var el = _a[_i];
            _loop_1(el);
        }
        this.dialogRef.close(this.data.airportList);
    };
    EditAirplaneComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return EditAirplaneComponent;
}());
export { EditAirplaneComponent };
