import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import {DrService} from '../../providers/dr.service';
import {DR} from '../../models/dr';
import {MDR} from '../../models/mdr';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ShipmentService } from '../../providers/shipment.service';
import { Shipment } from '../../models/shipment';

@Component({
  selector: 'app-shipment-optimizer',
  templateUrl: './shipment-optimizer.component.html',
  styleUrls: ['./shipment-optimizer.component.scss']
})

export class ShipmentOptimizerComponent implements OnInit {
  public searchControl: FormControl;
  inputOVs: number;
  opened: any;
  data: any[] = [];
  showLightBox = false;
  filter = '';

  columns = [
    { value: 'shipment', name: 'Shipment' },
    { value: 'city', name: 'City' },
    { value: 'shippingTypeDescription', name: 'Description' },
    { value: 'forwardingAgentName', name: 'FWDAgent' },
    { value: 'plannedShipmentEnd', name: 'Due Date' },
  ];
  selected: Shipment[] = [];

  constructor(public dialog: MatDialog, private mapsAPILoader: MapsAPILoader,
              private drService: DrService, private shipmentService: ShipmentService,
              public mdrService: MdrService, public router: Router, public snackBar: MatSnackBar, public dpipe: DatePipe) {

   }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.shipmentService.getAllShipment().subscribe(
      (data2) => {
        this.data = data2.map((row) => {
          // extract some fields to root level
          return Object.assign({
            shipment: row.zzt.shipment,
            city: row.zzt.city,
            shippingTypeDescription: row.zzt.shippingTypeDescription,
            forwardingAgentName: row.zzt.forwardingAgentName,
            plannedShipmentEnd: new Date(row.zzt.plannedShipmentEnd),
          }, row);
        });
      },
      (err) => {
        this.snackBar.open('Erro ao buscar shipments', 'Ok', {duration: 4000});
      }
    );
  }

  unmakeShipment() {
    this.shipmentService.unmakeShipments(this.selected).subscribe(
      (data) => {
        this.selected = [];
        this.snackBar.open('Shipments desfeitos com sucesso', 'Ok', {duration: 4000});
        this.getData();
      },
      (err) => {
        this.snackBar.open('Erro ao desfazer shipments', 'Ok', {duration: 4000});
      }
    );
  }

  createNewMDR() {
    const mdr: any = new MDR();
    mdr.shipmentList = [];
    mdr.status = 'MDR em criação';
    this.selected.forEach((el: Shipment) => {
      mdr.shipmentList.push(el);
    });

    console.log(mdr.shipmentList);
    if (mdr.shipmentList[0].zzt.shippingTypeDescription === 'Air transport' || mdr.shipmentList[0].zzt.shippingTypeDescription === 'Mail / Courier') {
      mdr.airport = true;
    } else if (mdr.shipmentList[0].zzt.shippingTypeDescription === 'Maritime transport') {
      mdr.airport = false;
    }

    this.mdrService.insertPickup(mdr).subscribe( (data) => {
     //// console.log('MDR new Service');
     console.log(data);
      this.mdrService.createLegs(data.id, false).subscribe((legs) => {
       //// console.log(legs);
      });
      this.getData();
    });
  }

  onSelection(selected) {
    this.selected = selected;
  }

}
