import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogisticRoutingModule } from './logistic-routing.module';

import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {AgmCoreModule, MarkerManager} from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import {MatDialogModule} from '@angular/material/dialog';
import {MatStepperModule} from '@angular/material/stepper';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import {MatIconModule,
  MatDatepickerModule,
  MatCardModule,
  MatTooltipModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatPaginator,
  MatExpansionModule,
  MatButtonModule,
  MatTableDataSource,
  MatCheckboxModule} from '@angular/material';

import { GeolocationComponent } from './geolocation/geolocation.component';
import {DeliveryRouteComponent, ViewInfoComponent, EditDrDialogComponent, HistoryDialogComponent} from './delivery-route/delivery-route.component';
import { TransportComponent } from './transport/transport.component';
import { TaxComponent, ConfirmDeleteDialogComponent } from './tax/tax.component';
import { OccurrenceComponent } from './occurrence/occurrence.component';
import {AttachmentsComponent} from './attachments/attachments.component';
import { AirportComponent, EditAirplaneComponent } from './airport/airport.component';

import { ImageLightboxModule } from '../../misc/image-lightbox/image-lightbox.module';
import {UploadComponent} from '../../misc/upload/upload.component';
import {PipesModule} from '../../misc/pipes/pipes.module';

import { LogisticOrderComponent, MdrHistoryDialogComponent} from './logistic-order.component';
import { SlaComponent } from './sla/sla.component';
import { TableModule } from '../../misc/table/table.module';

@NgModule({
  imports: [
    MatPaginatorModule,
    MatButtonModule,
    CommonModule,
    TranslateModule,
    MatChipsModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    MatTableModule,
    MatStepperModule,
    TableModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    PipesModule,
    LogisticRoutingModule,
    MatSelectModule,
    MatExpansionModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ImageLightboxModule,
    AgmDirectionModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAbtxRrJstl_v2wIpqaJK_FRcTN-UsI5ns',
      libraries: ['places'],
      language: 'pt'
    }),
  ],
  declarations: [
    LogisticOrderComponent,
    GeolocationComponent,
    DeliveryRouteComponent,
    ViewInfoComponent,
    EditDrDialogComponent,
    ConfirmDeleteDialogComponent,
    TransportComponent,
    TaxComponent,
    OccurrenceComponent,
    AttachmentsComponent,
    AirportComponent,
    EditAirplaneComponent,
    UploadComponent,
    SlaComponent,
    EditAirplaneComponent,
    HistoryDialogComponent,
    MdrHistoryDialogComponent
  ],
  entryComponents: [
    EditAirplaneComponent,
    ViewInfoComponent,
    TransportComponent,
    ConfirmDeleteDialogComponent,
    EditDrDialogComponent,
    HistoryDialogComponent,
    MdrHistoryDialogComponent
  ],
  exports: []
})
export class LogisticOrderModule { }
