<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'TAX/title' | translate }}
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-6">
          <span class="title" style="margin-left:-10px">{{ 'TAX/ordinary' | translate }}</span>
        </div>
        <div class="col-6" style="text-align:right"><i (click)="getAllPriceWithKm()" matTooltip="{{ 'TAX/recalculate' | translate }}" font class="fa fa-calculator click-button" style="font-size: 12px; margin-left: 10px;" aria-hidden="true"></i>
         <button mat-icon-button (click)="download()">
           <i class="fas fa-file-download"></i>
        </button>
        </div>
      </div>
      <div class="row" *ngIf="nfePrice !== undefined" >
        <mat-form-field class="col-md-12">
          <input disabled matInput type="text" placeholder="{{ 'TAX/value_nf_system' | translate }}" [ngModel]="nfePrice | currency:'BRL'">
        </mat-form-field>
      </div>
      <div class="row" *ngIf="price !== undefined" >
        <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input1 *ngIf="update" type="text" placeholder="{{ 'TAX/value_nf' | translate }}" [ngModel]="mdr.totalNF | currency:'BRL'" (blur)="sanitizeInputMdr('totalNF', $event, input1)">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Valor Total de NF:<span> {{mdr.totalNF | currency:'BRL'}}</span></p>
        </div>
        <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput type="text" placeholder="{{ 'TAX/total_km' | translate }}" [(ngModel)]="mdr.totalKM">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Distância:<span> {{mdr.totalKM}} KM</span></p>
        </div>
        <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input2 *ngIf="update" type="text" placeholder="{{ 'TAX/freight' | translate }}" [ngModel]="price.price | currency:'BRL'"  (blur)="sanitizeInputPrice('price', $event, input2)">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Frete:<span> {{price.price | currency:'BRL'}}</span></p>
        </div>
      </div>
      <div class="row" *ngIf="price !== undefined">
        <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input4 *ngIf="update" type="text" placeholder="{{ 'TAX/tax_freight' | translate }}" [ngModel]="price.taxedPrice | currency:'BRL'"  (blur)="sanitizeInputPrice('taxedPrice',$event, input4)">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Frete com Taxas:<span> {{price.taxedPrice | currency:'BRL'}}</span></p>
        </div>
        <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input5 *ngIf="update" type="text" placeholder="{{ 'TAX/ad_valorem' | translate }}" [ngModel]="price.adValorem | currency:'BRL'"  (blur)="sanitizeInputPrice('adValorem', $event, input5)">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Ad Valorem:<span> {{price.adValorem | currency:'BRL'}}</span></p>
        </div>
        <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input6 *ngIf="update" type="text" placeholder="{{ 'TAX/gris' | translate }}" [ngModel]="price.gris | currency:'BRL'"  (blur)="sanitizeInputPrice('gris',$event, input6)">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Gris:<span> {{price.gris | currency:'BRL'}}</span></p>
        </div>
      </div>
      <div class="row" *ngIf="price !== undefined">
        <mat-form-field *ngIf="role !== 5" class="col-md-6">
          <input matInput #input7 *ngIf="update" type="text" placeholder="{{ 'TAX/total' | translate }}" [ngModel]="price.total | currency:'BRL'"  (blur)="sanitizeInputPrice('total', $event, input7)">
        </mat-form-field>
        <div class="col-md-5">
          <p *ngIf="role === 5" >Total: <span> {{price.total | currency:'BRL'}}</span></p>
        </div>
        <div class="col-md-1" style="text-align:right">
          <i class="fa" [ngClass]="payField.color ? [ payment.icons[payField.color] , payment.classes[payField.color] ] : ''" matSuffix [matTooltip]="payment.tooltips[payField.color]"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9" class="pad">
          <span class="title">{{ 'TAX/uoc' | translate }}</span>
        </div>
      </div>
      <div *ngFor="let UOC of UOCs;let i = index;">
       <div class="row" >
        <p class="col-md-6"> {{UOC.uoc}}</p>
        <p class="col-md-3">{{UOC.value | currency: 'BRL'}}</p>
        <p class="col-md-2">
          <i class="fa fa-times" (click)='removeUOC(UOC)' *ngIf="role === 1"></i>
        </p>
        <p class="col-md-1" style="text-align:right">
        <i class="fa" [ngClass]="[ payment.icons[UOC.color] , payment.classes[UOC.color] ]" matSuffix [matTooltip]="payment.tooltips[UOC.color]"></i>
        </p>
       </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <span class="title-taxa">{{ 'TAX/uoc_total' | translate }} </span>
          <span class="data-taxa">{{sumUOC*100 | currency:'BRL'}}</span>
        </div>
      </div>
     <div *ngIf="role !== 5">
      <div class="row">
        <div class="col-md-9" class="pad">
          <span class="title">{{ 'TAX/copq' | translate }}</span>
        </div>
      </div>
      <div *ngFor="let COPQ of COPQs; let i = index;">
        <div class ="row">
          <p class="col-md-6"> {{COPQs[i].copq}}</p>
          <p class="col-md-3">{{COPQs[i].value | currency: 'BRL'}}</p>
          <p class="col-md-2"><i class="fa fa-times" (click)='removeCOPQ(COPQs[i])' *ngIf="role === 1"></i></p>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-9">
          <span class="title-taxa">{{ 'TAX/copq_total' | translate }} </span>
          <span class="data-taxa">{{ sumCOPQ | currency:'BRL' }}</span>
        </div>
      </div>
      </div>
      <div *ngFor="let COPQ of COPQs;let i = index;">
        <div class="row" >
          <mat-form-field class="col-md-3">
            <mat-select   placeholder="{{ 'TAX/type' | translate }}" [(ngModel)]="COPQs[i].copq">
              <mat-option *ngFor="let elem of copq" [value]="elem.name">
                {{ elem.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-md-3">
            <input    matInput type="text" placeholder="{{ 'TAX/value' | translate }}"  [(ngModel)]="COPQs[i].value">
          </mat-form-field>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-9">
          <span class="title-taxa">{{ 'TAX/total' | translate }}: </span>
          <span class="data-taxa">{{amountCOPQ() | currency:'BRL'}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
