import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DrService } from '../../../../providers/dr.service';
var DrCardComponent = /** @class */ (function () {
    function DrCardComponent(route, router, drService) {
        this.route = route;
        this.router = router;
        this.drService = drService;
        this.tooltip = [];
        this.pickupID = null;
        this.shippingCompany = '';
        this.icons = {
            'red': 'fa-exclamation-circle',
            'yellow': 'fa-exclamation-triangle',
            'green': '',
            'undefined': ''
        };
    }
    DrCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.token = this.route.snapshot.paramMap.get('token');
        this.drService.getDrWithToken(this.token).subscribe(function (data) {
            if (!data) {
                return;
            }
            _this.dr = data;
            _this.setStatusColor();
            if (_this.dr.idMDR !== null) {
                _this.shippingCompany = _this.dr.mdr.shippingCompany;
                _this.pickupID = _this.dr.mdr.pickupID;
            }
        });
    };
    DrCardComponent.prototype.getStatusClass = function () {
        return 'dr-status-' + this.color;
    };
    DrCardComponent.prototype.setStatusColor = function () {
        this.tooltip = [];
        this.color = 'green';
        var hora = 1000 * 60 * 60;
        var now = Date.now();
        if (this.dr === null) {
            return;
        }
        // verifica as ocorrencias da MDR
        if (this.dr.occurrences.length > 0) {
            var occurrencesNotSolved = [];
            for (var i = 0; i < this.dr.occurrences.length; i++) {
                if (this.dr.occurrences[i].status === 'OCCURRENCE_TO_RESOLVE') {
                    occurrencesNotSolved.push(this.dr.occurrences[i]);
                }
            }
            if (occurrencesNotSolved.length > 0) {
                var s = ((occurrencesNotSolved.length === 1) ? '' : 's');
                if (occurrencesNotSolved.some(function (el) {
                    var d = new Date(el.createdAt);
                    return (now - d.getTime() > 2 * hora);
                })) {
                    // se alguma ocorrencia foi criada mais de 2h atrás
                    this.color = 'red';
                    this.tooltip.push('há ' + this.dr.occurrences.length + ' ocorrência' + s + ' pendente' + s);
                }
                else {
                    // se alguma ocorrencia foi criada, e todas menos de 2h atrás
                    this.color = (this.color === 'red') ? this.color : 'yellow';
                    this.tooltip.push('há ' + this.dr.occurrences.length + ' ocorrência' + s + ' pendente' + s);
                }
            }
        }
        // se existe um caminhao para verificar
        if (this.dr.status === 'InTransit') {
            if (this.dr.driverPosition === undefined) {
                // se o caminhao ainda nao foi visto
                this.color = 'red';
                this.tooltip.push('caminhão não encontrado');
            }
            else {
                var d = new Date(this.dr.driverPosition.createdAt);
                if (now - d.getTime() > 1 * hora) {
                    // se o caminhao nao é visto a mais de 1h
                    this.color = 'red';
                    this.tooltip.push('caminhão não encontrado');
                }
                else {
                    if (now - d.getTime() > 1 * hora) {
                        // se o caminhao nao é visto a mais de 1h
                        this.color = 'red';
                        this.tooltip.push('caminhão não é visto a mais de 1 hora');
                    }
                    else if (now - d.getTime() > 0.5 * hora) {
                        // se o caminhao nao é visto a mais de 30min
                        this.color = (this.color === 'red') ? this.color : 'yellow';
                        this.tooltip.push('caminhão não é visto a mais de 30 minutos');
                    }
                }
            }
        }
    };
    return DrCardComponent;
}());
export { DrCardComponent };
