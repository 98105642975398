import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var DashboardService = /** @class */ (function () {
    function DashboardService(http) {
        this.http = http;
        // set token if saved in local storage
        this.url = base_url + 'statistics/';
        this.token = JSON.parse(localStorage.getItem('currentUser')).token;
        this.header = new HttpHeaders().set('Authorization', 'Bearer ' + this.token).set('x-api-key', api_key);
    }
    DashboardService.prototype.getMdrByShippingCompany = function () {
        return this.http.get(this.url + 'mdr/shippingCompany', { headers: this.header });
    };
    DashboardService.prototype.getMdrByStatus = function () {
        return this.http.get(this.url + 'mdr/status         ', { headers: this.header });
    };
    DashboardService.prototype.getDrByStatus = function () {
        return this.http.get(this.url + 'dr/status', { headers: this.header });
    };
    DashboardService.prototype.getDrByDriverPosition = function () {
        return this.http.get(this.url + 'dr/driverPosition', { headers: this.header });
    };
    DashboardService.prototype.getOccurrence = function () {
        return this.http.get(this.url + 'occurrence', { headers: this.header });
    };
    DashboardService.prototype.getPods = function () {
        return this.http.get(this.url + 'pod', { headers: this.header });
    };
    DashboardService.prototype.getCostKg = function () {
        return this.http.get(this.url + 'mdr/costPerKilo', { headers: this.header });
    };
    return DashboardService;
}());
export { DashboardService };
