import { OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { OVService } from '../../providers/ov.service';
var VehicleCalculatorComponent = /** @class */ (function () {
    function VehicleCalculatorComponent(ovservice) {
        this.ovservice = ovservice;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.columns = [
            { value: 'ourOrderNo', name: 'VEHICLE_CALCULATOR/table/ov' },
            { value: 'boxNo', name: 'VEHICLE_CALCULATOR/table/box' },
            { value: 'grossWeight', name: 'VEHICLE_CALCULATOR/table/weight' },
            { value: 'height', name: 'VEHICLE_CALCULATOR/table/height' },
            { value: 'length', name: 'VEHICLE_CALCULATOR/table/lenght' },
            { value: 'width', name: 'VEHICLE_CALCULATOR/table/widht' },
            { value: 'volume', name: 'VEHICLE_CALCULATOR/table/volume' },
            { value: 'site', name: 'VEHICLE_CALCULATOR/table/site' },
            { value: 'delivery', name: 'VEHICLE_CALCULATOR/table/delivery' }
        ];
        this.ovs = [];
        this.vehicle = null;
    }
    VehicleCalculatorComponent.prototype.ngOnInit = function () {
    };
    VehicleCalculatorComponent.prototype.getVehicle = function () {
        var _this = this;
        this.ovservice.getVehicle(this.ovs.map(function (e) { return e.value; })).subscribe(function (data) {
            for (var _i = 0, _a = _this.ovs; _i < _a.length; _i++) {
                var ov = _a[_i];
                if (data.validOVs.indexOf(ov.value.toString()) !== -1) {
                    ov.found = true;
                }
                else {
                    ov.found = false;
                }
            }
            if (data.validOVs.length > 0) {
                data.searched.height = data.searched.height / 10;
                data.searched.width = data.searched.width / 10;
                data.searched.length = data.searched.length / 10;
                data.searched.volume = data.searched.volume / 1000;
                _this.vehicle = data;
            }
        });
    };
    VehicleCalculatorComponent.prototype.add = function (event) {
        var input = event.input;
        var value = +event.value.trim();
        if (value) {
            var duplicate = false;
            for (var _i = 0, _a = this.ovs; _i < _a.length; _i++) {
                var ov = _a[_i];
                if (ov.value === value) {
                    duplicate = true;
                    break;
                }
            }
            if (!duplicate) {
                this.ovs.push({ value: value, found: true });
            }
        }
        if (input) {
            input.value = '';
        }
    };
    VehicleCalculatorComponent.prototype.remove = function (ov) {
        var index = this.ovs.indexOf(ov);
        if (index >= 0) {
            this.ovs.splice(index, 1);
        }
    };
    return VehicleCalculatorComponent;
}());
export { VehicleCalculatorComponent };
